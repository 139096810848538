import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import MarketBets from '../../../../allBets/MarketBets';
import { runresult_teenTest, startResult_teenTest } from './js/result';
import TeenMod from '../modals/TeenMod';
import TeenRules from '../modals/TeenRules';
import { 
check_teenTest,
start_teenTest,
pls_teenTest
} from './js/refresh';

const TeenTest = () => {

const [load_teentest, setLoadTeenTest] = useState(true);
const [curr_teentest, setCurrTeenTest] = useState([]);

const openteenRules = () => {
$('#teenModal').show();
}

useEffect(() => {
async function fetchteenTest() {
try {
const getData = await axios.get('/server2/t20test_Data');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadTeenTest(false);
setCurrTeenTest(resData);
}
else{
setLoadTeenTest(true);
setCurrTeenTest([]);
}
} catch (error) {
console.log('backend casino_teentest_data error : ' + error);
}
}

fetchteenTest();
},[]);

useEffect(() => {
check_teenTest();
runresult_teenTest();
pls_teenTest(curr_teentest.data !== undefined ? curr_teentest.data.t1[0].mid : 0,'teen_test');
return () => {
clearInterval(startResult_teenTest); 
clearInterval(start_teenTest);
}
},[curr_teentest]);

if(load_teentest){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
curr_teentest.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti test</span>
<span className='casGamerule' onClick={openteenRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_teenTest'>{curr_teentest.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>tiger</span>
<div className='teamLiveCards'>
<img id='card1_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>lion</span>
<div className='teamLiveCards'>
<img id='card4_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c4.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c5.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c6.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>   
<div className='col'>
<span className='cardTeam'>dragon</span>
<div className='teamLiveCards'>
<img id='card7_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c7.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card8_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c8.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card9_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c9.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>  
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_teenTest'>{curr_teentest.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3048' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh'><span>tiger</span></th>
<th className='backTh'><span>lion</span></th>
<th className='backTh'><span>dragon</span></th>
</tr>
</thead>
{
curr_teentest.data.t2.map((data, index) => {
return (
<tbody key={index}>
<tr>
<td>
<div className='teamTdCas'>
<span>{data.nation}</span>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino2' id={'locCasino_teenTest_'+index}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.tsection}>
<span className='casRate' id={'casRate_teenTest_'+data.tsection}>{data.trate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.tsection}>0</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.lsection}>
<span className='casRate' id={'casRate_teenTest_'+data.lsection}>{data.lrate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.lsection}>0</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.dsectionid}>
<span className='casRate' id={'casRate_teenTest_'+data.dsectionid}>{data.drate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.dsectionid}>0</span>
</button>
</td>
</tr>
</tbody>
)
})
}
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_teenTest'></div>
</div>

<MarketBets eventid={curr_teentest.data !== undefined ? curr_teentest.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<TeenMod />
<TeenRules />
</>
)
}

export default TeenTest;
