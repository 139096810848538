import React, { useState } from 'react';
import $ from 'jquery';
import axios from 'axios';

const Login = () => {

const [checked, setChecked] = useState(false);

const handleLoginCheck = () => {
setChecked(!checked);
var x = document.getElementById('password');
if(checked === false){
if(x.type === 'password'){
x.type = 'text';
}
}else if(checked === true){
if(x.type === 'text'){
x.type = 'password';
}
}
}

const loginUser = async() => {
var customer = $('#username').val();
var password = $('#password').val();
// alert(username +'-- '+ password);
$('#loginDesk').attr('disabled', 'disabled').css({background : 'grey'});

if(customer === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('username required');
setTimeout(function(){
$('#msgAlert').fadeOut();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
return false;
}
else if(password === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('password required');
setTimeout(function(){
$('#msgAlert').fadeOut();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/login', {
customer : customer,
password : password
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('login success');
setTimeout(function(){
$('#msgAlert').fadeOut();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
window.location.replace('/dashboard');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut();
$('#loginDesk').removeAttr('disabled').css({background : '#00b14f'});
},1000);
}
} catch (error) {
console.log('backend login-api error : ' + error);
}
}
}

return (
<>
<div className='loginWrap'>
<div className='loginBox'>
<div className='loginContent'>

<div className="logo-text-wrap">
{
    /*
<img src="/images/flower.png" alt="logo-img" />
<span>24ExchangeBet</span>
    */
}
<img src="/images/24exchLogo.png" alt="logo-img" />
</div>

<div className='formWrap'>
<div className='formControl'>
<label>username *</label>
<input type='text' name='username' id='username' placeholder='username...' />
</div>
<div className='formControl'>
<label>password *</label>
<input type='password' name='password' id='password' placeholder='password...' />
</div>
<div className='formControl formControlFlex'>
<label style={{cursor: 'pointer'}}>
<input type='checkbox' checked={checked} onChange={handleLoginCheck} />
show password</label>
</div>
<div className='formControl'>
<button id='loginDesk' onClick={() => loginUser()}>login</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Login;
