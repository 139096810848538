import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import NewsTicker from './extra/NewsTicker';
import Header from './extra/Header';
import MainMenu from './extra/MainMenu';
import Home from './pages/Home';
import SubAdmin from './users/SubAdmin';
import PrfAccount from './users/PrfAccount';
import Statement from './users/Statement';
import Activity from './users/Activity';
import AllUsers from './users/AllUsers';
import Profit from './users/profitLoss/Profit';
import BetHistory from './users/BetHistory';
import MyAccount from './pages/profile/MyAccount';
import MyStatement from './pages/profile/MyStatement';
import MyActivity from './pages/profile/MyActivity';
import BetList from './pages/betlist/BetList';
import AdminBanking from './pages/banking/AdminBanking';
import UserBanking from './pages/banking/UserBanking';
import Commision from './pages/commision/Commision';
import Users from './users/sub/Users';
import CommHis from './pages/commision/CommHis';
import PassHistory from './pages/passHis/PassHistory';
import Restore from './pages/restoreUser/Restore';
import MarketAnal from './pages/analysis/MarketAnal';
import CricMarket from './pages/analysis/markets/cricket/CricMarket';
import SoccerMarket from './pages/analysis/markets/soccer/SoccerMarket';
import TennisMarket from './pages/analysis/markets/tennis/TennisMarket';
import Reports from './pages/reports/eventRep/Reports';
import SportPls from './pages/reports/eventRep/SportPls';
import EventPls from './pages/reports/eventRep/EventPls';
import UserPls from './pages/reports/eventRep/UserPls';
import EventHistory from './pages/reports/eventRep/EventHistory';
import MasRep from './pages/reports/masterRep/MasRep';
import Event from './users/profitLoss/Event';
import History from './users/profitLoss/History';
import Notification from './pages/settings/Notification';
import BetSetting from './pages/settings/betSetting/BetSetting';
import GameSetting from './pages/settings/gameSetting/GameSetting';
import DefaultPass from './modals/DefaultPass';
import AutoScroll from './pages/AutoScroll';
import AutoLogOut from './pages/AutoLogOut';
import AuthUser from './AuthUser';
import Online from './pages/Online';
import SelfDepo from './pages/settings/SelfDepo';
import Settlement from './pages/settle/Settlement';
import ViewSettle from './pages/settle/ViewSettle';
import ReSettlement from './pages/settle/ReSettlement';
import ViewReSettle from './pages/settle/ViewReSettle';
import Dt20 from './pages/analysis/markets/casino/dt20/Dt20';
import Dt202 from './pages/analysis/markets/casino/dt202/Dt202';
import T20 from './pages/analysis/markets/casino/t20/T20';
import T201Day from './pages/analysis/markets/casino/t201day/T201Day';
import Dt1Day from './pages/analysis/markets/casino/dt1Day/Dt1Day';
import AndarBahar from './pages/analysis/markets/casino/andarBahar/AndarBahar';
import Lucky7A from './pages/analysis/markets/casino/lucky7A/Lucky7A';
import Lucky7B from './pages/analysis/markets/casino/lucky7B/Lucky7B';
import TeenTest from './pages/analysis/markets/casino/teenTest/TeenTest';

const App = () => {
return (
<BrowserRouter>
<AuthUser />
<Online />
<AutoScroll />
<AutoLogOut />
<NewsTicker />
<Header />
<MainMenu />
<DefaultPass />
<Routes>
<Route path='/' element={<Login />} />
<Route path='/dashboard' element={<Home />} />
<Route path='/all-admin' element={<SubAdmin />} />
<Route path='/all-users' element={<AllUsers />} />
<Route path='/user_profile' element={<PrfAccount />} />
<Route path='/user_statement' element={<Statement />} />
<Route path='/user_activity' element={<Activity />} />
<Route path='/profit_loss' element={<Profit />} />
<Route path='/bet_history' element={<BetHistory />} />
<Route path='/my_account' element={<MyAccount />} />
<Route path='/my_account/user_statement' element={<MyStatement />} />
<Route path='/my_account/user_activity' element={<MyActivity />} />
<Route path='/bet_list' element={<BetList />} />
<Route path='/admin_banking' element={<AdminBanking />} />
<Route path='/user_banking' element={<UserBanking />} />
<Route path='/user_commisions' element={<Commision />} />
<Route path='/commision_history' element={<CommHis />} />
<Route path='/all-admin/users' element={<Users />} />
<Route path='/password_history' element={<PassHistory />} />
<Route path='/restore_users' element={<Restore />} />
<Route path='/market_analysis' element={<MarketAnal />} />
<Route path='/market_4/' element={<CricMarket />} />
<Route path='/market_1/' element={<SoccerMarket />} />
<Route path='/market_2/' element={<TennisMarket />} />
<Route path='/sport_report/' element={<Reports />} />
<Route path='/sport_report/sport' element={<SportPls />} />
<Route path='/sport_report/sport/event' element={<EventPls />} />
<Route path='/sport_report/sport/event/market' element={<UserPls />} />
<Route path='/sport_report/sport/event/market/bet_history' element={<EventHistory />} />
<Route path='/downline_report/' element={<MasRep />} />
<Route path='/profit_loss/event/' element={<Event />} />
<Route path='/profit_loss/event/bet_history' element={<History />} />
<Route path='/notification/' element={<Notification />} />
<Route path='/bet_setting/' element={<BetSetting />} />
<Route path='/game_setting/' element={<GameSetting />} />
<Route path='/self_deposit/' element={<SelfDepo />} />
<Route path='/settlement/' element={<Settlement />} />
<Route path='/view_settle/' element={<ViewSettle />} />
<Route path='/re_settlement/' element={<ReSettlement />} />
<Route path='/view_resettle/' element={<ViewReSettle />} />
<Route path='/casino/dt20/' element={<Dt20 />} />
<Route path='/casino/dt202/' element={<Dt202 />} />
<Route path='/casino/t20/' element={<T20 />} />
<Route path='/casino/t201day/' element={<T201Day />} />
<Route path='/casino/dt1day/' element={<Dt1Day />} />
<Route path='/casino/ab/' element={<AndarBahar />} />
<Route path='/casino/lucky7a/' element={<Lucky7A />} />
<Route path='/casino/lucky7b/' element={<Lucky7B />} />
<Route path='/casino/teen_test/' element={<TeenTest />} />
</Routes>
</BrowserRouter>
)
}

export default App;
