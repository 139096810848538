import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../context/AppContext';

const NewsTicker = () => {

const { userActive, allNotice } = GlobalContext();
const [currNews, setCurrNews] = useState([]);

useEffect(() => {
if(userActive.length > 0){
if(userActive[0].user_role === 'admin_role'){
setCurrNews(allNotice.filter((e) => e.adm_allow === 'true'));  
}
else if(userActive[0].user_role === 'subadmin_role'){
setCurrNews(allNotice.filter((e) => e.sub_allow === 'true'));  
}
else if(userActive[0].user_role === 'super_role'){
setCurrNews(allNotice.filter((e) => e.sup_allow === 'true'));  
}
else if(userActive[0].user_role === 'master_role'){
setCurrNews(allNotice.filter((e) => e.mas_allow === 'true'));  
}
else if(userActive[0].user_role === 'agent_role'){
setCurrNews(allNotice.filter((e) => e.agn_allow === 'true'));  
}
}
},[userActive, allNotice]);

return (
<>
<div className='news_ticker'>
<div className='news_rows'>
<div className='col'>
<img src='/images/horn.png' alt='horn' />
</div>
<div className='col'>
<div className='news_tickerCon'>
{
currNews.length > 0 ? currNews.map((data, index) => {
return(
<span key={index}>{data.msg}</span>
)
})
:
<span>currently no updates...</span>
}
</div>
</div>
</div>
</div>
</>
)
}

export default NewsTicker;
