import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../context/AppContext';
import ParentList from '../../../../modals/ParentList';
import LiveBets from '../../../../modals/LiveBets';
import MarketList from '../../../../modals/MarketList';
import RemoveBet from '../../../../modals/RemoveBet';

const MasterBook = () => {

const location = useLocation();
const [isChecked, setIsChecked] = useState(false);
const { unsetBets, userActive } = GlobalContext();
const [currData, setCurrData] = useState([]);
const [liveBetsAll, setLiveBetsAll] = useState([]);

const liveBets = () => {
setIsChecked(!isChecked); 
if(isChecked){
setLiveBetsAll([]);
}
else{
setLiveBetsAll(currData.filter((e) => e.eventid === (location.state !== null ? location.state.eventid : 0)));
}
}

const parentList = (customer,admin,sub_admin,super_master,master,agent) => {
$('#parentListModal').show();
if(customer !== 'null'){
$('#customerParent').text(customer);
$('#customerParentRole').show();
}
if(admin !== 'null'){
$('#adminParent').text(admin);
$('#adminParentRole').show();
}
if(sub_admin !== 'null'){
$('#sub_adminParent').text(sub_admin);
$('#sub_adminParentRole').show();
}
if(super_master !== 'null'){
$('#super_masterParent').text(super_master);
$('#super_masterParentRole').show();
}
if(master !== 'null'){
$('#masterParent').text(master);
$('#masterParentRole').show();
}
if(agent !== 'null'){
$('#agentParent').text(agent);
$('#agentParentRole').show();
}
}

const userBook = () => {
$('#marketListModal').show();
$('#marketListRole').text('customer');
}

const mastersBooks = () => {
$('#marketListModal').show();
$('#marketListRole').text('master');
}

const removeBet = (id,usr,eventid,sec,team) => {
$('#removeBetModal').show();
$('#userRemoveBet').text(usr);
$('#removeBetId').val(id);
$('#removeEventid').val(eventid);
$('#removeSec').val(sec);
$('#removeTeam').val(team);
}

const viewMore = () => {
$('#liveBetsModal').show();
}

useEffect(() => {
if(unsetBets.length > 0){
setCurrData(unsetBets);
}
},[unsetBets]);

return (
<>
<div className='bookDivWraps'>
<div className='bookDiv'>
<div className='marketAnalDiv'><span>book</span></div>
<div className='bookDiv_body'>
<button onClick={() => mastersBooks()}>master book</button>
<button onClick={() => userBook()}>user book</button>
</div>
</div>

<div className='bookDiv2'>
<div className='marketAnalDiv'>
<div className='liveBetsSwitch'>
<span className='liveBetstitle'>live bets</span>
<label className='switchWrap'>
<input type='checkbox' className='checkSwitch' checked={isChecked} onChange={liveBets} />
<span className='switchSlider'></span>
</label>
</div>

<span className='viewMore' onClick={() => viewMore()}>view more</span>
</div>
<div className='bookDiv_body'>
{
liveBetsAll.length > 0 ? 
<div className='table-responsive'>
<table className='liveBetsTable'>
<thead>
<tr>
<th style={{textAlign: 'left'}}>team</th>
<th>odds</th>
<th>stake</th>
<th>user</th>
</tr>
</thead>
{
liveBetsAll.map((data, index) => {
if(data.bettype === 'back') {
return(
<tbody key={index}>
<tr className='liveBack_tr'>
<td style={{textAlign: 'left'}}>
<div className='gridBox'>
<div>    
<span className='gridBoxBack'>
{
data.sec === 'fancy' || data.sec === 'over' || data.sec === 'oddeven'
|| data.sec === 'meter' || data.sec === 'line' || data.sec === 'khado'
|| data.sec === 'ball' || data.sec === 'fancy1' ? 'yes' : data.bettype 
}
</span>
</div>
<div>
<span className='gridBoxTxt'>{data.team}</span>
<span className='gridBoxTxt2'>{data.sec}</span>
</div>
</div>
</td>
<td>{data.betRate}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>
<span className='parentUser' onClick={() => parentList(data.customer,data.admin,data.sub_admin,data.super_master,data.master,data.agent)}>{data.customer}</span>
{
userActive[0].user_role === 'admin_role' &&
<i className='fa fa-trash trashIcon' onClick={() => removeBet(data.id,data.customer,data.eventid,data.sec,data.team)}></i>
}
</td>
</tr>
<tr className='liveBack_tr2'>
<td colSpan='2' style={{textAlign: 'left'}}><span className='dateTime_sm'>date time : {data.bet_dateTime}</span></td>
<td colSpan='2' style={{textAlign: 'right'}}><span className='dateTime_sm'>eventid : {data.eventid}</span></td>
</tr>
</tbody>
)
}
else{
return (
<tbody key={index}>
<tr className='liveLay_tr'>
<td style={{textAlign: 'left'}}>
<div className='gridBox'>
<div>    
<span className='gridBoxLay'>
{
data.sec === 'fancy' || data.sec === 'over' || data.sec === 'oddeven'
|| data.sec === 'meter' || data.sec === 'line' || data.sec === 'khado'
|| data.sec === 'ball' || data.sec === 'fancy1' ? 'no' : data.bettype 
}
</span>
</div>
<div>
<span className='gridBoxTxt'>{data.team}</span>
<span className='gridBoxTxt2'>{data.sec}</span>
</div>
</div>
</td>
<td>{data.betRate}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>
<span className='parentUser' onClick={() => parentList(data.customer,data.admin,data.sub_admin,data.super_master,data.master,data.agent)}>{data.customer}</span>
{
userActive[0].user_role === 'admin_role' &&
<i className='fa fa-trash trashIcon' onClick={() => removeBet(data.id,data.customer,data.eventid,data.sec,data.team)}></i>
}
</td>
</tr>
<tr className='liveLay_tr2'>
<td colSpan='2' style={{textAlign: 'left'}}><span className='dateTime_sm'>date time : {data.bet_dateTime}</span></td>
<td colSpan='2' style={{textAlign: 'right'}}><span className='dateTime_sm'>eventid : {data.eventid}</span></td>
</tr>
</tbody>
)
}
})
}
</table>
</div>
:
<div className='noRecordDiv'><span>there are no any bets.</span></div>
}
</div>
</div>
</div>

<ParentList />
<LiveBets />
<MarketList />
<RemoveBet />
</>
)
}

export default MasterBook;
