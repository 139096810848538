import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { GlobalContext } from '../../../context/AppContext';

const SoccListApi = '/server2/betfair-soccer';
const gameSetData = '/server2/game_settingsData';

const Soccer = () => {

const [soccCompData, setSoccCompData] = useState([]);
const { gameSet, getGameSet } = GlobalContext();

const handleCheck = async(id) => {
var currCheck = $('#checkB_'+id).prop('checked') ? true : false;
var sport = 'soccer';

try {
const sendData = await axios.post('/server2/game_settings', {
id: id,
currCheck: currCheck,
sport: sport
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('series locked successfully');
setTimeout(function(){
$('#msgAlert').hide();
getGameSet(gameSetData);
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('series active successfully');
setTimeout(function(){
$('#msgAlert').hide();
getGameSet(gameSetData);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend soccer-game-setting-data error : ' + error);
}
}

useEffect(() => {
async function fetchData(){
var allData = [];
try {
const getData = await axios.get(`${SoccListApi}`);
const resData = await getData.data;
if(resData.length > 0){
resData.forEach((e) => {
var data = {
cid: e.cid,
cname: e.cname
}

const findKey = allData.findIndex((o) => o.cid === e.cid);
if(findKey === -1){
allData.push(data);
}
}); 
setSoccCompData(allData); 
}
} catch (error) {
console.log('backend game-setting-soccer-api error : ' + error);
}
}

fetchData();
},[]);

useEffect(() => {
const matchId = soccCompData.filter((e) => gameSet.find((f) => parseFloat(e.cid) === parseFloat(f.eventid)));
const nomatchId = soccCompData.filter((e) => !gameSet.find((f) => parseFloat(e.cid) === parseFloat(f.eventid)));

if(matchId.length > 0){
matchId.forEach((j) => {
$('#checkB_'+j.cid).prop('checked', true);
$('#seriesStatus_'+j.cid).addClass('locked').removeClass('active').html('locked')
});
}

if(nomatchId.length > 0){
nomatchId.forEach((j) => {
$('#checkB_'+j.cid).prop('checked', false);
$('#seriesStatus_'+j.cid).addClass('active').removeClass('locked').html('active')
});
}

},[gameSet, soccCompData]);

return (
<>
<div className='stateWrap' id='cricketGameSetting'>
<div className='stateHead'><span>soccer game setting</span></div>

<div className='stateBody'>
{
soccCompData.length > 0 ?
<table className='settingTable'>
<thead>
<tr>
<th>series name</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
soccCompData.map((data, index) => {
return(
<tr key={index}>
<td>{data.cname} <b>(Cid: {data.cid})</b></td>
<td>
<span className='seriesStatus' id={'seriesStatus_'+data.cid}>active</span>
</td>
<td>
<label className='switchWrap'>
<input type='checkbox' id={'checkB_'+data.cid} className='checkSwitch' onChange={() => handleCheck(data.cid)} />
<span className='switchSlider'></span>
</label>
</td>
</tr>
)
})
}
</tbody>
</table>
:
<div className='pageLoader'>
<div className='pageLoaderCon'>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
</div>
<div className='pageLoaderFoot'><span>loading...</span></div>
</div> 
}
</div>
</div>
</>
)
}

export default Soccer;
