import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const RemoveBet = () => {

const closeRemoveBet = () => {
$('#removeBetModal').hide();
$('.clearRemoveBet').val('');
}

const removeUserBet = async() => {
var removeId = $('#removeBetId').val();
var user = $('#userRemoveBet').text();
var eventid = $('#removeEventid').val();
var sec = $('#removeSec').val();
var team = $('#removeTeam').val();
var sport = null;
$('#removeUserBet').attr('disabled', 'disabled');

if(window.location.pathname.split('_')[1].replace('/', '') === '4'){
sport = 'cricket';
}
else if(window.location.pathname.split('_')[1].replace('/', '') === '1'){
sport = 'soccer';
}
else if(window.location.pathname.split('_')[1].replace('/', '') === '2'){
sport = 'tennis';
}

if(removeId === '' || user === '' || eventid === '' || sec === '' || sport === '' || team === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remove bet error');
setTimeout(function(){
$('#msgAlert').hide();
$('#removeUserBet').removeAttr('disabled');
},1000);
return false;
}
else{
//alert(removeId +' -- '+ eventid +' -- '+ sec +' -- '+ user +' -- '+ sport);
try {
if(sec !== 'fancy' && sec !== 'over' && sec !== 'meter' && sec !== 'khado' && sec !== 'line' && sec !== 'ball' && sec !== 'oddeven' && sec !== 'fancy1'){
const sendData = await axios.post('/server2/removeBets', {
removeId: removeId,
eventid: eventid,
sec: sec,
user: user,
sport: sport
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('remove bet successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#removeBetModal').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
$('#removeUserBet').removeAttr('disabled');
},1000);
}
}
else if(sec === 'oddeven' || sec === 'fancy1'){
const sendData = await axios.post('/server2/removeBets2', {
removeId: removeId,
eventid: eventid,
sec: sec,
user: user,
sport: sport,
team: team
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('remove bet successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#removeBetModal').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
$('#removeUserBet').removeAttr('disabled');
},1000);
}
}
else{
const sendData = await axios.post('/server2/removeBets3', {
removeId: removeId,
eventid: eventid,
sec: sec,
user: user,
sport: sport,
team: team
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('remove bet successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#removeBetModal').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
$('#removeUserBet').removeAttr('disabled');
},1000);
}
}
} catch (error) {
console.log('backend remove-bets-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='removeBetModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>remove live bet - </span> <span id='userRemoveBet'></span> <i className='fa fa-close right' onClick={() => closeRemoveBet()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/bin.png' alt='bin' />
<span>are you sure? you want to remove this bet?</span>
<input type='hidden' name='removeBetId' className='clearRemoveBet' id='removeBetId' />
<input type='hidden' name='removeEventid' className='clearRemoveBet' id='removeEventid' />
<input type='hidden' name='removeSec' className='clearRemoveBet' id='removeSec' />
<input type='hidden' name='removeTeam' className='clearRemoveBet' id='removeTeam' />
<button id='removeUserBet' onClick={() => removeUserBet()}>yes</button>
<button onClick={() => closeRemoveBet()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default RemoveBet;
