import $ from 'jquery';
import axios from 'axios';

async function book_Fancy(eventid,team,mid,sid){
$('.book-loader').css({display: 'flex'});
$('.bookTbale').css({display: 'none'});
//console.log(eventid +' -- '+ team);
$('#fancyBook_div').show();
$('#fancyBook_headTxt').text(team);
let currlay = $('#price_lay_'+mid+sid+0).text();
let currback = $('#price_back_'+mid+sid+0).text();
//console.log(currlay +' -- '+ currback);
document.getElementById('bookTbody').innerHTML = '';
try {
const getData = await axios.get('/server2/fancy_books');
const resData = await getData.data;
if(resData.length > 0){
$('.book-loader').css({display: 'none'});
$('.bookTbale').css({display: 'table'});
const filterData = resData.filter((e) => e.eventid === eventid && e.team.trim() === team.trim());
if(filterData.length > 0){

var yesData = [];
var noData = [];

filterData.forEach(element => {
if(element.bettype === 'back'){
var yes_data = {
bet_type: element.bettype,
bet_rate: element.betRate,
profit: element.profit,
loss: element.loss
}
yesData.push(yes_data);
} 

if(element.bettype === 'lay'){
var no_data = {
bet_type: element.bettype,
bet_rate: element.betRate,
profit: element.profit,
loss: element.loss
}
noData.push(no_data);
} 
});

// console.log(yesData);
// console.log(noData);

let nowLayRate = 0;
let nowBackRate = 0;

if(currlay <= 5){
nowLayRate = 1;
}
else {
nowLayRate = parseFloat(currlay) - 5;   
}

if(currback >= 0){
nowBackRate = parseFloat(currback) + 6;
}

//console.log(nowLayRate);
//console.log(nowBackRate);

let amountCurr = 0;
//let amountLos = 0;

const noPls = noData.reduce((ele, {profit, loss}) => ({
newPrf : (ele.newPrf || 0) + (profit > 0 ? profit : 0),
newLos : (ele.newLos || 0) + (loss > 0 ? loss : 0)
}), {});

const yesPls = yesData.reduce((ele, {profit, loss}) => ({
newPrf : (ele.newPrf || 0) + (profit > 0 ? profit : 0),
newLos : (ele.newLos || 0) + (loss > 0 ? loss : 0)
}), {});

let highRateNo = 0;
let lowRateNo = 0;
let highRateYes = 0;
let lowRateYes = 0;

highRateNo = Math.max(...noData.map(o => o.bet_rate));
lowRateNo = Math.min(...noData.map(o => o.bet_rate));

highRateYes =  Math.max(...yesData.map(o => o.bet_rate));
lowRateYes =  Math.min(...yesData.map(o => o.bet_rate));
//console.log(highRateNo);
//console.log(lowRateNo);

//console.log(yesData);
//console.log(noData);

const lowNo = noData.filter((e) => yesData.find((f) => parseInt(e.bet_rate) <= parseInt(f.bet_rate)));
const yesHigh = yesData.filter((e) => noData.find((f) => parseInt(e.bet_rate) >= parseInt(f.bet_rate)));

const highNo = noData.filter((e) => !yesData.find((f) => parseInt(e.bet_rate) <= parseInt(f.bet_rate)));
const yesLow = yesData.filter((e) => !noData.find((f) => parseInt(e.bet_rate) >= parseInt(f.bet_rate)));

//console.log(highNo);
//console.log(yesLow);
//console.log(lowNo);
//console.log(yesHigh);

let new_highRateNo = 0;
let new_lowRateNo = 0;
let new_highRateYes = 0;
let new_lowRateYes = 0;

let only_highNo = 0;
let only_lowNo = 0;
let only_highYes = 0;
let only_lowYes = 0;

let new_highRateNoMax = Math.max(...lowNo.map(o => o.bet_rate));
let new_lowRateNoMin = Math.min(...lowNo.map(o => o.bet_rate));
let new_highRateYesMax = Math.max(...yesHigh.map(o => o.bet_rate));
let new_lowRateYesMin = Math.min(...yesHigh.map(o => o.bet_rate));

let only_highNoMax = Math.max(...highNo.map(o => o.bet_rate));
let only_lowNoMin = Math.min(...highNo.map(o => o.bet_rate));
let only_highYesMax = Math.max(...yesLow.map(o => o.bet_rate));
let only_lowYesMin = Math.min(...yesLow.map(o => o.bet_rate));

if(new_highRateNoMax !== -Infinity){
new_highRateNo = new_highRateNoMax;   
}
if(new_lowRateNoMin !== Infinity){
new_lowRateNo = new_lowRateNoMin;
}
if(new_highRateYesMax !== -Infinity){
new_highRateYes = new_highRateYesMax;   
}
if(new_lowRateYesMin !== Infinity){
new_lowRateYes = new_lowRateYesMin;
}
if(only_highNoMax !== -Infinity){
only_highNo = only_highNoMax;
}
if(only_lowNoMin !== Infinity){
only_lowNo = only_lowNoMin;  
}
if(only_highYesMax !== -Infinity){
only_highYes = only_highYesMax;
}
if(only_lowYesMin !== Infinity){
only_lowYes = only_lowYesMin;
}

//console.log('new_highRateNo : ' + new_highRateNo +' new_lowRateNo : ' + new_lowRateNo);
//console.log(' new_highRateYes : ' + new_highRateYes +' new_lowRateYes : ' + new_lowRateYes);

//console.log('only_highNo : ' + only_highNo +' only_lowNo : ' + only_lowNo);
//console.log('only_highYes : ' + only_highYes +' only_lowYes : ' + only_lowYes);


const lowNo_reduce = lowNo.reduce((a, {profit, loss}) => ({
new_prf : (a.new_prf || 0) + (profit > 0 ? profit : 0),
new_los : (a.new_los || 0) + (loss > 0 ? loss : 0),
}), {});

const yesHigh_reduce = yesHigh.reduce((a, {profit, loss}) => ({
new_prf : (a.new_prf || 0) + (profit > 0 ? profit : 0),
new_los : (a.new_los || 0) + (loss > 0 ? loss : 0),
}), {});

const highNo_reduce = highNo.reduce((a, {profit, loss}) => ({
new_prf : (a.new_prf || 0) + (profit > 0 ? profit : 0),
new_los : (a.new_los || 0) + (loss > 0 ? loss : 0),
}), {});

const yesLow_reduce = yesLow.reduce((a, {profit, loss}) => ({
new_prf : (a.new_prf || 0) + (profit > 0 ? profit : 0),
new_los : (a.new_los || 0) + (loss > 0 ? loss : 0),
}), {});

//console.log('high no');
//console.log(highNo_reduce);
//console.log('yes low');
//console.log(yesLow_reduce);
// console.log(lowNo);
//console.log(yesHigh);
//console.log(LowEqual);
//console.log(BigEqual);
//console.log(LowEqualBigEqual);
//console.log('low no');
//console.log(lowNo_reduce);
//console.log('high yes');
//console.log(yesHigh_reduce);

let lowNo_prf = 0;
let lowNo_los = 0;
let yesHigh_prf = 0;
let yesHigh_los = 0;

let HighNo_prf = 0;
let HighNo_los = 0;
let yesLow_prf = 0;
let yesLow_los = 0;

if(lowNo_reduce.new_prf !== undefined){
lowNo_prf = lowNo_reduce.new_prf;
}
if(lowNo_reduce.new_los !== undefined){
lowNo_los = lowNo_reduce.new_los;
}
if(yesHigh_reduce.new_prf !== undefined){
yesHigh_prf = yesHigh_reduce.new_prf;
}
if(yesHigh_reduce.new_los !== undefined){
yesHigh_los = yesHigh_reduce.new_los;
}
if(highNo_reduce.new_prf !== undefined){
HighNo_prf = highNo_reduce.new_prf;
}
if(highNo_reduce.new_los !== undefined){
HighNo_los = highNo_reduce.new_los;
}
if(yesLow_reduce.new_prf !== undefined){
yesLow_prf = yesLow_reduce.new_prf;
}
if(yesLow_reduce.new_los !== undefined){
yesLow_los = yesLow_reduce.new_los;
}

for(let i = nowLayRate; i < nowBackRate; i++){

let lossCurr = 0;
let lossCurr1 = 0;
let lossCurr2 = 0;
let lossCurr3 = 0;
let lossCurr4 = 0;
let lossCurr5 = 0;

if(noData.length > 0 && yesData.length <= 0){

for(let a = 0; a < noData.length; a++){
if(parseInt(i) >= parseInt(noData[a].bet_rate)){
lossCurr = noPls.newLos;
amountCurr = '<span class="successTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
else if(parseInt(i) < parseInt(noData[a].bet_rate)){
lossCurr = noPls.newPrf;
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
else{
amountCurr = '<span class="successTxt">0</span>';
}
}

if(parseInt(i) >= parseInt(lowRateNo) && parseInt(i) < parseInt(highRateNo)){
// console.log(' curr i : ' + i);
for(let b=0; b < noData.length; b++){
if(parseInt(i) < parseInt(noData[b].bet_rate)){
// console.log(i +' -- '+ noData[b].bet_rate +' -- '+ noData[b].profit +' -- '+ noData[b].loss); 
lossCurr1 += noData[b].profit;
lossCurr2 += noData[b].loss;
lossCurr = (parseFloat(lossCurr1) + parseFloat(lossCurr2)) - parseFloat(noPls.newLos);
if(lossCurr <= 0){
amountCurr = '<span class="successTxt">'+ ((Math.abs(lossCurr)).toFixed(2)) +'</span>';
}
else{
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
}
else{
lossCurr = 0;
amountCurr = '<span class="dangerTxt">'+ (lossCurr) +'</span>'; 
}
}

}

}

if(yesData.length > 0 && noData.length <= 0){

for(let a=0; a < yesData.length; a++){
if(parseInt(i) < parseInt(yesData[a].bet_rate)){
lossCurr = yesPls.newLos;
amountCurr = '<span class="successTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
else if(parseInt(i) >= parseInt(yesData[a].bet_rate)){
lossCurr = yesPls.newPrf;
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
else{
amountCurr = '<span class="successTxt">0</span>';
}
}

if(parseInt(i) >= parseInt(lowRateYes) && parseInt(i) < parseInt(highRateYes)){
//console.log(' curr i : ' + i);
for(let b=0; b < yesData.length; b++){
if(parseInt(i) < parseInt(yesData[b].bet_rate)){
// console.log(i +' -- '+ noData[b].bet_rate +' -- '+ noData[b].profit +' -- '+ noData[b].loss); 
lossCurr1 += yesData[b].profit;
lossCurr2 += yesData[b].loss;
lossCurr = parseFloat(yesPls.newLos) - (parseFloat(lossCurr1) + parseFloat(lossCurr2));
if(lossCurr <= 0){
amountCurr = '<span class="successTxt">'+ ((Math.abs(lossCurr)).toFixed(2)) +'</span>';
}
else{
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) +'</span>';
}
}
else{
lossCurr = 0;
amountCurr = '<span class="dangerTxt">'+ (lossCurr) +'</span>'; 
}
}
}

}

/*----------------------------------No Bet Yes Bet Both----------------------------------------*/

if(noData.length > 0 && yesData.length > 0){

if(parseInt(i) >= parseInt(only_highYes) && parseInt(i) < parseInt(new_lowRateNo) && parseInt(only_highYes) > 0){
//console.log('new_lowRateNo and only_highYes check i : ' + i);
lossCurr1 = (parseFloat(lowNo_prf) + parseFloat(HighNo_prf)) + parseFloat(yesLow_prf);
lossCurr2 = parseFloat(yesHigh_los);
//console.log(lossCurr1 +' -- '+ lossCurr2);
if(parseFloat(lossCurr2) >= parseFloat(lossCurr1)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr2) - parseFloat(lossCurr1)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr1) > parseFloat(lossCurr2)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr1) - parseFloat(lossCurr2)).toFixed(2)) + '</span>';
}
}
else if(parseInt(i) < parseInt(new_lowRateNo) && parseInt(only_highYes) <= 0){
//console.log('new_lowRateNo check i : ' + i);
lossCurr1 = parseFloat(lowNo_prf) + parseFloat(HighNo_prf);
lossCurr2 = parseFloat(yesHigh_los);
if(parseFloat(lossCurr2) >= parseFloat(lossCurr1)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr2) - parseFloat(lossCurr1)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr1) > parseFloat(lossCurr2)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr1) - parseFloat(lossCurr2)).toFixed(2)) + '</span>';
}
}
else {
amountCurr = '<span class="successTxt">0</span>';  
}

if(parseInt(i) >= parseInt(only_lowYes) && parseInt(i) < parseInt(only_highYes) && parseInt(only_lowYes) > 0 && parseInt(only_highYes) > 0){
//console.log('only_lowYes and only_highYes check i : ' + i);
for(let a = 0; a < yesLow.length; a++){
if(parseInt(i) >= parseInt(yesLow[a].bet_rate) && parseInt(yesLow[a].bet_rate) < parseInt(only_highYes)){
//console.log('check i ' + i + ' -- '+ yesLow[a].bet_rate);
lossCurr1 += yesLow[a].profit;
//lossCurr2 += yesLow[a].loss;
lossCurr3 = parseFloat(lossCurr1) - (parseFloat(yesLow_los) - parseFloat(lossCurr1));
//console.log(lossCurr3);
if(lossCurr3 <= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(yesHigh_los);
lossCurr4 = parseFloat(lowNo_prf) + parseFloat(HighNo_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(lossCurr3 > 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(yesHigh_los);
lossCurr4 = parseFloat(lowNo_prf) + parseFloat(HighNo_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}
}
}

if(parseInt(i) < parseInt(only_lowYes) && parseInt(only_lowYes) > 0){
//console.log('only_lowYes check i : ' + i);
lossCurr1 =  parseFloat(yesLow_los) + parseFloat(yesHigh_los);
lossCurr2 = parseFloat(HighNo_prf) + parseFloat(lowNo_prf);
//console.log(lossCurr1 +' -- '+ lossCurr2);
if(parseFloat(lossCurr1) >= parseFloat(lossCurr2)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr1) - parseFloat(lossCurr2)).toFixed(2)) +'</span>'; 
}
else if(parseFloat(lossCurr2) > parseFloat(lossCurr1)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr2) - parseFloat(lossCurr1)).toFixed(2)) +'</span>';
} 
}

if(parseInt(new_highRateNo) > parseInt(new_lowRateYes)){
//console.log(new_lowRateYes);

if(parseInt(i) >= parseInt(new_lowRateNo) && parseInt(i) < parseInt(new_lowRateYes)){
//console.log('new_lowRateNo and new_lowRateYes check i : ' + i);
for(let a=0; a < lowNo.length; a++){
if(parseInt(i) >= parseInt(lowNo[a].bet_rate) && parseInt(lowNo[a].bet_rate) < parseInt(new_lowRateYes)){
//console.log('check i : ' + i +' -- '+ lowNo[a].bet_rate);
//lossCurr1 += yesLow[a].profit;
lossCurr2 += lowNo[a].loss;
lossCurr3 =  (Math.abs(parseFloat(lowNo_prf) - parseFloat(lossCurr2))) - parseFloat(lossCurr2);
//console.log(lossCurr2);
//console.log(lossCurr3);
if(lossCurr3 <= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(yesHigh_los);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
//console.log(lossCurr);
//console.log(lossCurr4);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) +'</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) +'</span>';
}
}
else if(lossCurr3 > 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(yesHigh_los);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) +'</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) +'</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) +'</span>';
}
}
}
}
}

if(parseInt(i) >= parseInt(new_lowRateYes) && parseInt(i) < parseInt(new_highRateNo)){
//console.log('new_lowRateYes and new_highRateNo check i : ' + i);
for(let a=0; a < yesHigh.length; a++){
if(parseInt(i) >= parseInt(yesHigh[a].bet_rate) && parseInt(yesHigh[a].bet_rate) < parseInt(new_highRateNo)){
//console.log('check i : ' + i + ' -- '+ yesHigh[a].bet_rate);
lossCurr2 += yesHigh[a].profit;
lossCurr3 = parseFloat(lossCurr2) - (parseFloat(yesHigh_los) - parseFloat(lossCurr2));
//console.log(lossCurr3);
//console.log(lossCurr2);
//console.log(yesHigh_los);

var noProfit = [];
var noloss = [];

for(let b=0; b < lowNo.length; b++){
// console.log(' yes rate : ' + yesHigh[a].bet_rate);
// console.log(' no rate : ' + lowNo[b].bet_rate); 
if(parseInt(lowNo[b].bet_rate) <= parseInt(i)){
//lossCurr1 += lowNo[b].loss;
noloss.push({'loss' : lowNo[b].loss});
}
else if(parseInt(lowNo[b].bet_rate) > parseInt(i)){
//lossCurr4 += lowNo[b].profit;
noProfit.push({'profit' : lowNo[b].profit});
}
}

//console.log(noProfit);
//console.log(noloss);
//console.log(noProfit.concat(noloss));

const reduceAll = noProfit.concat(noloss).reduce((a, {profit, loss}) => ({
newprf : (a.newprf || 0) + (profit > 0 ? profit : 0),
newloss : (a.newloss || 0) + (loss > 0 ? loss : 0)
}), {});

//console.log(reduceAll);

lossCurr1 = reduceAll.newloss;
lossCurr4 = reduceAll.newprf;
let new_profit = 0;
let new_loss = 0;

if(parseFloat(lossCurr1) >= parseFloat(lossCurr4)){
new_loss = parseFloat(lossCurr1) - parseFloat(lossCurr4);
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr1)){
new_profit = parseFloat(lossCurr4) - parseFloat(lossCurr1);
}

//console.log('new_profit : ' + new_profit +' new_loss : ' + new_loss);

if(lossCurr3 <= 0){
if(new_profit > 0){
lossCurr = parseFloat(new_profit) - parseFloat(Math.abs(lossCurr3));
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
}
else if(new_loss >= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(new_loss);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
}
else if(lossCurr3 > 0){
if(new_profit > 0){
lossCurr = (parseFloat(lossCurr3) + parseFloat(new_profit)) + (parseFloat(HighNo_prf) + parseFloat(yesLow_prf));
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) + '</span>';
}
else if(new_loss >= 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(new_loss);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
}
}
}
}
}

if(parseInt(i) >= parseInt(new_highRateNo) && parseInt(i) < parseInt(new_highRateYes)){
//console.log('new_highRateNo and new_highRateYes check i i : ' + i);
lossCurr1 = parseFloat(lowNo_los);
//console.log(lossCurr1);

var newPrf = [];
var newloss = [];

for(let a=0; a < yesHigh.length; a++){
if(parseInt(yesHigh[a].bet_rate) <= parseInt(i)){
newPrf.push({'profit' : yesHigh[a].profit});
}
else if(parseInt(yesHigh[a].bet_rate) > parseInt(i)){
newloss.push({'loss' : yesHigh[a].loss});
}
}

//console.log(newPrf);
//console.log(newloss);

const reduceAll = newPrf.concat(newloss).reduce((a, {profit, loss}) => ({
newprf : (a.newprf || 0) + (profit > 0 ? profit : 0),
newloss : (a.newloss || 0) + (loss > 0 ? loss : 0)
}), {});

//console.log(reduceAll);

lossCurr2 = reduceAll.newprf;
lossCurr3 = reduceAll.newloss;

//console.log('lossCurr2 : ' + lossCurr2 +' lossCurr3 : '+ lossCurr3);

let new_profit = 0;
let new_loss = 0;

if(parseFloat(lossCurr3) >= parseFloat(lossCurr2)){
new_loss = parseFloat(lossCurr3) - parseFloat(lossCurr2);
}
else if(parseFloat(lossCurr2) > parseFloat(lossCurr3)){
new_profit = parseFloat(lossCurr2) - parseFloat(lossCurr3);
}

//console.log('new_loss : ' + new_loss +' new_profit : '+ new_profit);

if(new_profit > 0){
lossCurr4 = parseFloat(new_profit) - parseFloat(lossCurr1);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr4 <= 0){
if(parseFloat(Math.abs(lossCurr4)) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr4)) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(Math.abs(lossCurr4))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(Math.abs(lossCurr4))).toFixed(2)) + '</span>';
}
}
else if(lossCurr4 > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) + parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
}
else if(new_loss >= 0){
lossCurr4 = parseFloat(lossCurr1) + parseFloat(new_loss);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr4) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(lossCurr4)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}

}

}
else if(parseInt(new_lowRateYes) >= parseInt(new_highRateNo)){

if(parseInt(i) >= parseInt(new_lowRateNo) && parseInt(i) < parseInt(new_highRateNo)){
//console.log('new_lowRateNo and new_highRateNo check i : ' + i);
for(let a=0; a < lowNo.length; a++){
if(parseInt(i) >= parseInt(lowNo[a].bet_rate) && parseInt(lowNo[a].bet_rate) < parseInt(new_highRateNo)){
//console.log('check i : ' + i + ' -- ' + lowNo[a].bet_rate);
lossCurr1 += lowNo[a].loss;
lossCurr3 = (parseFloat(lowNo_prf) - parseFloat(lossCurr1)) - parseFloat(lossCurr1);
//console.log(parseFloat(lowNo_prf) - parseFloat(lossCurr1));
//console.log(lossCurr3);

var allYesProfit = [];
var allYesLoss = [];

for(let b=0; b < yesHigh.length; b++){
if(parseInt(yesHigh[b].bet_rate) <= parseInt(i)){
 //console.log(' yes low to no : ' + yesHigh[b].bet_rate + ' -- ' + lowNo[a].bet_rate);
allYesProfit.push({'profit' : yesHigh[b].profit});
}
else if(parseInt(yesHigh[b].bet_rate) > parseInt(i)){
 //console.log(' yes high to no : ' + yesHigh[b].bet_rate + ' -- ' + lowNo[a].bet_rate);
allYesLoss.push({'loss' : yesHigh[b].loss});
}
}

// console.log(allYesProfit);
// console.log(allYesLoss);
//console.log(allYesProfit.concat(allYesLoss));
const reduceAll = allYesProfit.concat(allYesLoss).reduce((a, {profit, loss}) => ({
yesprf : (a.yesprf || 0) + (profit > 0 ? profit : 0),
yesloss : (a.yesloss || 0) + (loss > 0 ? loss : 0)
}), {});

//console.log(reduceAll);
lossCurr2 = reduceAll.yesprf;
lossCurr4 = reduceAll.yesloss; 
//console.log('lossCurr2 : ' + lossCurr2 + ' lossCurr4 : ' + lossCurr4);

let new_profit2 = 0;
let new_loss2 = 0;

if(parseFloat(lossCurr4) >= parseFloat(lossCurr2)){
new_loss2 = parseFloat(lossCurr4) - parseFloat(lossCurr2);
}
else if(parseFloat(lossCurr2) > parseFloat(lossCurr4)){
new_profit2 = parseFloat(lossCurr2) - parseFloat(lossCurr4);
} 

//console.log('new_loss2 : ' + new_loss2 + ' new_profit2 : ' + new_profit2);

if(lossCurr3 <= 0){
if(new_loss2 >= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(new_loss2);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(new_profit2 > 0){
lossCurr = parseFloat(new_profit2) - parseFloat(Math.abs(lossCurr3));
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) > parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
}
}
else if(lossCurr3 > 0){
if(new_loss2 >= 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(new_loss2);
lossCurr5 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr5)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr5) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr5) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr5)).toFixed(2)) + '</span>';
}
}
else if(new_profit2 > 0){
lossCurr = (parseFloat(lossCurr3) + parseFloat(new_profit2)) + (parseFloat(HighNo_prf) + parseFloat(yesLow_prf));
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) + '</span>';
}
}
}
}
}

if(parseInt(i) >= parseInt(new_highRateNo) && i < parseInt(new_lowRateYes)){
//console.log('new_highRateNo and new_lowRateYes check i : ' + i);

for(let a=0; a < yesHigh.length; a++){
if(parseInt(yesHigh[a].bet_rate) <= parseInt(new_highRateNo)){
//console.log('check yes low to no : ' + yesHigh[a].bet_rate + ' -- ' + new_highRateNo);
lossCurr1 += yesHigh[a].profit;
}
else if(parseInt(yesHigh[a].bet_rate) > parseInt(new_highRateNo)){
//console.log('check yes high to no : ' + yesHigh[a].bet_rate + ' -- ' + new_highRateNo);
lossCurr2 += yesHigh[a].loss;
}
}

// console.log('new_profit1 : ' + lossCurr1 +' new_loss1 : ' + lossCurr2);
let new_profit1 = 0;
let new_loss1 = 0;

if(parseFloat(lossCurr2) >= parseFloat(lossCurr1)){
new_loss1 = parseFloat(lossCurr2) - parseFloat(lossCurr1);
}
else if(parseFloat(lossCurr1) > parseFloat(lossCurr2)){
new_profit1 = parseFloat(lossCurr1) - parseFloat(lossCurr2);
}
//console.log('new_loss1 : ' + new_loss1 +' new_profit1 : ' + new_profit1);

if(new_loss1 >= 0){
lossCurr = parseFloat(lowNo_los) + parseFloat(new_loss1);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(new_profit1 > 0){
lossCurr = parseFloat(new_profit1) - parseFloat(lowNo_los);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}

if(parseInt(i) >= parseInt(new_lowRateYes) && parseInt(i) < parseInt(new_highRateYes)){
//console.log('new_lowRateYes and new_highRateYes check i : ' + i);
for(let a=0; a < yesHigh.length; a++){
if(parseInt(i) >= parseInt(yesHigh[a].bet_rate) && parseInt(yesHigh[a].bet_rate) < parseInt(new_highRateYes)){
//console.log('check i : ' + i + ' -- ' + yesHigh[a].bet_rate);
lossCurr1 += yesHigh[a].profit;
lossCurr3 = parseFloat(lossCurr1) - (parseFloat(yesHigh_los) - parseFloat(lossCurr1));

if(lossCurr3 <= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(lowNo_los);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(lossCurr3 > 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(lowNo_los);
lossCurr4 = parseFloat(HighNo_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';  
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>'; 
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}
}
}

}

if(parseInt(new_highRateYes) > 0 && parseInt(only_lowNo) > 0){

if(parseInt(i) >= parseInt(new_highRateYes) && parseInt(i) < parseInt(only_lowNo)){
//console.log('new_highRateYes and only_lowNo check i : ' + i);
lossCurr = parseFloat(yesHigh_prf) + (parseFloat(yesLow_prf) + parseFloat(HighNo_prf));
lossCurr4 = parseFloat(lowNo_los);
if(parseFloat(lossCurr4) >= parseFloat(lossCurr)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr) > parseFloat(lossCurr4)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}

if(parseInt(i) >= parseInt(only_lowNo) && parseInt(i) < parseInt(only_highNo)){
//console.log('only_lowNo and only_highNo check i : ' + i);
for(let a=0; a < highNo.length; a++){
if(parseInt(i) >= parseInt(highNo[a].bet_rate) && parseInt(highNo[a].bet_rate) < parseInt(only_highNo)){
// console.log('check i : ' + i + ' -- ' + highNo[a].bet_rate);
lossCurr1 += highNo[a].loss;
lossCurr3 = (parseFloat(HighNo_prf) - parseFloat(lossCurr1)) - parseFloat(lossCurr1);
if(lossCurr3 <= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(lowNo_los);
lossCurr4 = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(lossCurr3 > 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(lowNo_los);
lossCurr4 = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}  
}
}

if(parseInt(i) >= parseInt(only_highNo)){
//console.log('only_highNo check i : ' + i);
lossCurr = parseFloat(HighNo_los) + parseFloat(lowNo_los);
lossCurr4 = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}

}
else if(parseInt(new_highRateYes) > 0 && parseInt(only_lowNo) <= 0){
if(parseInt(i) >= parseInt(new_highRateYes)){
//console.log('new_highRateYes check i : ' + i);
lossCurr = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
lossCurr4 = parseFloat(lowNo_los);
if(parseFloat(lossCurr4) >= parseFloat(lossCurr)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr) > parseFloat(lossCurr4)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}
else if(parseInt(i) >= parseInt(only_highNo) && parseInt(only_highNo) > 0 && parseInt(new_highRateYes) <= 0){
//console.log('only_highNo check i : ' + i);
lossCurr = parseFloat(HighNo_los);
lossCurr2 = parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr2)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr2)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr2) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr2) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(parseInt(i) >= parseInt(only_highYes) && parseInt(i) < parseInt(only_lowNo) && parseInt(new_highRateYes) <= 0){
//console.log('only_highYes and only_lowNo and new_highRateYes 0 check i : ' + i);
lossCurr = parseFloat(yesLow_prf) + parseFloat(HighNo_prf);
amountCurr = '<span class="dangerTxt">'+ ((lossCurr).toFixed(2)) + '</span>';
}
else if(parseInt(i) >= parseInt(only_lowNo) && parseInt(i) < parseInt(only_highNo) && parseInt(new_highRateYes) <= 0){
//console.log('only_lowNo and only_highNo check i : ' + i);
for(let a=0; a < highNo.length; a++){
if(parseInt(i) >= parseInt(highNo[a].bet_rate) && parseInt(highNo[a].bet_rate) < parseInt(only_highNo)){
// console.log('check i : ' + i + ' -- ' + highNo[a].bet_rate);
lossCurr1 += highNo[a].loss;
lossCurr3 = (parseFloat(HighNo_prf) - parseFloat(lossCurr1)) - parseFloat(lossCurr1);
if(lossCurr3 <= 0){
lossCurr = parseFloat(Math.abs(lossCurr3)) + parseFloat(lowNo_los);
lossCurr4 = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
if(parseFloat(lossCurr) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(lossCurr) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(lossCurr)){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(lossCurr)).toFixed(2)) + '</span>';
}
}
else if(lossCurr3 > 0){
lossCurr = parseFloat(lossCurr3) - parseFloat(lowNo_los);
lossCurr4 = parseFloat(yesHigh_prf) + parseFloat(yesLow_prf);
if(lossCurr <= 0){
if(parseFloat(Math.abs(lossCurr)) >= parseFloat(lossCurr4)){
amountCurr = '<span class="successTxt">'+ ((parseFloat(Math.abs(lossCurr)) - parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
else if(parseFloat(lossCurr4) > parseFloat(Math.abs(lossCurr))){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr4) - parseFloat(Math.abs(lossCurr))).toFixed(2)) + '</span>';
}
}
else if(lossCurr > 0){
amountCurr = '<span class="dangerTxt">'+ ((parseFloat(lossCurr) + parseFloat(lossCurr4)).toFixed(2)) + '</span>';
}
}
}  
}
}

}

document.getElementById('bookTbody').innerHTML += `<tr>
<td>${i}</td>
<td>${amountCurr}</td>
</tr>`;
}

}
else {
$('.book-loader').css({display: 'none'});
$('.bookTbale').css({display: 'table'});
document.getElementById('bookTbody').innerHTML = `<tr><td colspan='2' style='border: none'><span>no record found...</span></td></tr>`;
}
}
else{
$('.book-loader').css({display: 'none'});
$('.bookTbale').css({display: 'table'});
document.getElementById('bookTbody').innerHTML = `<tr><td colspan='2' style='border: none'><span>no record found...</span></td></tr>`;
}
} catch (error) {
console.log('backend book fancy api error: ' + error);
}
}

export {book_Fancy};