import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useLocation } from 'react-router-dom';
import UserBook from './UserBook';
import MasterBook from './MasterBook';

const MarketList = () => {

const location = useLocation();
const { unsetBets } = GlobalContext();
const [listSec, setListSec] = useState([]);
const [reqSec, setReqSec] = useState(null);

const closeMarketList = () => {
$('#marketListModal').hide();
}

const openSecList = (sec) => {
var listRole =  $('#marketListRole').text();
if(listRole === 'customer'){
$('#userBookModal').show();
setReqSec(sec);
}else{
$('#masterBookModal').show();
setReqSec(sec);   
}
}

useEffect(() =>{
var allSec = [];
if(unsetBets.length > 0){
unsetBets.filter((e) => e.eventid === (location.state !== null ? location.state.eventid : 0)).forEach(element => {
if(element.sec !== 'fancy' && element.sec !== 'over' && element.sec !== 'oddeven' && element.sec !== 'meter' && element.sec !== 'line' && element.sec !== 'khado' && element.sec !== 'ball' && element.sec !== 'fancy1'){
var data = {
sec: element.sec
}

const findIndex = allSec.findIndex((o) => o.sec === element.sec);

if(findIndex === -1){
allSec.push(data);
}
}
});
}

setListSec(allSec);
}, [unsetBets, location.state]);

return (
<>
<div className='modalsSm' id='marketListModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>market list</span> <i className='fa fa-close right' onClick={() => closeMarketList()}></i></div>
<div className='modalsSm_body'>

<div className='bookUlWrap'>
<span style={{display: 'none'}} id='marketListRole'></span>
{
listSec.length > 0 ? listSec.map((data, index) => {
return (
<ul className='bookUl' key={index}>
<li onClick={() => openSecList(data.sec)}>{data.sec}</li>
</ul>
)
})
:
<ul className='bookUl'>
<li>no market list available...</li>
</ul>
}
</div>

</div>
</div>
</div>

<UserBook reqSec={reqSec} setReqSec={setReqSec} />
<MasterBook reqSec={reqSec} setReqSec={setReqSec} />
</>
)
}

export default MarketList;
