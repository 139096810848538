import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';

const ViewSettle = () => {

const location = useLocation();
const { unsetBets } = GlobalContext();
const [eventBets, setEventBets] = useState([]);
const [totalSec, setToalSec] = useState([]);
const [totalFancy, setTotalFancy] = useState([]);
//console.log(eventBets);
//console.log(totalSec);
//console.log(totalFancy);

const settleFancy = async(index,team,sec) => {
var run = $('#fancyRate_'+index).val();
var check = $('#fancyCheck_'+index).is(':checked');
var sport = location.state !== null ? location.state.sport : null;
var event = location.state !== null ? location.state.event : null;
var eventid = location.state !== null ? location.state.eventid : 0;

if(run === '' && check !== true){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('run required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/fancy_settle', {
run: run,
team: team,
sport: sport,
event: event,
eventid: eventid,
check: check,
sec: sec
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('settlement successfully');
setTimeout(function(){
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend fancySettle error : ' + error);
}
}
}

const handleFRate = (val,index) => {
if(val <= 0){
$('#fancyRate_'+index).val(0);
}
else{
$('#fancyRate_'+index).val(parseFloat(val !== '' ? val : 0).toFixed());
}
}

const oddsSettle = async(index,sec) => {
var winner = $('#oddsName_'+index).val().trim();
var check = $('#oddsCheck_'+index).is(':checked');
var sport = location.state !== null ? location.state.sport : null;
var event = location.state !== null ? location.state.event : null;
var eventid = location.state !== null ? location.state.eventid : 0;
//console.log(winner +' -- '+ sec);
if(winner === '' && check !== true){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('winner name required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/odds_settle', {
winner: winner,
sport: sport,
event: event,
eventid: eventid,
sec: sec,
check: check
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('settlement successfully');
setTimeout(function(){
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend oddsSettle error : ' + error);
}
}
}

const copyTextFun = (id) => {
var copyText = document.getElementById('copyText_'+id).innerHTML;
navigator.clipboard.writeText(copyText);
$('#CopyAlert').show();
setTimeout(function(){
$('#CopyAlert').hide();
},2000);
}

const voidCheck = (index) => {
var check = $('#fancyCheck_'+index).is(':checked');
if(check === true){
$('#fancyRate_'+index).attr('disabled', 'disabled');
}
else{
$('#fancyRate_'+index).removeAttr('disabled');   
}
}

const voidCheckOdds = (index) => {
var check = $('#oddsCheck_'+index).is(':checked');
if(check === true){
$('#oddsName_'+index).attr('disabled', 'disabled');
}
else{
$('#oddsName_'+index).removeAttr('disabled');   
} 
}

useEffect(() => {
if(unsetBets.length > 0){
setEventBets(unsetBets.filter((e) => e.sport === (location.state !== null ? location.state.sport : null) && e.eventid === (location.state !== null ? location.state.eventid : 0) && e.eventName === (location.state !== null ? location.state.event : null)));
}
},[unsetBets, location.state]);

useEffect(() => {
if(eventBets.length > 0){
var allSec = [];
var allFancy = [];
eventBets.forEach((e) => {
var data = {
sec: e.sec
}

const findInd = allSec.findIndex((o) => o.sec === e.sec);

if(findInd === -1){
allSec.push(data);
}

if(e.sec === 'fancy' || e.sec === 'ball' || e.sec === 'over' || e.sec === 'meter' || e.sec === 'khado' || e.sec === 'oddeven' || e.sec === 'fancy1' || e.sec === 'line'){
var data2 = {
sec: e.sec,
team: e.team
}

const findInd2 = allFancy.findIndex((o) => o.sec === e.sec && o.team === e.team);
if(findInd2 === -1){
allFancy.push(data2);
}
}
});
setToalSec(allSec);
setTotalFancy(allFancy);
}
}, [eventBets]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='CopyAlert' id='CopyAlert'>
<span>text copied!</span>
</div>

<div className='headingTitle'><span>{location.state !== null ? location.state.sport : null} settlement</span></div>

<div className='profilePage'>
<div className='headerProfile'><span>{location.state !== null ? location.state.event +' ('+ location.state.eventid +')' : null}</span></div>

<div className='settleBody'>
{
totalSec.length > 0 ? totalSec.map((data, index) => {
return (
<div className='betsSettleWrap' key={index}>
<div className='betsSettleTitle'><span>{data.sec}</span></div>

<div className='betSettleBody'>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line' ?
totalFancy.length && totalFancy.map((data2, index2) => {
return (
<div className='fancyTeamWrap' key={index2}>
<div className='fancyTeamTitle'><span>{data2.team}</span></div>

<div className='table_responsiveAuto'>
<table className='settleTable'>
<thead>
<tr>
<th>customer</th>
<th>runner</th>
<th>odds</th>
<th>size</th>
<th>bettype</th>
<th>betamount</th>
<th>profit</th>
<th>loss</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
eventBets.filter((e) => e.sec === data2.sec && e.team === data2.team).map((data3, index3) => {
const selfDate = data3.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data3.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
return (
<tr key={index3}>
<td>{data3.customer}</td>
<td>{data3.team}</td>
<td>{data3.betRate}</td>
<td>{data3.betSize}</td>
<td>{data3.bettype === 'back' ? 'yes' : 'no'}</td>
<td>{parseFloat(data3.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.loss.toFixed(2)).toLocaleString()}</td>
<td>{placeDate}</td>
<td>{data3.bet_dateTime}</td>
</tr>
)
})
}
</tbody>
</table>
</div>

<div className='settleDivWrap'>
<div className='settlecol'>
<label><input className='voidCheck' type='checkbox' id={'fancyCheck_'+index2} onClick={() => voidCheck(index2)} /> void</label>   
<input className='fancyRate' type='number' name='fancyRate' id={'fancyRate_'+index2} placeholder='enter run' onChange={(e) => handleFRate(e.target.value,index2)} />
<button className='settleBtn' onClick={() => settleFancy(index2,data2.team,data2.sec)}>settle</button>
</div>
</div>
</div>
)
})
:
<div className='otherSecWrap'>
<div className='table_responsiveAuto'>
<table className='settleTable'>
<thead>
<tr>
<th>customer</th>
<th>runner</th>
<th>odds</th>
<th>bettype</th>
<th>betamount</th>
<th>profit</th>
<th>loss</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
eventBets.filter((e) => e.sec === data.sec).map((data3, index3) => {
const selfDate = data3.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data3.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
return (
<tr key={index3}>
<td>{data3.customer}</td>
<td><span className='links' id={'copyText_'+data3.id} onClick={() => copyTextFun(data3.id)}>{data3.team}</span></td>
<td>{data3.betRate}</td>
<td>{data3.bettype}</td>
<td>{parseFloat(data3.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.loss.toFixed(2)).toLocaleString()}</td>
<td>{placeDate}</td>
<td>{data3.bet_dateTime}</td>
</tr>
)
})
}
</tbody>
</table>
</div>

<div className='settleDivWrap'>
<div className='settlecol'>
<label><input className='voidCheck' type='checkbox' id={'oddsCheck_'+index} onClick={() => voidCheckOdds(index)} /> void</label>  
<input type='text' name='oddsName' id={'oddsName_'+index} className='fancyRate' placeholder='enter name' />
<button className='settleBtn' onClick={() => oddsSettle(index,data.sec)}>settle</button>
</div>
</div>
</div>
} 
</div>
</div>
)
})
:
<div className='stateBody'>
<div className='norecordSettle'>
<img src='/images/cloud.png' alt='cloud' />
<span>no record found...</span>
</div>
</div>
}
</div>

</div>

</div>
</div>
</div>
</>
)
}

export default ViewSettle;
