import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';
import CreditModal from '../../modals/CreditModal';
import DepositMod from './DepositMod';
import WithDrawMod from './WithDrawMod';
import StatusAlert from '../StatusAlert';

const UserBanking = () => {

const { userList, useExpo, userActive } = GlobalContext();
const [currUsers, setCurrUsers] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const creditRef = (id,user,credit) => {
$('#creditModal').show();
$('#userEditM').text(user);
$('#oldCreditRef').text(credit);
$('#creditId').val(id);
}

const depoShow = (id,user,role) => {
var amountBanking = $('#amountBanking_'+id).val();
var remarkBanking = $('#remarkBanking_'+id).val();

if(amountBanking <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('amount required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(remarkBanking === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#ModalBanking').show();
var a = role.split('_');
$('#rollUserBank').text(a[0]);
$('#UserBank').text(user);
$('#bankAmt').text(parseFloat(amountBanking).toLocaleString());
$('#bankRmk').text(remarkBanking);
$('#depoIdBank').val(id);
}
}

const withdrawShow = (id,user,role,availTotal) => {
var amountBanking = $('#amountBanking_'+id).val();
var remarkBanking = $('#remarkBanking_'+id).val();

if(amountBanking <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('amount required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(remarkBanking === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#ModalBanking2').show();
var a = role.split('_');
$('#rollUserBank2').text(a[0]);
$('#UserBank2').text(user);
$('#bankAmt2').text(parseFloat(amountBanking).toLocaleString());
$('#bankRmk2').text(remarkBanking);
$('#depoIdBank2').val(id);
$('#availDrw').val(availTotal);
}
}

const searchUsers = (name) => {
setCurrUsers(userList.filter((e) => e.user_role.trim() === 'customer_role' && e.customer.includes(name)));
}

useEffect(() => {
if(userList.length > 0){
setCurrUsers(userList.filter((e) => e.user_role.trim() === 'customer_role'));
}
},[userList]);

useEffect(() => {
if(records.length > 0){
if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.depoSm').css({background: '#ccc'}).attr('disabled', 'disabled');
}
}
},[userActive, records]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>user banking</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>currency</th>
<th>balance</th>
<th>avail. d/w</th>
<th>exposure</th>
<th>credit ref.</th>
<th>ref. p/l</th>
<th>deposit / withdrawl / remark </th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
const currExpo = useExpo.filter((e) => e.customer === data.customer);

return(
<tr key={index}>
<td><span className='roleTxt'>{data.user_role.slice(0, 3)}</span> {data.customer}</td>
<td>{data.currency}</td>
<td>{parseFloat(data.total_balance.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat((data.total_balance - (currExpo.length > 0 ? currExpo[0].exposure : 0)).toFixed(2)).toLocaleString()}</td>
<td><span className='dangerTxt'>({currExpo.length > 0 ? parseFloat(currExpo[0].exposure.toFixed(2)).toLocaleString() : 0})</span></td>
<td>{parseFloat(data.creditRefrence.toFixed(2)).toLocaleString()} <i className='fa fa-edit editIcon' onClick={() => creditRef(data.id,data.customer,data.creditRefrence)}></i></td>
<td>{parseFloat(data.creditRefrence) > parseFloat(data.total_balance) ? <span className='dangerTxt'>({parseFloat((data.total_balance - data.creditRefrence).toFixed(2)).toLocaleString()})</span> : <span className='successTxt'>({parseFloat((data.total_balance - data.creditRefrence).toFixed(2)).toLocaleString()})</span>}</td>
<td>
<div className='amountInput'>
<input type='number' className='amountBanking' name='amountBanking' id={'amountBanking_'+data.id} placeholder='enter amount...' />
<input type='text' className='amountBanking' name='remarkBanking' id={'remarkBanking_'+data.id} placeholder='remark...' />
<button className='depoSm success' onClick={() => depoShow(data.id, data.customer, data.user_role)}>d</button>
<button className='depoSm danger' onClick={() => withdrawShow(data.id, data.customer, data.user_role, (data.total_balance - (currExpo.length > 0 ? currExpo[0].exposure : 0)))}>w</button>
</div>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>

<CreditModal />
<DepositMod />
<WithDrawMod />
</>
)
}

export default UserBanking;
