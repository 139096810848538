import axios from 'axios';
import $ from 'jquery';

var startResult_ab;

function runresult_ab(){
fetchResult_ab();
startResult_ab = setInterval(fetchResult_ab, 2000);
}

async function fetchResult_ab() {
try {
const getData = await axios.get('/server2/andarBahar_Result');
const resData = await getData.data;
//console.log('result');
if(resData.data === undefined){
clearInterval(startResult_ab);
setTimeout(function(){
startResult_ab = setInterval(fetchResult_ab, 1000);
},1000);
}
else{
if(document.getElementById('allResCas_ab') !== null){
document.getElementById('allResCas_ab').innerHTML = '';
resData.data.forEach(element => {
document.getElementById('allResCas_ab').innerHTML += `<span id='casResFull_${element.mid}' class='dotOne_ab'>R</span>`;
});   
}
}
} catch (error) {
clearInterval(startResult_ab);
setTimeout(function(){
startResult_ab = setInterval(fetchResult_ab, 1000);
},1000);
console.log('backend casino_andarBahar_result error : ' + error);
}
}

$(document).on('click', '.dotOne_ab', function(e){
casinoResMod_ab(e.target.id);
$('#casResModal_ab').show();
$('.casDetResLoad_ab').css({display: 'flex'});
$('.resCasCon_ab').hide();
});

async function casinoResMod_ab(mid) {
$('#record_no_ab').hide();
var midSplit = mid.split('_')[1];
try {
const getData = await axios.get('/server2/casinoDetailsResult/'+midSplit);
const resData = await getData.data;
if(resData.data !== undefined){
//console.log(resData.data);
const allcard = resData.data[0].cards.toUpperCase().split('*');
var andarSplit = allcard[0].split(',');
var baharSplit = allcard[1].split(',');
//console.log(andarSplit);
if(andarSplit.length > 0){
andarSplit.forEach((cards) => {
if(cards !== ''){
var andarImgMods = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+cards+'.jpg';
document.getElementById('andarCardMod').innerHTML += `<img src='${andarImgMods}' alt='ab_mod_cards' />`;
}
});
}

if(baharSplit.length > 0){
baharSplit.forEach((cards) => {
if(cards !== ''){
var baharImgMods = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+cards+'.jpg';
document.getElementById('baharCardMod').innerHTML += `<img src='${baharImgMods}' alt='ab_mod_cards' />`;
}
});
}
//console.log(allcard);
$('#winCas_abMid').text(resData.data[0].mid).show();
$('.casDetResLoad_ab').css({display: 'none'});
$('.resCasCon_ab').show();
}
else{
$('.casDetResLoad_ab').hide();
$('#winCas_abMid').hide();
$('.resCasCon_ab').hide();
$('#record_no_ab').show();
}
} catch (error) {
console.log('backend casino_andarBahar_details_result error : ' + error);
}
}

export { startResult_ab, runresult_ab, casinoResMod_ab };