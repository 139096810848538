import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const userActive_api = '/server2/active-user';
const usersList_api = '/server2/users-list';
const userBanking_api = '/server2/user_bankingData';
const totalBal_api = '/server2/users-total-bal';
const userExpo_api = '/server2/exposure-user';
const allUsers_api = '/server2/users-list-all';

const AddMaster = () => {

const [isChecked, setIsChecked] = useState(false);
const { userActive, userBanking, getUserLists, activeUser, userBalTotal, userExpo, userAll } = GlobalContext();

const sliderCheck = () => {
setIsChecked(!isChecked);
//console.log(isChecked);
if(isChecked === false){
$('#toogleDiv').show();
}else{
$('#toogleDiv').hide();
}
}

const closeMasterAdd = () => {
$('#masterModal').hide();
$('.formMaster').val('');
setIsChecked(false);
$('#toogleDiv').hide();
$('select').prop('selectedIndex', 0);
}

const createMaster = async() => {
var username = $('#usernameM').val();
var name = $('#nameM').val();
var aacount = $('#aacountM').val();
var currency = $('#currencyM').val();
var commision = $('#commisionM').val();
var opening = $('#openingM').val();
var credit = $('#creditM').val();
var mobile = $('#mobileM').val();
var partner = $('#partnerM').val();
var password = $('#passwordM').val();
var confPassword = $('#confPasswordM').val();
var fancy = $('#fancyM').val();
var casino = $('#casinoM').val();
var sportsbook = $('#sportsbookM').val();
var bookmaker = $('#bookmakerM').val();
var virtual = $('#virtualM').val();
var adminPassword = $('#adminPasswordM').val();
var creator = userActive.length > 0 ? userActive[0].customer : null;
var creator_admin = null;
var creator_subAdmin = null;
var creator_super = null;
var creator_master = null;
var creator_agent = null;
if(userActive.length > 0 && userActive[0].user_role === 'admin_role'){
creator_admin = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'subadmin_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'super_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].sub_admin;
creator_super = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'master_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].sub_admin;
creator_super = userActive[0].super_master;
creator_master = userActive[0].customer;
}

if(partner <= 0){
partner = 100;
}

if(username === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('username required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(aacount === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('account type required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(currency === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('currency type required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(opening <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('opening balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(mobile === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('mobile number required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(password.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min 6 digit password required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(password !== confPassword){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('confirm password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(adminPassword !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/add-users', {
username: username,
name: name,
aacount: aacount,
currency: currency,
commision: commision,
opening: opening,
credit: credit,
mobile: mobile,
partner: partner,
password: password,
fancy: fancy,
casino: casino,
sportsbook: sportsbook,
bookmaker: bookmaker,
virtual: virtual,
creator: creator,
creator_admin: creator_admin,
creator_subAdmin: creator_subAdmin,
creator_super: creator_super,
creator_master: creator_master,
creator_agent: creator_agent
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('user created successfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userAll(allUsers_api);
userBanking(userBanking_api);
userBalTotal(totalBal_api);
userExpo(userExpo_api);
$('#masterModal').hide();
$('.formMaster').val('');
setIsChecked(false);
$('#toogleDiv').hide();
$('select').prop('selectedIndex', 0);
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log("backend add-master-api error : " + error);
}
}
}

useEffect(() => {
if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.formControlsSubt button').css({background: '#ccc'}).attr('disabled', 'disabled');
} 
},[userActive]);

return (
<>
<div className='modals' id='masterModal'>
<div className='modalsContent'>
<div className='modalsHeader'>
<span><i className='fa fa-user-plus'></i> add master</span>
<i className='fa fa-close right' onClick={() => closeMasterAdd()}></i>
</div>
<div className='modalsBody'>
<div className='formDiv'>
<div className='formControls'>
<div><label>username*</label></div>
<div><input type='text' className='formMaster' name='usernameM' id='usernameM' placeholder='username...' /></div>
</div>
<div className='formControls'>
<div><label>name</label></div>
<div><input type='text' className='formMaster' name='nameM' id='nameM' placeholder='name...' /></div>
</div>
<div className='formControls'>
<div><label>account type*</label></div>
<div>
{
userActive.length > 0 && userActive[0].user_role === 'admin_role' ?
<select id='aacountM'>
<option value='null'>--select account type--</option>
<option value='agent'>agent</option>
<option value='master'>master</option>
<option value='super master'>super master</option>
<option value='sub admin'>sub admin</option>
</select>
: userActive.length > 0 && userActive[0].user_role === 'subadmin_role' ?
<select id='aacountM'>
<option value='null'>--select account type--</option>
<option value='agent'>agent</option>
<option value='master'>master</option>
<option value='super master'>super master</option>
</select>
: userActive.length > 0 && userActive[0].user_role === 'super_role' ?
<select id='aacountM'>
<option value='null'>--select account type--</option>
<option value='agent'>agent</option>
<option value='master'>master</option>
</select>
:
<select id='aacountM'>
<option value='null'>--select account type--</option>
<option value='agent'>agent</option>
</select>
}
</div>
</div>
<div className='formControls'>
<div><label>currency type*</label></div>
<div>
{
userActive.length > 0 && userActive[0].user_role !== 'admin_role' ?
<select id='currencyM' disabled>
<option value={userActive[0].currency}>{userActive[0].currency.toUpperCase()}</option>   
</select>
:
<select id='currencyM'>
<option value='null'>--select currency type--</option>
<option value='inr'>INR</option>
<option value='hkd'>HKD</option>
</select>
}
</div>
</div>
<div className='formControls'>
<div><label>commision</label></div>
<div><input type='number' className='formMaster' name='commisionM' id='commisionM' placeholder='commision...' /></div>
</div>
<div className='formControls'>
<div><label>opening balance*</label></div>
<div><input type='number' className='formMaster' name='openingM' id='openingM' placeholder='opening balance...' /></div>
</div>
<div className='formControls'>
<div><label>credit refrence</label></div>
<div><input type='number' className='formMaster' name='creditM' id='creditM' placeholder='credit refrence...' /></div>
</div>
<div className='formControls'>
<div><label>mobile number*</label></div>
<div><input type='number' className='formMaster' name='mobileM' id='mobileM' placeholder='mobile number...' /></div>
</div>
<div className='formControls'>
<div><label>partnership</label></div>
<div><input type='number' className='formMaster' name='partnerM' id='partnerM' placeholder='partnership...' /></div>
</div>
<div className='formControls'>
<div><label>password*</label></div>
<div><input type='text' className='formMaster' name='passwordM' id='passwordM' placeholder='password...' /></div>
</div>
<div className='formControls'>
<div><label>confirm password*</label></div>
<div><input type='password' className='formMaster' name='confPasswordM' id='confPasswordM' placeholder='confirm password...' /></div>
</div>
<div className='formControls'>
<div><label>rolling commision</label></div>
<div>
<label className='slidersM'>
<input type='checkbox' checked={isChecked} onChange={sliderCheck} />
<span className='sliderBox'></span>
</label>
</div>
</div>
<div className='toogleDiv' id='toogleDiv'>
<div className='formControls'>
<div><label>fancy</label></div>
<div><input type='number' className='formMaster' name='fancyM' id='fancyM' placeholder='fancy...' /></div>
</div>
<div className='formControls'>
<div><label>casino</label></div>
<div><input type='number' className='formMaster' name='casinoM' id='casinoM' placeholder='casino...' /></div>
</div>
<div className='formControls'>
<div><label>sportsbook</label></div>
<div><input type='number' className='formMaster' name='sportsbookM' id='sportsbookM' placeholder='sportsbook...' /></div>
</div>
<div className='formControls'>
<div><label>bookmaker</label></div>
<div><input type='number' className='formMaster' name='bookmakerM' id='bookmakerM' placeholder='bookmaker...' /></div>
</div>
<div className='formControls'>
<div><label>virtual sports</label></div>
<div><input type='number' className='formMaster' name='virtualM' id='virtualM' placeholder='virtual sports...' /></div>
</div>
</div>
<hr className='hrForm' />
<div className='formControls'>
<div><label>admin password*</label></div>
<div><input type='password' className='formMaster' name='adminPasswordM' id='adminPasswordM' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt'>
<button onClick={() => createMaster()}>create</button>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default AddMaster;
