import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MarketBets from '../../../../allBets/MarketBets';
import { check_t20, start_t20, pls_t20 } from './js/refresh';
import { startResult_t20, runresult_t20 } from './js/result';
import ResultT20 from '../modals/ResultT20';
import T20Rules from '../modals/T20Rules';

const T20 = () => {

const [load_t20, setLoadT20] = useState(true);
const [curr_t20, setCurrT20] = useState([]);

const openT20Rules = () => {
$('#t20Modal').show();
}

useEffect(() => {
async function fetcht20() {
try {
const getData = await axios.get('/server2/teenpattiT20Data');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadT20(false);
setCurrT20(resData);
}
else{
setLoadT20(true);
setCurrT20([]);
}
} catch (error) {
console.log('backend casino_t20_data error : ' + error);
}
}

fetcht20();
},[]);

useEffect(() => {
check_t20();
runresult_t20();
pls_t20(curr_t20.data !== undefined ? curr_t20.data.t1[0].mid : 0,'t20');
return () => {
clearInterval(start_t20);
clearInterval(startResult_t20);
}
},[curr_t20]);

if(load_t20){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
curr_t20.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti 20 20</span>
<span className='casGamerule' onClick={openT20Rules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_t20'>{curr_t20.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>player a</span>
<div className='teamLiveCards'>
<img id='card1_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>player b</span>
<div className='teamLiveCards'>
<img id='card4_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c4.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c5.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c6.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_t20'>{curr_t20.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3030' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh'><span>back</span></th>
<th className='backTh'></th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t20.data.t2[0].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t20_'+curr_t20.data.t2[0].sid}>0.00</span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t20_'+curr_t20.data.t2[0].sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[0].sid}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[0].sid}>{curr_t20.data.t2[0].rate}</span>
<span className='casSize'>0.00</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[1].sid}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[1].sid}>{curr_t20.data.t2[1].rate}</span>
<span className='casSize'>{curr_t20.data.t2[1].nation}</span>
</button>
</td>
</tr>

<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t20.data.t2[2].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t20_'+curr_t20.data.t2[2].sid}>0.00</span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t20_'+curr_t20.data.t2[2].sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[2].sid}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[2].sid}>{curr_t20.data.t2[2].rate}</span>
<span className='casSize'>0.00</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[3].sid}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[3].sid}>{curr_t20.data.t2[3].rate}</span>
<span className='casSize'>{curr_t20.data.t2[3].nation}</span>
</button>
</td>
</tr>
</tbody>
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_t20'></div>
</div>

<MarketBets eventid={curr_t20.data !== undefined ? curr_t20.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<ResultT20 />
<T20Rules />
</>
)
}

export default T20;
