import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';
const userActive_api = '/server2/active-user';
const userBanking_api = '/server2/user_bankingData';

const Banking = () => {

const { userActive, getUserLists, activeUser, userBanking } = GlobalContext();

const closeBanking = () => {
$('#bankingModal').hide();
$('.clearBankFrm').val('');
}

const submitBanking = async() => {
var id = $('#bankingId').val();
var newBank = $('#newBank').val();
var newRemark = $('#newRemark').val();
var newBankPass = $('#newBankPass').val();

if(newBank <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newRemark === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newBankPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/deposit_userBanking', {
id: id,
newBank: newBank,
newRemark: newRemark,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('deposit banking successfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
$('#bankingModal').hide();
$('.clearBankFrm').val('');
},1000);
return false;
}else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend deposit-banking-api error : ' + error);
}
}
}

const withdrawBanking = async() => {
var id = $('#bankingId').val();
var newBank = $('#newBank').val();
var newRemark = $('#newRemark').val();
var newBankPass = $('#newBankPass').val();
var mainBalance = $('#availBal').val();

if(newBank <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newRemark === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newBankPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(newBank) > parseFloat(mainBalance)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('insufficiant balance');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/withdrawl_banking', {
id: id,
newBank: newBank,
newRemark: newRemark  
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('withdrawal banking sccessfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
$('#bankingModal').hide();
$('.clearBankFrm').val('');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend withdrawl-banking-api error : ' + error);
}
}
}

var role = null;
if(userActive.length > 0){
var a = userActive[0].user_role.split('_');
role = a[0];
if(userActive[0].status !== 'active'){
$('.formControlsSubt2 button').css({background: '#ccc'}).attr('disabled', 'disabled');
}
}

return (
<>
<div className='modalsSm' id='bankingModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'>
<span>edit banking - </span>
<span className='roleTxt'>{role === 'admin' ? 'w_label' : role}</span>
<span> {userActive.length > 0 ? userActive[0].customer : null} : </span>
<span>{userActive.length > 0 ? parseFloat(userActive[0].total_balance.toFixed(2)).toLocaleString() : null}</span>
<i className='fa fa-close right' onClick={() => closeBanking()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>username</label></div>
<div>
<span className='roleTxt' id='cuurBankUserRole'></span> 
<span id='cuurBankUser'></span>
<input className='clearBankFrm' type='hidden' id='bankingId' />
<input className='clearBankFrm' type='hidden' id='availBal' />
</div>
</div>
<div className='formControls'>
<div><label>client balance</label></div>
<div><span id='clientBalB'></span></div>
</div>
<div className='formControls'>
<div><label>new balance</label></div>
<div><input className='clearBankFrm' type='number' name='newBank' id='newBank' placeholder='new balance...' /></div>
</div>
<div className='formControls'>
<div><label>remark</label></div>
<div><input className='clearBankFrm' type='text' name='newRemark' id='newRemark' placeholder='remark...' /></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearBankFrm' type='password' name='newBankPass' id='newBankPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt2'>
<button onClick={() => submitBanking()}>deposit</button>
<button onClick={() => withdrawBanking()}>withdrawl</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Banking;
