import axios from 'axios';
import $ from 'jquery';

var start_t20;

async function check_t20(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_t20 = setInterval(fetcht20, 1000);
}
else{
console.log('login error');
}
}

async function fetcht20(){
try {
const getData = await axios.get('/server2/teenpattiT20Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
$('.odds_cas').attr('disabled', 'disabled');
clearInterval(start_t20);
setTimeout(function(){
start_t20 = setInterval(fetcht20, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('#plsCas_t20_1').text('0.00').css({color: 'black'});
$('#plsCas_t20_3').text('0.00').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_t20').text(resData.data.t1[0].autotime);
$('#roundId_t20').text(resData.data.t1[0].mid);
$('#eventid_t20').text(resData.data.t1[0].mid);

$('#card1_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');
$('#card3_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c3.toUpperCase()+'.jpg');
$('#card4_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c4.toUpperCase()+'.jpg');
$('#card5_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c5.toUpperCase()+'.jpg');
$('#card6_t20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c6.toUpperCase()+'.jpg');

if(resData.data.t2[0].gstatus === '0'){
$('#locCasino_t20_'+resData.data.t2[0].sid).css({display: 'flex'});
}
else if(resData.data.t2[0].gstatus === '1'){
$('#locCasino_t20_'+resData.data.t2[0].sid).hide();
}

if(resData.data.t2[2].gstatus === '0'){
$('#locCasino_t20_'+resData.data.t2[2].sid).css({display: 'flex'});
}
else if(resData.data.t2[2].gstatus === '1'){
$('#locCasino_t20_'+resData.data.t2[2].sid).hide();
}

}
} catch (error) {
clearInterval(start_t20);
setTimeout(function(){
start_t20 = setInterval(fetcht20, 1000);
},1000);
console.log('backend refresh_t20_data error : ' + error);
}
}

async function pls_t20(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
var t1Prf = 0;
var t2Prf = 0;
var runner1 = 'player a';
var runner2 = 'player b';

response.forEach((exx) => {
if(exx.team.trim() === runner1 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === runner2 && exx.bettype === 'back'){
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
}
});

if(t1Prf > 0){
$('#plsCas_t20_1').html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
else{
$('#plsCas_t20_1').html(parseFloat(Math.abs(t1Prf).toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}

if(t2Prf > 0){
$('#plsCas_t20_3').html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
else{
$('#plsCas_t20_3').html(parseFloat(Math.abs(t2Prf).toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}

}
} catch (error) {
console.log('backend t20_pls bets api : ' + error);
}
}

export {
check_t20,
start_t20,
pls_t20
};