import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MarketBets from '../../../../allBets/MarketBets';
import { check_dt20, start_dt20, allSid_dt20, pls_dt20 } from './js/refresh';
import { runresult_dt20, startResult_dt20 } from './js/result';
import Resultdt from '../modals/Resultdt';
import DtRules from '../modals/DtRules';


const Dt20 = () => {

const [load_dt20, setLoad_dt20] = useState(true);
const [currdt20, setCurrDt20] = useState([]);

const opendt20Rules = () => {
$('#dtModal').show();
}

useEffect(() => {
async function fetchDt20() {
try {
const getData = await axios.get('/server2/dt20_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nation : element.nation
}

const findInd = allSid_dt20.findIndex((o) => o.sid === element.sid && o.nation === element.nation);
if(findInd === -1){
allSid_dt20.push(data);
}
});
setLoad_dt20(false);
setCurrDt20(resData);
}
else{
setLoad_dt20(true);
setCurrDt20([]);
}
} catch (error) {
console.log('backend casino_dt20_data error : ' + error);
}
}

fetchDt20();
},[]);

useEffect(() => {
check_dt20();
runresult_dt20();
pls_dt20(currdt20.data !== undefined ? currdt20.data.t1[0].mid : 0,'dt20');
return () => {
clearInterval(start_dt20);
clearInterval(startResult_dt20);
}
},[currdt20]);

if(load_dt20){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
currdt20.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>DT 20 20</span>
<span className='casGamerule' onClick={opendt20Rules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_dt20'>{currdt20.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_dt20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt20.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_dt20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt20.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_dt20'>{currdt20.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3035' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon / tiger </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[0].sid}>{currdt20.data.t2[0].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[0].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[0].sid}>{currdt20.data.t2[0].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[0].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[0].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[1].sid}>{currdt20.data.t2[1].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[1].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[1].sid}>{currdt20.data.t2[1].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[1].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[1].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tie / pair </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[2].sid}>{currdt20.data.t2[2].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[2].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[2].sid}>{currdt20.data.t2[2].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[2].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[2].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[3].sid}>{currdt20.data.t2[3].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[3].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[3].sid}>{currdt20.data.t2[3].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[3].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[3].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[4].sid}>{currdt20.data.t2[4].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[4].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[4].sid}>{currdt20.data.t2[4].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[4].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[4].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[5].sid}>{currdt20.data.t2[5].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[5].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[5].sid}>{currdt20.data.t2[5].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[5].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[5].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[6].sid}>{currdt20.data.t2[6].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[6].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[6].sid}>{currdt20.data.t2[6].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[6].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[6].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[7].sid}>{currdt20.data.t2[7].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[7].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[7].sid}>{currdt20.data.t2[7].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[7].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[7].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[8].sid}>{currdt20.data.t2[8].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[8].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[8].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[9].sid}>{currdt20.data.t2[9].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[9].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[9].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[10].sid}>{currdt20.data.t2[10].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[10].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[10].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[11].sid}>{currdt20.data.t2[11].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[11].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[11].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[12].sid}>{currdt20.data.t2[12].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[12].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[12].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[12].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[13].sid}>{currdt20.data.t2[13].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[13].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[13].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[14].sid}>{currdt20.data.t2[14].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[14].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[14].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[15].sid}>{currdt20.data.t2[15].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[15].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[15].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[16].sid}>{currdt20.data.t2[16].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[16].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[16].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[17].sid}>{currdt20.data.t2[17].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[17].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[17].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[18].sid}>{currdt20.data.t2[18].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[18].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[18].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[18].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[19].sid}>{currdt20.data.t2[19].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[19].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[19].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[19].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[19].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[20].sid}>{currdt20.data.t2[20].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[20].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[20].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[20].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[20].sid}>0</span>
</div>

</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[21].sid}>{currdt20.data.t2[21].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[21].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[21].sid}>{currdt20.data.t2[21].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[21].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[21].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[22].sid}>{currdt20.data.t2[22].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[22].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[22].sid}>{currdt20.data.t2[22].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[22].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[22].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[23].sid}>{currdt20.data.t2[23].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[23].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[23].sid}>{currdt20.data.t2[23].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[23].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[23].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[24].sid}>{currdt20.data.t2[24].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[24].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[24].sid}>{currdt20.data.t2[24].nation}</span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[24].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[24].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tiger cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[25].sid}>{currdt20.data.t2[25].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[25].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[25].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[25].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[25].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[26].sid}>{currdt20.data.t2[26].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[26].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[26].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[26].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[26].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[27].sid}>{currdt20.data.t2[27].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[27].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[27].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[27].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[27].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[28].sid}>{currdt20.data.t2[28].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[28].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[28].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[28].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[28].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[29].sid}>{currdt20.data.t2[29].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[29].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[29].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[29].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[29].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[30].sid}>{currdt20.data.t2[30].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[30].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[30].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[30].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[30].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[31].sid}>{currdt20.data.t2[31].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[31].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[31].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[31].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[31].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[32].sid}>{currdt20.data.t2[32].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[32].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[32].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[32].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[32].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[33].sid}>{currdt20.data.t2[33].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[33].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[33].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[33].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[33].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[34].sid}>{currdt20.data.t2[34].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[34].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[34].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[34].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[34].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[35].sid}>{currdt20.data.t2[35].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[35].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[35].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[35].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[35].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[36].sid}>{currdt20.data.t2[36].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[36].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[36].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[36].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[36].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt20_Rate_'+currdt20.data.t2[37].sid}>{currdt20.data.t2[37].rate}</span>
<button id={'dt20_Team_'+currdt20.data.t2[37].sid}>
<span id={'dt20_TeamTx1_'+currdt20.data.t2[37].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt20_TeamTx2_'+currdt20.data.t2[37].sid}></span>
</button>
<span className='dtPls' id={'dt20_Pls_'+currdt20.data.t2[37].sid}>0</span>
</div>

</div>
</div>

</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_dt20'></div>
</div>

<MarketBets eventid={currdt20.data !== undefined ? currdt20.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<Resultdt />
<DtRules />
</>
)
}

export default Dt20;
