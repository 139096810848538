import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';
const removeUsersList_api = '/server2/remove_usersList';

const Remove = () => {

const { getUserLists, getRemoveUsrList } = GlobalContext();

const closeRemove = () =>{
$('#removeModal').hide();
$('.clearRemove').val('');
}

const removeUser = async() => {
var id = $('#removeUserId').val();
var user = $('#userRemove').text();
var role = $('#roleRemove').text();

//alert(id +' -- '+ user +' -- '+ role);

try {
const sendData = await axios.post('/server2/remove_users',{
id: id,
user: user,
role: role
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('removed user successfully');
setTimeout(function(){
$('#msgAlert').hide(); 
$('#removeModal').hide();  
$('.clearRemove').val('');
getUserLists(usersList_api);
getRemoveUsrList(removeUsersList_api);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide(); 
},1000);
}
} catch (error) {
console.log('backend remove-users-api error : ' + error);
}
}

return (
<>
<div className='modalsSm' id='removeModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>remove user - </span> <span className='roleTxt' id='roleRemove'></span> <span id='userRemove'></span> <i className='fa fa-close right' onClick={() => closeRemove()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/bin.png' alt='bin' />
<span>are you sure? you want to remove this user?</span>
<input type='hidden' name='removeUserId' className='clearRemove' id='removeUserId' />
<button onClick={() => removeUser()}>yes</button>
<button onClick={() => closeRemove()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default Remove;
