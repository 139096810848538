import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';

const ExposureLimit = () => {

const { userActive, getUserLists } = GlobalContext();

const closeExpoLimit = () => {
$('#ExpoLimitModal').hide();
$('.clearExpoFrm').val('');
}

const submitExpoLimit = async() => {
var id = $('#expoLimitId').val();
var newExpoLimit = $('#newExpoLimit').val();
var newExpoPass = $('#newExpoPass').val();

if(newExpoLimit <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new expo. limit required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newExpoPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/exposureLimit_update', {
id: id,
newExpoLimit: newExpoLimit,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('expo. limit updated');
setTimeout(function(){
$('#msgAlert').hide();
getUserLists(usersList_api);
$('#ExpoLimitModal').hide();
$('.clearExpoFrm').val('');
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend expo-limit-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='ExpoLimitModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>edit exposure limit -</span> <span id='userEditU'></span> <i className='fa fa-close right' onClick={() => closeExpoLimit()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>old expo. limit</label></div>
<div><span id='oldExpoLimit'></span></div>
<input className='clearExpoFrm' type='hidden' id='expoLimitId' />
</div>
<div className='formControls flexControl'>
<div><label>new expo. limit</label></div>
<div><input className='clearExpoFrm' type='number' name='newExpoLimit' id='newExpoLimit' placeholder='new exposure limit...' /></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearExpoFrm' type='password' name='newExpoPass' id='newExpoPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt2'>
<button className='submitBtn' onClick={() => submitExpoLimit()}>submit</button>
<button onClick={() => closeExpoLimit()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default ExposureLimit;
