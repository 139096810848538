import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';
import PageSideMenu2 from '../../extra/PageSideMenu2';
import MyRolling from '../../modals/MyRolling';
import ChangePass2 from '../../modals/ChangePass2';

const MyAccount = () => {

const { userActive } = GlobalContext();
const [currProfile, setCurrProfile] = useState([]);

const editRolling2 = (user) => {
$('#RollcommisionModal2').show();
$('#rollUser2').text(user);
}

const editPassword = (id, user) => {
$('#changePassModal2').show();
$('#changePassId2').val(id);
$('#PassUser2').val(user);
}

useEffect(() => {
if(userActive.length > 0){
setCurrProfile(userActive);
}
},[userActive]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu2 />
</div>
<div className='col'>
<div className='profilePage'>
<div className='headerProfile'><span>account details</span></div>
{
currProfile.length > 0 && currProfile.map((data, index) => {
return (
<div className='profileBody' key={index}>
<div>
<div className='col'><span>name</span></div>
<div className='col2'><span>{data.customer}</span></div>
</div>
<div>
<div className='col'><span>commision</span></div>
<div className='col2'><span>{data.commision}</span></div>
</div>
<div>
<div className='col'><span>rolling commision</span></div>
<div className='col2'><i className='fa fa-edit' onClick={() => editRolling2(data.customer)}></i></div>
</div>
<div>
<div className='col'><span>currency</span></div>
<div className='col2'><span className='upper'>{data.currency}</span></div>
</div>
<div>
<div className='col'><span>partnership</span></div>
<div className='col2'><span>{data.partnership}</span></div>
</div>
<div>
<div className='col'><span>mobile number</span></div>
<div className='col2'><span>{data.MobileNumber}</span></div>
</div>
<div>
<div className='col'><span>password</span></div>
<div className='col2'><span className='passSample'>******** <i className='fa fa-edit' onClick={() => editPassword(data.id, data.customer)}></i></span></div>
</div>
</div>
) 
})
}
</div>
</div>
</div>
</div>
</div>

<MyRolling />
<ChangePass2 />
</>
)
}

export default MyAccount;
