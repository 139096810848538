import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { GlobalContext } from '../../../context/AppContext';

const TennListApi = '/server2/betfair-tennis';
const betSettingApi = '/server2/betSettings_data';

const Tennis = () => {

const [tennData, setTennData] = useState([]);
const { betSet, getBetSet } = GlobalContext();

const toogleBetsSet = (id) => {
if(tennData.length > 0){
tennData.forEach((e) => {
if(e.cid === id){
$('#betsOptWrapAll_'+e.cid).toggle();
}else{
$('#betsOptWrapAll_'+e.cid).hide();
}
});
}
}

const updateBetSet = async(id,sec,sport) => {
var eventid = id;
var section = sec;
var sports = sport;
var min_odds = 0;
var max_odds = 0;
var min_bet = 0;
var max_bet = 0;
var bet_delay = 0;
var type = 'series';

if(section === 'match_odds'){
min_odds = $('#minOdds_'+id).val();
max_odds = $('#maxOdds_'+id).val(); 
min_bet = $('#minBet_'+id).val();
max_bet = $('#maxBet_'+id).val();
bet_delay = $('#delayBet_'+id).val();
}
else if(section === 'bookmaker'){
min_odds = $('#minOddsBook_'+id).val();  
max_odds = $('#maxOddsBook_'+id).val();
min_bet = $('#minBetBook_'+id).val();
max_bet = $('#maxBetBook_'+id).val();
bet_delay = $('#delayBetBook_'+id).val();
}
else if(section === 'other_odds'){
min_odds = $('#minOddsOther_'+id).val();  
max_odds = $('#maxOddsOther_'+id).val();
min_bet = $('#minBetOther_'+id).val();
max_bet = $('#maxBetOther_'+id).val();
bet_delay = $('#delayBetOther_'+id).val();
}

// console.log(eventid +' -- '+ section +' -- '+ sports +' -- '+ min_odds +' -- '+ max_odds +' -- '+ min_bet +' -- '+ max_bet +' -- '+ bet_delay +' -- '+ type);

try {
const sendData = await axios.post('/server2/update_bet_setting', {
eventid: eventid,
section: section,
sports: sports,
min_odds: min_odds,
max_odds: max_odds,
min_bet: min_bet,
max_bet: max_bet,
bet_delay: bet_delay,
type: type
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('bet setting applied');
setTimeout(function(){
$('#msgAlert').hide();
getBetSet(betSettingApi);
},1000);
return false;
}else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend update-bet-setting-api-tennis error : ' + error);
}
}

const handleDefault = async(id, sec, sport) => {
var defaultCheck = null;
if(sec === 'match_odds'){
defaultCheck = $('#default_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOdds_'+id).removeAttr('disabled');  
$('#maxOdds_'+id).removeAttr('disabled');  
$('#minBet_'+id).removeAttr('disabled');
$('#maxBet_'+id).removeAttr('disabled');
$('#delayBet_'+id).removeAttr('disabled');
$('#betsUpdBtn_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'bookmaker'){
defaultCheck = $('#defaultBook_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsBook_'+id).removeAttr('disabled');  
$('#maxOddsBook_'+id).removeAttr('disabled');  
$('#minBetBook_'+id).removeAttr('disabled');
$('#maxBetBook_'+id).removeAttr('disabled');
$('#delayBetBook_'+id).removeAttr('disabled');
$('#betsUpdBtnBook_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'other_odds'){
defaultCheck = $('#defaultOther_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsOther_'+id).removeAttr('disabled');  
$('#maxOddsOther_'+id).removeAttr('disabled');  
$('#minBetOther_'+id).removeAttr('disabled');
$('#maxBetOther_'+id).removeAttr('disabled');
$('#delayBetOther_'+id).removeAttr('disabled');
$('#betsUpdBtnOther_'+id).removeClass('disabled').removeAttr('disabled');  
}
}

if(defaultCheck === true){
try {
const sendData = await axios.post('/server2/remove_bet_setting', {id:id, sec:sec, sport:sport});
const resData = await sendData.data;
console.log(resData);
if(resData === 'success'){
getBetSet(betSettingApi);
return false;
}
else if(resData === 'default'){
getBetSet(betSettingApi);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend remove-bet-settings-api-tennis error : ' + error);  
}
}
}

useEffect(() => {
var allTenn = [];
async function fetchData(){
try {
const getData = await axios.get(`${TennListApi}`);
const resData = await getData.data;
if(resData.length > 0){
resData.forEach(element => {
var data = {
cid: element.cid,
cname: element.cname
}
const findKey = allTenn.findIndex((o) => o.cid === element.cid);
if(findKey === -1){
allTenn.push(data);
}
}); 
setTennData(allTenn);
}
} catch (error) {
console.log('backend tennis-betting-list-api error : ' + error);
}
}

fetchData();
},[]);

useEffect(() => {
if(tennData.length > 0 && betSet.length > 0){

const noDefault = tennData.filter((e) => betSet.find((f) => parseFloat(e.cid) === parseFloat(f.eventid)));
const yesDefault = tennData.filter((e) => !betSet.find((f) => parseFloat(e.cid) === parseFloat(f.eventid)));

noDefault.forEach((e) => {
var matchOddsSet = '';
var bookmakerSet = '';
var otherMatchSet = '';
matchOddsSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.cid) && ee.type === 'series' && ee.sec === 'match_odds' && ee.sport === 'tennis');
bookmakerSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.cid) && ee.type === 'series' && ee.sec === 'bookmaker' && ee.sport === 'tennis');
otherMatchSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.cid) && ee.type === 'series' && ee.sec === 'other_odds' && ee.sport === 'tennis');

//console.log(matchOddsSet);
//console.log(bookmakerSet);
if(matchOddsSet.length <= 0){
matchOddsSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_odds' && ee.sport === 'tennis');
}
if(bookmakerSet.length <= 0){
bookmakerSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'bookmaker' && ee.sport === 'tennis');
}
if(otherMatchSet.length <= 0){
otherMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'other_odds' && ee.sport === 'tennis');
}

if(matchOddsSet.length > 0){
if(matchOddsSet[0].type === 'series'){
$('#minOdds_'+e.cid).val(matchOddsSet[0].min_odds);  
$('#maxOdds_'+e.cid).val(matchOddsSet[0].max_odds);  
$('#minBet_'+e.cid).val(matchOddsSet[0].min_bet);
$('#maxBet_'+e.cid).val(matchOddsSet[0].max_bet);
$('#delayBet_'+e.cid).val(matchOddsSet[0].bet_delay);
$('#default_'+e.cid).prop('checked', false);
}
else{
$('#minOdds_'+e.cid).val(matchOddsSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOdds_'+e.cid).val(matchOddsSet[0].max_odds).attr('disabled', 'disabled');  
$('#minBet_'+e.cid).val(matchOddsSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBet_'+e.cid).val(matchOddsSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBet_'+e.cid).val(matchOddsSet[0].bet_delay).attr('disabled', 'disabled');
$('#default_'+e.cid).prop('checked', true); 
$('#betsUpdBtn_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}
}

if(bookmakerSet.length > 0){
if(bookmakerSet[0].type === 'series'){
$('#minOddsBook_'+e.cid).val(bookmakerSet[0].min_odds);  
$('#maxOddsBook_'+e.cid).val(bookmakerSet[0].max_odds);
$('#minBetBook_'+e.cid).val(bookmakerSet[0].min_bet);
$('#maxBetBook_'+e.cid).val(bookmakerSet[0].max_bet);
$('#delayBetBook_'+e.cid).val(bookmakerSet[0].bet_delay);
$('#defaultBook_'+e.cid).prop('checked', false);
} 
else{
$('#minOddsBook_'+e.cid).val(bookmakerSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsBook_'+e.cid).val(bookmakerSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetBook_'+e.cid).val(bookmakerSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetBook_'+e.cid).val(bookmakerSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetBook_'+e.cid).val(bookmakerSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultBook_'+e.cid).prop('checked', true);
$('#betsUpdBtnBook_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}
}

if(otherMatchSet.length > 0){
if(otherMatchSet[0].type === 'series'){
$('#minOddsOther_'+e.cid).val(otherMatchSet[0].min_odds);  
$('#maxOddsOther_'+e.cid).val(otherMatchSet[0].max_odds);
$('#minBetOther_'+e.cid).val(otherMatchSet[0].min_bet);
$('#maxBetOther_'+e.cid).val(otherMatchSet[0].max_bet);
$('#delayBetOther_'+e.cid).val(otherMatchSet[0].bet_delay);
$('#defaultOther_'+e.cid).prop('checked', false);
} 
else{
$('#minOddsOther_'+e.cid).val(otherMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsOther_'+e.cid).val(otherMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetOther_'+e.cid).val(otherMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetOther_'+e.cid).val(otherMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetOther_'+e.cid).val(otherMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultOther_'+e.cid).prop('checked', true);
$('#betsUpdBtnOther_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}
}

});

yesDefault.forEach((e) => {
const matchOddsSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_odds' && ee.sport === 'tennis');
const bookmakerSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'bookmaker' && ee.sport === 'tennis');
const otherMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'other_odds' && ee.sport === 'tennis');

// console.log(matchOddsSet);
// console.log(bookmakerSet);

if(matchOddsSet.length > 0){
$('#minOdds_'+e.cid).val(matchOddsSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOdds_'+e.cid).val(matchOddsSet[0].max_odds).attr('disabled', 'disabled');
$('#minBet_'+e.cid).val(matchOddsSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBet_'+e.cid).val(matchOddsSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBet_'+e.cid).val(matchOddsSet[0].bet_delay).attr('disabled', 'disabled');
$('#default_'+e.cid).prop('checked', true);
$('#betsUpdBtn_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}

if(bookmakerSet.length > 0){
$('#minOddsBook_'+e.cid).val(bookmakerSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsBook_'+e.cid).val(bookmakerSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetBook_'+e.cid).val(bookmakerSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetBook_'+e.cid).val(bookmakerSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetBook_'+e.cid).val(bookmakerSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultBook_'+e.cid).prop('checked', true);
$('#betsUpdBtnBook_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}

if(otherMatchSet.length > 0){
$('#minOddsOther_'+e.cid).val(otherMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsOther_'+e.cid).val(otherMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetOther_'+e.cid).val(otherMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetOther_'+e.cid).val(otherMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetOther_'+e.cid).val(otherMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultOther_'+e.cid).prop('checked', true);
$('#betsUpdBtnOther_'+e.cid).addClass('disabled').attr('disabled', 'disabled');
}

});

}
},[tennData, betSet]);

return (
<>
<div className='stateWrap' id='tennisBetSetting'>
<div className='stateHead'><span>tennis bet setting</span></div>

<div className='stateBody'>
<div className='betSettingContent'>
<ul>
{
tennData.length > 0 ? tennData.map((data, index) => {
return(
<li key={index}>
<span className='listTxtBets' onClick={() => toogleBetsSet(data.cid)}>{data.cname} <i className='fa fa-angle-right angleIcon'></i></span>
<div className='betsOptWrapAll' id={'betsOptWrapAll_'+data.cid}>
<div className='betsOptWrap'>
<div className='betsOptHead'><span>match odds</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOdds' id={'minOdds_'+data.cid} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOdds' id={'maxOdds_'+data.cid} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBet' id={'minBet_'+data.cid} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBet' id={'maxBet_'+data.cid} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBet' id={'delayBet_'+data.cid} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='default' id={'default_'+data.cid} onChange={() => handleDefault(data.cid,'match_odds','tennis')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtn_'+data.cid} onClick={() => updateBetSet(data.cid,'match_odds','tennis')}>update</button>
</div>
</div>

<div className='betsOptWrap' >
<div className='betsOptHead'><span>bookmaker</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsBook' id={'minOddsBook_'+data.cid} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsBook' id={'maxOddsBook_'+data.cid} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetBook' id={'minBetBook_'+data.cid} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetBook' id={'maxBetBook_'+data.cid} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetBook' id={'delayBetBook_'+data.cid} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultBook' id={'defaultBook_'+data.cid} onChange={() => handleDefault(data.cid,'bookmaker','tennis')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnBook_'+data.cid} onClick={() => updateBetSet(data.cid,'bookmaker','tennis')}>update</button>
</div>
</div>

<div className='betsOptWrap'>
<div className='betsOptHead'><span>others odds</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsOther' id={'minOddsOther_'+data.cid} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsOther' id={'maxOddsOther_'+data.cid} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetOther' id={'minBetOther_'+data.cid} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetOther' id={'maxBetOther_'+data.cid} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetOther' id={'delayBetOther_'+data.cid} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultOther' id={'defaultOther_'+data.cid} onChange={() => handleDefault(data.cid,'other_odds','tennis')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnOther_'+data.cid} onClick={() => updateBetSet(data.cid,'other_odds','tennis')}>update</button>
</div>
</div>

</div>
</li>
)
})
:
<div className='pageLoader'>
<div className='pageLoaderCon'>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
</div>
<div className='pageLoaderFoot'><span>loading...</span></div>
</div>
}
</ul>
</div>
</div>
</div>
</>
)
}

export default Tennis;
