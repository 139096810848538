import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';

const ViewReSettle = () => {
const location = useLocation();
const { allBetsSettle } = GlobalContext();
const [eventBets, setEventBets] = useState([]);
const [totalSec, setToalSec] = useState([]);
const [totalFancy, setTotalFancy] = useState([]);
//console.log(eventBets);
//console.log(totalSec);
//console.log(totalFancy);

const reSettleFancy = async(team,sec) => {
var sport = location.state !== null ? location.state.sport : null;
var event = location.state !== null ? location.state.event : null;
var eventid = location.state !== null ? location.state.eventid : 0;

try {
const sendData = await axios.post('/server2/fancy_ReSettle', {
team: team,
sport: sport,
event: event,
eventid: eventid,
sec: sec
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('settlement restored');
setTimeout(function(){
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend fancyReSettle error : ' + error);
}
}

const reOddsSettle = async(sec) => {
var sport = location.state !== null ? location.state.sport : null;
var event = location.state !== null ? location.state.event : null;
var eventid = location.state !== null ? location.state.eventid : 0;
//alert(winner +' -- '+ sec);
try {
const sendData = await axios.post('/server2/odds_ReSettle', {
sport: sport,
event: event,
eventid: eventid,
sec: sec,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('settlement restored');
setTimeout(function(){
$('#msgAlert').hide();
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend oddsReSettle error : ' + error);
}
}

useEffect(() => {
if(allBetsSettle.length > 0){
setEventBets(allBetsSettle.filter((e) => e.sport === (location.state !== null ? location.state.sport : null) && e.eventid === (location.state !== null ? location.state.eventid : 0) && e.eventName === (location.state !== null ? location.state.event : null)));
}
},[allBetsSettle, location.state]);

useEffect(() => {
if(eventBets.length > 0){
var allSec = [];
var allFancy = [];
eventBets.forEach((e) => {
var data = {
sec: e.sec
}

const findInd = allSec.findIndex((o) => o.sec === e.sec);

if(findInd === -1){
allSec.push(data);
}

if(e.sec === 'fancy' || e.sec === 'ball' || e.sec === 'over' || e.sec === 'meter' || e.sec === 'khado' || e.sec === 'oddeven' || e.sec === 'fancy1' || e.sec === 'line'){
var data2 = {
sec: e.sec,
team: e.team
}

const findInd2 = allFancy.findIndex((o) => o.sec === e.sec && o.team === e.team);
if(findInd2 === -1){
allFancy.push(data2);
}
}
});
setToalSec(allSec);
setTotalFancy(allFancy);
}
}, [eventBets]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='CopyAlert' id='CopyAlert'>
<span>text copied!</span>
</div>

<div className='headingTitle'><span>{location.state !== null ? location.state.sport : null} settlement</span></div>

<div className='profilePage'>
<div className='headerProfile'><span>{location.state !== null ? location.state.event +' ('+ location.state.eventid +')' : null}</span></div>

<div className='settleBody'>
{
totalSec.length > 0 ? totalSec.map((data, index) => {
return (
<div className='betsSettleWrap' key={index}>
<div className='betsSettleTitle'><span>{data.sec}</span></div>

<div className='betSettleBody'>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line' ?
totalFancy.length && totalFancy.map((data2, index2) => {
return (
<div className='fancyTeamWrap' key={index2}>
<div className='fancyTeamTitle'><span>{data2.team}</span></div>

<div className='table_responsiveAuto'>
<table className='settleTable'>
<thead>
<tr>
<th>customer</th>
<th>runner</th>
<th>odds</th>
<th>size</th>
<th>bettype</th>
<th>betamount</th>
<th>profit</th>
<th>loss</th>
<th>result</th>
<th>win status</th>
<th>match date</th>
<th>place date</th>
<th>settle date</th>
</tr>
</thead>
<tbody>
{
eventBets.filter((e) => e.sec === data2.sec && e.team === data2.team).map((data3, index3) => {
const selfDate = data3.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data3.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
return (
<tr key={index3}>
<td>{data3.customer}</td>
<td>{data3.team}</td>
<td>{data3.betRate}</td>
<td>{data3.betSize}</td>
<td>{data3.bettype === 'back' ? 'yes' : 'no'}</td>
<td>{parseFloat(data3.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.loss.toFixed(2)).toLocaleString()}</td>
<td>{data3.winner}</td>
<td>{data3.win_status}</td>
<td>{placeDate}</td>
<td>{data3.bet_dateTime}</td>
<td>{data3.settle_date}</td>
</tr>
)
})
}
</tbody>
</table>
</div>

<div className='settleDivWrap'>
<div className='settlecol'>
<button className='settleBtn' onClick={() => reSettleFancy(data2.team,data2.sec)}>re-settle</button>
</div>
</div>
</div>
)
})
:
<div className='otherSecWrap'>
<div className='table_responsiveAuto'>
<table className='settleTable'>
<thead>
<tr>
<th>customer</th>
<th>runner</th>
<th>odds</th>
<th>bettype</th>
<th>betamount</th>
<th>profit</th>
<th>loss</th>
<th>result</th>
<th>win status</th>
<th>match date</th>
<th>place date</th>
<th>settle date</th>
</tr>
</thead>
<tbody>
{
eventBets.filter((e) => e.sec === data.sec).map((data3, index3) => {
const selfDate = data3.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data3.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
return (
<tr key={index3}>
<td>{data3.customer}</td>
<td>{data3.team}</td>
<td>{data3.betRate}</td>
<td>{data3.bettype}</td>
<td>{parseFloat(data3.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data3.loss.toFixed(2)).toLocaleString()}</td>
<td>{data3.winner}</td>
<td>{data3.win_status}</td>
<td>{placeDate}</td>
<td>{data3.bet_dateTime}</td>
<td>{data3.settle_date}</td>
</tr>
)
})
}
</tbody>
</table>
</div>

<div className='settleDivWrap'>
<div className='settlecol'>
<button className='settleBtn' onClick={() => reOddsSettle(data.sec)}>re-settle</button>
</div>
</div>
</div>
} 
</div>
</div>
)
})
:
<div className='stateBody'>
<div className='norecordSettle'>
<img src='/images/cloud.png' alt='cloud' />
<span>no record found...</span>
</div>
</div>
}
</div>

</div>

</div>
</div>
</div>
</>
)
}

export default ViewReSettle;
