import React, { useEffect } from 'react';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';

const PageSideMenu = () => {

const Navigate = useNavigate();
const location = useLocation();

const userProfile = () => {
Navigate('/user_profile', {state:{name:location.state.name, role:location.state.role}});
}

const userStatement = () => {
Navigate('/user_statement', {state:{name:location.state.name, role:location.state.role}});
}

const userActivity = () => {
Navigate('/user_activity', {state:{name:location.state.name, role:location.state.role}});
}

const userProfitLoss2 = () => {
Navigate('/profit_loss', {state:{name:location.state.name, role:location.state.role}});
}

const userBetHistory2 = () => {
Navigate('/bet_history', {state:{name:location.state.name, role:location.state.role}});
}

useEffect(() => {
$(document).on('click', '.profileUl li', function(){
$(this).addClass('active').siblings().removeClass('active');
});

if(window.location.pathname === '/user_profile'){
$('.1').addClass('active');
}
if(window.location.pathname === '/bet_history'){
$('.2').addClass('active');
}
if(window.location.pathname === '/profit_loss'){
$('.3').addClass('active');
}
else if(window.location.pathname === '/user_statement'){
$('.4').addClass('active');
}
else if(window.location.pathname === '/user_activity'){
$('.5').addClass('active');
}

},[]);

return (
<>
{
location.state !== null && (location.state.role === 'customer_role' || location.state.role === 'demo_role') ?
<div className='sideUlPage'> 
<div><span>my account</span></div>
<ul className='profileUl'>
<li onClick={() => userProfile()} className='1'>my profile</li>
<li onClick={() => userBetHistory2()} className='2'>bet history</li>
<li onClick={() => userProfitLoss2()} className='3'>profit/loss</li>
<li onClick={() => userStatement()} className='4'>account statement</li>
<li onClick={() => userActivity()} className='5'>activity log</li>
</ul>
</div>
:
<div className='sideUlPage'> 
<div><span>my account</span></div>
<ul className='profileUl'>
<li onClick={() => userProfile()} className='1'>my profile</li>
<li onClick={() => userStatement()} className='4'>account statement</li>
<li onClick={() => userActivity()} className='5'>activity log</li>
</ul>
</div>
}

</>
)
}

export default PageSideMenu;
