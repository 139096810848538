import axios from 'axios';
import $ from 'jquery';

var startData;
var eventID;
var matchOddsRunner = [];

function clearId(){
matchOddsRunner = [];  
}

async function checkUser(eventid){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
eventID = eventid;
startData = setInterval(fetchData, 1000);
}
else{
console.log('login error');
}
}

async function fetchData(){
//console.log('ok run ready for refresh data' + eventID);
try {
const getData = await axios.get('/server2/soccer-market/'+eventID);
const resData = await getData.data;
if(resData.length !== 0 && resData.data !== undefined){
//console.log(resData);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

if(allSec.length > 0){
allSec.forEach((ele) => {
//console.log(resData);
resData.data.filter((e) => e.mname.trim() === ele.trim()).forEach((currVal) => {
//console.log(currVal);
if(currVal.gtype.trim() === 'match'){
if(currVal.status.trim() !== 'open'){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

currVal.section.forEach(element => {
if(element.gstatus.trim() === 'active' || element.gstatus.trim() === 'open'){
var currBack;
var currLay;

element.odds.forEach((ex) => {
if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back3'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#d7e8f4'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back2'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#b7d5eb'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay2'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#efd3d9'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay3'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#f6e6ea'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}); 

}
});

}
else if(currVal.gtype.trim() === 'match1'){
if(currVal.iplay === false){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

currVal.section.forEach((bo) => {
if(currVal.iplay !== false && bo.gstatus.trim() !== 'active'){
$('#blockTdSingle_'+bo.mid+bo.sid).css({display: 'flex'});
$('#blockTdSingleTxt_'+bo.mid+bo.sid).text(bo.gstatus);
$('#placeBet_'+bo.mid+bo.sid).hide();
}
else{
$('#blockTdSingle_'+bo.mid+bo.sid).hide();
}

var currBookBack;
var currBookLay;

bo.odds.forEach((od) => {

if(bo.sid === od.sid){
if(od.otype.trim() === 'back' && od.oname.trim() === 'back3' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back3' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back2' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){

currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back2' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back1' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: 'rgb(114, 187, 239)'});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back1' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay1' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#faa9ba'});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay1' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) < 1){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay2' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay2' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay3' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay3' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

}
});
});

}
});
});
}
else{
$('.oddsDiv').attr('disabled', 'disabled');
}
} 
} catch (error) {
clearInterval(startData);
setTimeout(function(){
startData = setInterval(fetchData, 1000);
},1000);
}
}

async function matchOdds_pls(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
if(matchOddsRunner.length > 0){

//console.log(response);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

allSec.forEach((ex) => {
//console.log(ex);
const findTeam = matchOddsRunner.filter((e) => e.sec.trim() === ex.trim());
//console.log(findTeam);
if(findTeam.length > 0){
//console.log(findTeam[0].rc);
//var currSec = '';
var t1Prf = 0;
var t2Prf = 0;
var t3Prf = 0;
var t4Prf = 0;
var t5Prf = 0;
var t6Prf = 0;
var t7Prf = 0;
var t8Prf = 0;
var t9Prf = 0;
var t10Prf = 0;
var t11Prf = 0;
var t12Prf = 0;
var t13Prf = 0;
var t14Prf = 0;
var t15Prf = 0;
var t16Prf = 0;
var t17Prf = 0;
var t18Prf = 0;
var t19Prf = 0;


response.filter((e) => e.sec.trim() === ex.trim()).forEach((exx) => {
//console.log(exx.sec);
var runner1 = null;
var runner2 = null;
var runner3 = null;
var runner4 = null;
var runner5 = null;
var runner6 = null;
var runner7 = null;
var runner8 = null;
var runner9 = null;
var runner10 = null;
var runner11 = null;
var runner12 = null;
var runner13 = null;
var runner14 = null;
var runner15 = null;
var runner16 = null;
var runner17 = null;
var runner18 = null;
var runner19 = null;

if(findTeam[0].rc === 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
}
else if(findTeam[0].rc === 2){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
}
else if(findTeam[0].rc > 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
runner4 = (findTeam[3] !== undefined ? findTeam[3].runner : null);
runner5 = (findTeam[4] !== undefined ? findTeam[4].runner : null);
runner6 = (findTeam[5] !== undefined ? findTeam[5].runner : null);
runner7 = (findTeam[6] !== undefined ? findTeam[6].runner : null);
runner8 = (findTeam[7] !== undefined ? findTeam[7].runner : null);
runner9 = (findTeam[8] !== undefined ? findTeam[8].runner : null);
runner10 = (findTeam[9] !== undefined ? findTeam[9].runner : null);
runner11 = (findTeam[10] !== undefined ? findTeam[10].runner : null);
runner12 = (findTeam[11] !== undefined ? findTeam[11].runner : null);
runner13 = (findTeam[12] !== undefined ? findTeam[12].runner : null);
runner14 = (findTeam[13] !== undefined ? findTeam[13].runner : null);
runner15 = (findTeam[14] !== undefined ? findTeam[14].runner : null);
runner16 = (findTeam[15] !== undefined ? findTeam[15].runner : null);
runner17 = (findTeam[16] !== undefined ? findTeam[16].runner : null);
runner18 = (findTeam[17] !== undefined ? findTeam[17].runner : null);
runner19 = (findTeam[18] !== undefined ? findTeam[18].runner : null);
}

//console.log(exx.sec +' -- '+ runner1 +' --'+ runner2 +' -- '+ runner3 +' -- '+ runner4 
//+' --'+ runner5 +' --'+ runner6 +' -- '+ runner7 +' -- '+ runner8 +' -- '+ runner9);

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'back'){
//currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
//console.log(runner4);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner4 !== null ? runner4.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner5 !== null ? runner5.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner6 !== null ? runner6.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner7 !== null ? runner7.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner8 !== null ? runner8.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner9 !== null ? runner9.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner10 !== null ? runner10.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner12 !== null ? runner12.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner13 !== null ? runner13.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner14 !== null ? runner14.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner15 !== null ? runner15.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner16 !== null ? runner16.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner17 !== null ? runner17.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner18 !== null ? runner18.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner19 !== null ? runner19.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner4 !== null ? runner4.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner5 !== null ? runner5.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner6 !== null ? runner6.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit);
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit);  
}

if(exx.team.trim() === (runner7 !== null ? runner7.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner8 !== null ? runner8.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner9 !== null ? runner9.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner10 !== null ? runner10.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner12 !== null ? runner12.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner13 !== null ? runner13.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner14 !== null ? runner14.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner15 !== null ? runner15.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner16 !== null ? runner16.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner17 !== null ? runner17.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner18 !== null ? runner18.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner19 !== null ? runner19.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

});

//console.log(currSec +' -- '+ t1Prf +' -- '+ t2Prf +' -- '+ t3Prf +' -- '+ t4Prf +' -- '+ t5Prf 
//+' -- '+ t6Prf +' -- '+ t7Prf +' -- '+ t8Prf +' -- '+ t9Prf);

if(findTeam[0] !== undefined){
if(t1Prf > 0){
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html('-'+t1Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(Math.abs(t1Prf)).css({color: '#44b10a'});
}
}

if(findTeam[1] !== undefined){
if(t2Prf > 0){
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html('-'+t2Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(Math.abs(t2Prf)).css({color: '#44b10a'});
}
}

if(findTeam[2] !== undefined){
if(t3Prf > 0){
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html('-'+t3Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(Math.abs(t3Prf)).css({color: '#44b10a'});
}
}

if(findTeam[3] !== undefined){
if(t4Prf > 0){
$('#teamPrf_'+findTeam[3].mid+findTeam[3].sid).html('-'+t4Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[3].mid+findTeam[3].sid).html(Math.abs(t4Prf)).css({color: '#44b10a'});
}
}

if(findTeam[4] !== undefined){
if(t5Prf > 0){
$('#teamPrf_'+findTeam[4].mid+findTeam[4].sid).html('-'+t5Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[4].mid+findTeam[4].sid).html(Math.abs(t5Prf)).css({color: '#44b10a'});
}
}

if(findTeam[5] !== undefined){
if(t6Prf > 0){
$('#teamPrf_'+findTeam[5].mid+findTeam[5].sid).html('-'+t6Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[5].mid+findTeam[5].sid).html(Math.abs(t6Prf)).css({color: '#44b10a'});
}
}

if(findTeam[6] !== undefined){
if(t7Prf > 0){
$('#teamPrf_'+findTeam[6].mid+findTeam[6].sid).html('-'+t7Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[6].mid+findTeam[6].sid).html(Math.abs(t7Prf)).css({color: '#44b10a'});
}
}

if(findTeam[7] !== undefined){
if(t8Prf > 0){
$('#teamPrf_'+findTeam[7].mid+findTeam[7].sid).html('-'+t8Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[7].mid+findTeam[7].sid).html(Math.abs(t8Prf)).css({color: '#44b10a'});
}
}

if(findTeam[8] !== undefined){
if(t9Prf > 0){
$('#teamPrf_'+findTeam[8].mid+findTeam[8].sid).html('-'+t9Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[8].mid+findTeam[8].sid).html(Math.abs(t9Prf)).css({color: '#44b10a'});
}
}

if(findTeam[9] !== undefined){
if(t10Prf > 0){
$('#teamPrf_'+findTeam[9].mid+findTeam[9].sid).html('-'+t10Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[9].mid+findTeam[9].sid).html(Math.abs(t10Prf)).css({color: '#44b10a'});
}
}

if(findTeam[10] !== undefined){
if(t11Prf > 0){
$('#teamPrf_'+findTeam[10].mid+findTeam[10].sid).html('-'+t11Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[10].mid+findTeam[10].sid).html(Math.abs(t11Prf)).css({color: '#44b10a'});
}
}

if(findTeam[11] !== undefined){
if(t12Prf > 0){
$('#teamPrf_'+findTeam[11].mid+findTeam[11].sid).html('-'+t12Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[11].mid+findTeam[11].sid).html(Math.abs(t12Prf)).css({color: '#44b10a'});
}
}

if(findTeam[12] !== undefined){
if(t13Prf > 0){
$('#teamPrf_'+findTeam[12].mid+findTeam[12].sid).html('-'+t13Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[12].mid+findTeam[12].sid).html(Math.abs(t13Prf)).css({color: '#44b10a'});
}
}

if(findTeam[13] !== undefined){
if(t14Prf > 0){
$('#teamPrf_'+findTeam[13].mid+findTeam[13].sid).html('-'+t14Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[13].mid+findTeam[13].sid).html(Math.abs(t14Prf)).css({color: '#44b10a'});
}
}

if(findTeam[14] !== undefined){
if(t15Prf > 0){
$('#teamPrf_'+findTeam[14].mid+findTeam[14].sid).html('-'+t15Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[14].mid+findTeam[14].sid).html(Math.abs(t15Prf)).css({color: '#44b10a'});
}
}

if(findTeam[15] !== undefined){
if(t16Prf > 0){
$('#teamPrf_'+findTeam[15].mid+findTeam[15].sid).html('-'+t16Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[15].mid+findTeam[15].sid).html(Math.abs(t16Prf)).css({color: '#44b10a'});
}
}

if(findTeam[16] !== undefined){
if(t17Prf > 0){
$('#teamPrf_'+findTeam[16].mid+findTeam[16].sid).html('-'+t17Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[16].mid+findTeam[16].sid).html(Math.abs(t17Prf)).css({color: '#44b10a'});
}
}

if(findTeam[17] !== undefined){
if(t18Prf > 0){
$('#teamPrf_'+findTeam[17].mid+findTeam[17].sid).html('-'+t18Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[17].mid+findTeam[17].sid).html(Math.abs(t18Prf)).css({color: '#44b10a'});
}
}

if(findTeam[18] !== undefined){
if(t19Prf > 0){
$('#teamPrf_'+findTeam[18].mid+findTeam[18].sid).html('-'+t19Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[18].mid+findTeam[18].sid).html(Math.abs(t19Prf)).css({color: '#44b10a'});
}
}

}
});
}
}
} catch (error) {
console.log('backend soccer matchOdds pls bets api : ' + error);
}
}

function numFormat(number){
if (number < 1000) {
return number;
} else if (number >= 1000 && number < 1_000_000) {
return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
} else if (number >= 1_000_000 && number < 1_000_000_000) {
return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
}
}

export {
checkUser,
startData,
clearId,
matchOddsRunner,
matchOdds_pls
};
