import axios from 'axios';
import $ from 'jquery';

var startResult_t20;

function runresult_t20(){
fetchResult_t20();
startResult_t20 = setInterval(fetchResult_t20, 2000);
}

async function fetchResult_t20() {
try {
const getData = await axios.get('/server2/teenpattiT20Result');
const resData = await getData.data;
//console.log('result');
if(resData.data === undefined){
clearInterval(startResult_t20);
setTimeout(function(){
startResult_t20 = setInterval(fetchResult_t20, 1000);
},1000);
}
else{
if(document.getElementById('allResCas_t20') !== null){
document.getElementById('allResCas_t20').innerHTML = '';
resData.data.forEach(element => {
document.getElementById('allResCas_t20').innerHTML += `<span id='casResFull_${element.mid}' class=${element.result === '1' ? 'dotOne_t20' : 'dotTwo_t20'}>${element.result === '1' ? 'A' : 'B'}</span>`;
});   
}
}
} catch (error) {
clearInterval(startResult_t20);
setTimeout(function(){
startResult_t20 = setInterval(fetchResult_t20, 1000);
},1000);
console.log('backend casino_t20_result error : ' + error);
}
}

$(document).on('click', '.dotOne_t20', function(e){
casinoResMod_t20(e.target.id);
$('#casResModal').show();
$('.casDetResLoad').css({display: 'flex'});
$('.resCasCon').hide();
});

$(document).on('click', '.dotTwo_t20', function(e){
casinoResMod_t20(e.target.id);
$('#casResModal').show();
$('.casDetResLoad').css({display: 'flex'});
$('.resCasCon').hide();
});

async function casinoResMod_t20(mid) {
$('#record_no_teen').hide();
var midSplit = mid.split('_')[1];
$('#img1').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img2').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img3').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img4').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img5').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img6').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
try {
const getData = await axios.get('/server2/casinoDetailsResult/'+midSplit);
const resData = await getData.data;
if(resData.data !== undefined){
//console.log(resData.data);
const allcard = resData.data[0].cards.toUpperCase().split(',');
//console.log(allcard);
$('#img1').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[0]+'.jpg');
$('#img2').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[2]+'.jpg');
$('#img3').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[4]+'.jpg');
$('#img4').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[1]+'.jpg');
$('#img5').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[3]+'.jpg');
$('#img6').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[5]+'.jpg');
$('#winCas').text(resData.data[0].win === '1' ? 'player a' : 'player b');
$('#winCasMid').text(resData.data[0].mid).show();
$('.casDetResLoad').css({display: 'none'});
$('.resCasCon').show();
}
else{
$('#winCasMid').hide();
$('.casDetResLoad').hide();
$('.resCasCon').hide();
$('#record_no_teen').show();
}
} catch (error) {
console.log('backend casino_t20_details_result error : ' + error);
}
}

export { startResult_t20, runresult_t20, casinoResMod_t20 };