import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const CommHis = () => {
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [currHisComm, setCurrHisComm] = useState([]);
const [searchData, setSearchData] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currHisComm.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currHisComm.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const searchUsers = (name) => {
setCurrHisComm(searchData.filter((e) => e.customer.includes(name)));
}

useEffect(() => {
async function fetchData(){
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
try {
const sendData = await axios.post('/server2/commision-history-all', {
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
if(resData.length > 0){
setCurrHisComm(resData);
setSearchData(resData);
}else{
setCurrHisComm([]);
setSearchData([]);
}
} catch (error) {
console.log('backend commision-history-api error : ' + error);
}
}

fetchData();
}, [startDate, endDate]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='dateDivWrap'>
<div className='dateForm'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>commision history</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currHisComm.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>turn over</th>
<th>commision</th>
<th>commision (%)</th>
<th>section</th>
<th>status</th>
<th>date time</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.customer}</td>
<td>{data.turn_over > 0 ? <span className='successTxt'>(+{parseFloat(data.turn_over.toFixed(2)).toLocaleString()})</span> : <span className='dangerTxt'>({parseFloat(data.turn_over.toFixed(2)).toLocaleString()})</span>}</td>
<td>{data.commision > 0 ? <span className='successTxt'>(+{parseFloat(data.commision.toFixed(2)).toLocaleString()})</span> : <span className='dangerTxt'>({parseFloat(data.commision.toFixed(2)).toLocaleString()})</span>}</td>
<td>{data.comm_percent}</td>
<td>{data.comm_sec}</td>
<td>{data.status}</td>
<td>{data.create_date}</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default CommHis;
