import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { GlobalContext } from '../../../context/AppContext';

const CompetitionCricListApi = '/server2/competition-list';
const betSettingApi = '/server2/betSettings_data';

const Cricket = () => {

const [cricComp, setCricComp] = useState([]);
const { betSet, getBetSet } = GlobalContext();

const toogleBetsSet = (id) => {
if(cricComp.length > 0){
cricComp.forEach((e) => {
if(e.competition.id === id){
$('#betsOptWrapAll_'+e.competition.id).toggle();
}else{
$('#betsOptWrapAll_'+e.competition.id).hide();
}
});
}
}

const updateBetSet = async(id,sec,sport) => {
var eventid = id;
var section = sec;
var sports = sport;
var min_odds = 0;
var max_odds = 0;
var min_bet = 0;
var max_bet = 0;
var bet_delay = 0;
var type = 'series';

if(section === 'match_odds'){
min_odds = $('#minOdds_'+id).val();
max_odds = $('#maxOdds_'+id).val(); 
min_bet = $('#minBet_'+id).val();
max_bet = $('#maxBet_'+id).val();
bet_delay = $('#delayBet_'+id).val();
}
else if(section === 'bookmaker'){
min_odds = $('#minOddsBook_'+id).val();  
max_odds = $('#maxOddsBook_'+id).val();
min_bet = $('#minBetBook_'+id).val();
max_bet = $('#maxBetBook_'+id).val();
bet_delay = $('#delayBetBook_'+id).val();
}
else if(section === 'tied_match'){
min_odds = $('#minOddsTied_'+id).val();  
max_odds = $('#maxOddsTied_'+id).val();
min_bet = $('#minBetTied_'+id).val();
max_bet = $('#maxBetTied_'+id).val();
bet_delay = $('#delayBetTied_'+id).val();
}
else if(section === 'match_toss'){
min_odds = $('#minOddsToss_'+id).val();  
max_odds = $('#maxOddsToss_'+id).val();
min_bet = $('#minBetToss_'+id).val();
max_bet = $('#maxBetToss_'+id).val();
bet_delay = $('#delayBetToss_'+id).val();
}
else if(section === 'fancy'){
min_odds = $('#minOddsFancy_'+id).val();  
max_odds = $('#maxOddsFancy_'+id).val();
min_bet = $('#minBetFancy_'+id).val();
max_bet = $('#maxBetFancy_'+id).val();
bet_delay = $('#delayBetFancy_'+id).val();
}

// console.log(eventid +' -- '+ section +' -- '+ sports +' -- '+ min_odds +' -- '+ max_odds +' -- '+ min_bet +' -- '+ max_bet +' -- '+ bet_delay +' -- '+ type);

try {
const sendData = await axios.post('/server2/update_bet_setting', {
eventid: eventid,
section: section,
sports: sports,
min_odds: min_odds,
max_odds: max_odds,
min_bet: min_bet,
max_bet: max_bet,
bet_delay: bet_delay,
type: type
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('bet setting applied');
setTimeout(function(){
$('#msgAlert').hide();
getBetSet(betSettingApi);
},1000);
return false;
}else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend update-bet-setting-api error : ' + error);
}
}

const handleDefault = async(id, sec, sport) => {
var defaultCheck = null;
if(sec === 'match_odds'){
defaultCheck = $('#default_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOdds_'+id).removeAttr('disabled');  
$('#maxOdds_'+id).removeAttr('disabled');  
$('#minBet_'+id).removeAttr('disabled');
$('#maxBet_'+id).removeAttr('disabled');
$('#delayBet_'+id).removeAttr('disabled');
$('#betsUpdBtn_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'bookmaker'){
defaultCheck = $('#defaultBook_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsBook_'+id).removeAttr('disabled');  
$('#maxOddsBook_'+id).removeAttr('disabled');  
$('#minBetBook_'+id).removeAttr('disabled');
$('#maxBetBook_'+id).removeAttr('disabled');
$('#delayBetBook_'+id).removeAttr('disabled');
$('#betsUpdBtnBook_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'tied_match'){
defaultCheck = $('#defaultTied_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsTied_'+id).removeAttr('disabled');  
$('#maxOddsTied_'+id).removeAttr('disabled');  
$('#minBetTied_'+id).removeAttr('disabled');
$('#maxBetTied_'+id).removeAttr('disabled');
$('#delayBetTied_'+id).removeAttr('disabled');
$('#betsUpdBtnTied_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'match_toss'){
defaultCheck = $('#defaultToss_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsToss_'+id).removeAttr('disabled');  
$('#maxOddsToss_'+id).removeAttr('disabled');  
$('#minBetToss_'+id).removeAttr('disabled');
$('#maxBetToss_'+id).removeAttr('disabled');
$('#delayBetToss_'+id).removeAttr('disabled');
$('#betsUpdBtnToss_'+id).removeClass('disabled').removeAttr('disabled');  
}
}
else if(sec === 'fancy'){
defaultCheck = $('#defaultFancy_'+id).prop('checked') ? true : false;
if(defaultCheck === false){
$('#minOddsFancy_'+id).removeAttr('disabled');  
$('#maxOddsFancy_'+id).removeAttr('disabled');  
$('#minBetFancy_'+id).removeAttr('disabled');
$('#maxBetFancy_'+id).removeAttr('disabled');
$('#delayBetFancy_'+id).removeAttr('disabled');
$('#betsUpdBtnFancy_'+id).removeClass('disabled').removeAttr('disabled');  
}
}

if(defaultCheck === true){
try {
const sendData = await axios.post('/server2/remove_bet_setting', {id:id, sec:sec, sport:sport});
const resData = await sendData.data;
console.log(resData);
if(resData === 'success'){
getBetSet(betSettingApi);
return false;
}
else if(resData === 'default'){
getBetSet(betSettingApi);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend remove-bet-settings-api error : ' + error);  
}
}
}

useEffect(() => {
async function fetchCricket(){
try {
const getData = await axios.get(`${CompetitionCricListApi}/4`);
const resData = await getData.data;
if(resData.length > 0){
setCricComp(resData);
}
} catch (error) {
console.log('backend cricket-bet-setting-api error : ' + error);
}
}

fetchCricket();
},[]);

useEffect(() => {
if(cricComp.length > 0 && betSet.length > 0){
const noDefault = cricComp.filter((e) => betSet.find((f) => parseFloat(e.competition.id) === parseFloat(f.eventid)));
const yesDefault = cricComp.filter((e) => !betSet.find((f) => parseFloat(e.competition.id) === parseFloat(f.eventid)));

noDefault.forEach((e) => {
var matchOddsSet = '';
var bookmakerSet = '';
var tiedMatchSet = '';
var tossMatchSet = '';
var fancySet = '';
matchOddsSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.competition.id) && ee.type === 'series' && ee.sec === 'match_odds' && ee.sport === 'cricket');
bookmakerSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.competition.id) && ee.type === 'series' && ee.sec === 'bookmaker' && ee.sport === 'cricket');
tiedMatchSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.competition.id) && ee.type === 'series' && ee.sec === 'tied_match' && ee.sport === 'cricket');
tossMatchSet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.competition.id) && ee.type === 'series' && ee.sec === 'match_toss' && ee.sport === 'cricket');
fancySet = betSet.filter((ee) => parseFloat(ee.eventid) === parseFloat(e.competition.id) && ee.type === 'series' && ee.sec === 'fancy' && ee.sport === 'cricket');
//console.log(matchOddsSet);
//console.log(bookmakerSet);
if(matchOddsSet.length <= 0){
matchOddsSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_odds' && ee.sport === 'cricket');
}
if(bookmakerSet.length <= 0){
bookmakerSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'bookmaker' && ee.sport === 'cricket');
}
if(tiedMatchSet.length <= 0){
tiedMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'tied_match' && ee.sport === 'cricket');
}
if(tossMatchSet.length <= 0){
tossMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_toss' && ee.sport === 'cricket');
}
if(fancySet.length <= 0){
fancySet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'fancy' && ee.sport === 'cricket');
}

if(matchOddsSet.length > 0){
if(matchOddsSet[0].type === 'series'){
$('#minOdds_'+e.competition.id).val(matchOddsSet[0].min_odds);  
$('#maxOdds_'+e.competition.id).val(matchOddsSet[0].max_odds);  
$('#minBet_'+e.competition.id).val(matchOddsSet[0].min_bet);
$('#maxBet_'+e.competition.id).val(matchOddsSet[0].max_bet);
$('#delayBet_'+e.competition.id).val(matchOddsSet[0].bet_delay);
$('#default_'+e.competition.id).prop('checked', false);
}
else{
$('#minOdds_'+e.competition.id).val(matchOddsSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOdds_'+e.competition.id).val(matchOddsSet[0].max_odds).attr('disabled', 'disabled');  
$('#minBet_'+e.competition.id).val(matchOddsSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBet_'+e.competition.id).val(matchOddsSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBet_'+e.competition.id).val(matchOddsSet[0].bet_delay).attr('disabled', 'disabled');
$('#default_'+e.competition.id).prop('checked', true); 
$('#betsUpdBtn_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}
}

if(bookmakerSet.length > 0){
if(bookmakerSet[0].type === 'series'){
$('#minOddsBook_'+e.competition.id).val(bookmakerSet[0].min_odds);  
$('#maxOddsBook_'+e.competition.id).val(bookmakerSet[0].max_odds);
$('#minBetBook_'+e.competition.id).val(bookmakerSet[0].min_bet);
$('#maxBetBook_'+e.competition.id).val(bookmakerSet[0].max_bet);
$('#delayBetBook_'+e.competition.id).val(bookmakerSet[0].bet_delay);
$('#defaultBook_'+e.competition.id).prop('checked', false);
} 
else{
$('#minOddsBook_'+e.competition.id).val(bookmakerSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsBook_'+e.competition.id).val(bookmakerSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetBook_'+e.competition.id).val(bookmakerSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetBook_'+e.competition.id).val(bookmakerSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetBook_'+e.competition.id).val(bookmakerSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultBook_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnBook_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}
}

if(tiedMatchSet.length > 0){
if(tiedMatchSet[0].type === 'series'){
$('#minOddsTied_'+e.competition.id).val(tiedMatchSet[0].min_odds);  
$('#maxOddsTied_'+e.competition.id).val(tiedMatchSet[0].max_odds);
$('#minBetTied_'+e.competition.id).val(tiedMatchSet[0].min_bet);
$('#maxBetTied_'+e.competition.id).val(tiedMatchSet[0].max_bet);
$('#delayBetTied_'+e.competition.id).val(tiedMatchSet[0].bet_delay);
$('#defaultTied_'+e.competition.id).prop('checked', false);
} 
else{
$('#minOddsTied_'+e.competition.id).val(tiedMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsTied_'+e.competition.id).val(tiedMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetTied_'+e.competition.id).val(tiedMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetTied_'+e.competition.id).val(tiedMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetTied_'+e.competition.id).val(tiedMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultTied_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnTied_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}
}

if(tossMatchSet.length > 0){
if(tossMatchSet[0].type === 'series'){
$('#minOddsToss_'+e.competition.id).val(tossMatchSet[0].min_odds);  
$('#maxOddsToss_'+e.competition.id).val(tossMatchSet[0].max_odds);
$('#minBetToss_'+e.competition.id).val(tossMatchSet[0].min_bet);
$('#maxBetToss_'+e.competition.id).val(tossMatchSet[0].max_bet);
$('#delayBetToss_'+e.competition.id).val(tossMatchSet[0].bet_delay);
$('#defaultToss_'+e.competition.id).prop('checked', false);
} 
else{
$('#minOddsToss_'+e.competition.id).val(tossMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsToss_'+e.competition.id).val(tossMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetToss_'+e.competition.id).val(tossMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetToss_'+e.competition.id).val(tossMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetToss_'+e.competition.id).val(tossMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultToss_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnToss_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}
}

if(fancySet.length > 0){
if(fancySet[0].type === 'series'){
$('#minOddsFancy_'+e.competition.id).val(fancySet[0].min_odds);  
$('#maxOddsFancy_'+e.competition.id).val(fancySet[0].max_odds);
$('#minBetFancy_'+e.competition.id).val(fancySet[0].min_bet);
$('#maxBetFancy_'+e.competition.id).val(fancySet[0].max_bet);
$('#delayBetFancy_'+e.competition.id).val(fancySet[0].bet_delay);
$('#defaultFancy_'+e.competition.id).prop('checked', false);
} 
else{
$('#minOddsFancy_'+e.competition.id).val(fancySet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsFancy_'+e.competition.id).val(fancySet[0].max_odds).attr('disabled', 'disabled');
$('#minBetFancy_'+e.competition.id).val(fancySet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetFancy_'+e.competition.id).val(fancySet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetFancy_'+e.competition.id).val(fancySet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultFancy_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnFancy_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}
}

});

yesDefault.forEach((e) => {
const matchOddsSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_odds' && ee.sport === 'cricket');
const bookmakerSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'bookmaker' && ee.sport === 'cricket');
const tiedMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'tied_match' && ee.sport === 'cricket');
const tossMatchSet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'match_toss' && ee.sport === 'cricket');
const fancySet = betSet.filter((ee) => ee.type === 'default' && ee.sec === 'fancy' && ee.sport === 'cricket');
// console.log(matchOddsSet);
// console.log(bookmakerSet);

if(matchOddsSet.length > 0){
$('#minOdds_'+e.competition.id).val(matchOddsSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOdds_'+e.competition.id).val(matchOddsSet[0].max_odds).attr('disabled', 'disabled');
$('#minBet_'+e.competition.id).val(matchOddsSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBet_'+e.competition.id).val(matchOddsSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBet_'+e.competition.id).val(matchOddsSet[0].bet_delay).attr('disabled', 'disabled');
$('#default_'+e.competition.id).prop('checked', true);
$('#betsUpdBtn_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}

if(bookmakerSet.length > 0){
$('#minOddsBook_'+e.competition.id).val(bookmakerSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsBook_'+e.competition.id).val(bookmakerSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetBook_'+e.competition.id).val(bookmakerSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetBook_'+e.competition.id).val(bookmakerSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetBook_'+e.competition.id).val(bookmakerSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultBook_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnBook_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}

if(tiedMatchSet.length > 0){
$('#minOddsTied_'+e.competition.id).val(tiedMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsTied_'+e.competition.id).val(tiedMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetTied_'+e.competition.id).val(tiedMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetTied_'+e.competition.id).val(tiedMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetTied_'+e.competition.id).val(tiedMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultTied_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnTied_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}

if(tossMatchSet.length > 0){
$('#minOddsToss_'+e.competition.id).val(tossMatchSet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsToss_'+e.competition.id).val(tossMatchSet[0].max_odds).attr('disabled', 'disabled');
$('#minBetToss_'+e.competition.id).val(tossMatchSet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetToss_'+e.competition.id).val(tossMatchSet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetToss_'+e.competition.id).val(tossMatchSet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultToss_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnToss_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}

if(fancySet.length > 0){
$('#minOddsFancy_'+e.competition.id).val(fancySet[0].min_odds).attr('disabled', 'disabled');  
$('#maxOddsFancy_'+e.competition.id).val(fancySet[0].max_odds).attr('disabled', 'disabled');
$('#minBetFancy_'+e.competition.id).val(fancySet[0].min_bet).attr('disabled', 'disabled');
$('#maxBetFancy_'+e.competition.id).val(fancySet[0].max_bet).attr('disabled', 'disabled');
$('#delayBetFancy_'+e.competition.id).val(fancySet[0].bet_delay).attr('disabled', 'disabled');
$('#defaultFancy_'+e.competition.id).prop('checked', true);
$('#betsUpdBtnFancy_'+e.competition.id).addClass('disabled').attr('disabled', 'disabled');
}

});

}
},[cricComp, betSet]);

return (
<>
<div className='stateWrap' id='cricketBetSetting'>
<div className='stateHead'><span>cricket bet setting</span></div>

<div className='stateBody'>
<div className='betSettingContent'>
<ul>  
{
cricComp.length > 0 ? cricComp.map((data, index) => {
return(
<li key={index}>
<span className='listTxtBets' onClick={() => toogleBetsSet(data.competition.id)}>{data.competition.name} <i className='fa fa-angle-right angleIcon'></i></span>
<div className='betsOptWrapAll' id={'betsOptWrapAll_'+data.competition.id}>
<div className='betsOptWrap'>
<div className='betsOptHead'><span>match odds</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOdds' id={'minOdds_'+data.competition.id} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOdds' id={'maxOdds_'+data.competition.id} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBet' id={'minBet_'+data.competition.id} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBet' id={'maxBet_'+data.competition.id} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBet' id={'delayBet_'+data.competition.id} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='default' id={'default_'+data.competition.id} onChange={() => handleDefault(data.competition.id,'match_odds','cricket')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtn_'+data.competition.id} onClick={() => updateBetSet(data.competition.id,'match_odds','cricket')}>update</button>
</div>
</div>

<div className='betsOptWrap' >
<div className='betsOptHead'><span>bookmaker</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsBook' id={'minOddsBook_'+data.competition.id} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsBook' id={'maxOddsBook_'+data.competition.id} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetBook' id={'minBetBook_'+data.competition.id} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetBook' id={'maxBetBook_'+data.competition.id} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetBook' id={'delayBetBook_'+data.competition.id} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultBook' id={'defaultBook_'+data.competition.id} onChange={() => handleDefault(data.competition.id,'bookmaker','cricket')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnBook_'+data.competition.id} onClick={() => updateBetSet(data.competition.id,'bookmaker','cricket')}>update</button>
</div>
</div>

<div className='betsOptWrap'>
<div className='betsOptHead'><span>tied match</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsTied' id={'minOddsTied_'+data.competition.id} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsTied' id={'maxOddsTied_'+data.competition.id} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetTied' id={'minBetTied_'+data.competition.id} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetTied' id={'maxBetTied_'+data.competition.id} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetTied' id={'delayBetTied_'+data.competition.id} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultTied' id={'defaultTied_'+data.competition.id} onChange={() => handleDefault(data.competition.id,'tied_match','cricket')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnTied_'+data.competition.id} onClick={() => updateBetSet(data.competition.id,'tied_match','cricket')}>update</button>
</div>
</div>

<div className='betsOptWrap'>
<div className='betsOptHead'><span>match toss</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsToss' id={'minOddsToss_'+data.competition.id} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsToss' id={'maxOddsToss_'+data.competition.id} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetToss' id={'minBetToss_'+data.competition.id} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetToss' id={'maxBetToss_'+data.competition.id} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetToss' id={'delayBetToss_'+data.competition.id} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultToss' id={'defaultToss_'+data.competition.id} onChange={() => handleDefault(data.competition.id,'match_toss','cricket')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnToss_'+data.competition.id} onClick={() => updateBetSet(data.competition.id,'match_toss','cricket')}>update</button>
</div>
</div>

<div className='betsOptWrap'>
<div className='betsOptHead'><span>Fancy (session bet)</span></div>
<div className='betsOptCont'>
<div className='betsOpt'>
<label>min odds*</label>
<input type='number' name='minOddsFancy' id={'minOddsFancy_'+data.competition.id} placeholder='min odds...' />
</div>
<div className='betsOpt'>
<label>max odds*</label>
<input type='number' name='maxOddsFancy' id={'maxOddsFancy_'+data.competition.id} placeholder='max odds...' />
</div>
<div className='betsOpt'>
<label>min bet*</label>
<input type='number' name='minBetFancy' id={'minBetFancy_'+data.competition.id} placeholder='min bet...' />
</div>
<div className='betsOpt'>
<label>max bet*</label>
<input type='number' name='maxBetFancy' id={'maxBetFancy_'+data.competition.id} placeholder='max bet...' />
</div>
<div className='betsOpt'>
<label>bet delay*</label>
<input type='number' name='delayBetFancy' id={'delayBetFancy_'+data.competition.id} placeholder='delay bet...' />
</div>
</div>
<div className='betsOptBtn'>
<label className='defaultRight'>
default <input type='checkbox' name='defaultFancy' id={'defaultFancy_'+data.competition.id} onChange={() => handleDefault(data.competition.id,'fancy','cricket')} />
</label>
<button className='betsUpdBtn' id={'betsUpdBtnFancy_'+data.competition.id} onClick={() => updateBetSet(data.competition.id,'fancy','cricket')}>update</button>
</div>
</div>
</div>
</li>
)
})
:
<div className='pageLoader'>
<div className='pageLoaderCon'>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
</div>
<div className='pageLoaderFoot'><span>loading...</span></div>
</div> 
}
</ul>
</div>
</div>
</div>
</>
)
}

export default Cricket;
