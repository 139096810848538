import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const EventPls = () => {

const Navigate = useNavigate();
const location = useLocation();
const [startDate, setStartDate] = useState(new Date(location.state !== null && location.state.startDate));
const [endDate, setEndDate] = useState(new Date(location.state !== null && location.state.endDate));
const [currRepo, setCurrRepo] = useState([]);
const [currEvent, setCurrEvent] = useState([]);
const [totalMain, setTotalMain] = useState(0);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currEvent.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currEvent.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const marketPls = (sport,eventname,team,sec,eventid) => {
Navigate('/sport_report/sport/event/market', {state:{sport: sport, eventname: eventname, team: team, sec: sec, eventid: eventid, startDate: startDate, endDate: endDate}});
}

useEffect(() => {
const fetchReports = async() => {
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();

try {
const sendData = await axios.post('/server2/settle_bets', {
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
if(resData.length > 0){
setCurrRepo(resData); 
}
else{
setCurrRepo([]); 
}
} catch (error) {
console.log('backend event-reports-api error : ' + error);
}

}

fetchReports();
},[startDate, endDate]);

useEffect(() => {
if(currRepo.length > 0){
setCurrEvent(currRepo.filter((e) => e.settlement === 'settle' && e.sport === location.state.sport && e.eventName === location.state.eventname).reduce((a, b) => {
const { sport, eventName, team, winner, settle_date, sec, win_status, profit, loss, eventid } = b;
var item = '';
if(sec === 'fancy' || sec === 'ball' || sec === 'over' || sec === 'meter' || sec === 'khado' || sec === 'oddeven' || sec === 'fancy1' || sec === 'line'){
item = a.find((h) => h.team === team && h.eventid === eventid);
}
else{
item = a.find((h) => h.sec === sec && h.eventid === eventid);   
}

let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit;
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({sport,eventName,team,winner,settle_date,sec,finalLos,eventid});
return a;
},[]));
}
else{
setCurrEvent([]);
}
},[currRepo, location.state]);

useEffect(() => {
if(records.length > 0){
const totalAmt = records.reduce((a, b) => {
a = a + b.finalLos;
return a;
},0);

setTotalMain(totalAmt);
}
else{
setTotalMain(0);
}
},[records]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='dateDivWrap'>
<div className='dateForm'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>Market profit / loss</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currEvent.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable2'>
<thead>
<tr>
<th>sport name</th>
<th>event name</th>
<th>market name</th>
<th>result</th>
<th>upline profit/loss</th>
<th>downline profit/loss</th>
<th>commision</th>
<th>settle time</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>
<span className='links' onClick={() => marketPls(data.sport,data.eventName,data.team,data.sec,data.eventid)}>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line' ? data.team : data.sec
}
</span>
</td>
<td>{data.winner}</td>
<td>{data.finalLos < 0 ? <span className='successTxt'>{parseFloat(Math.abs(data.finalLos).toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{'-'+parseFloat(data.finalLos.toFixed(2)).toLocaleString()}</span>}</td>
<td>{data.finalLos > 0 ? <span className='successTxt'>{parseFloat(data.finalLos.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.finalLos.toFixed(2)).toLocaleString()}</span>}</td>
<td>0</td>
<td>{data.settle_date}</td>
</tr>
)
})
:
<tr>
<td colSpan='8' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
<tr>
<td><b>Total</b></td>
<td>--</td>
<td>--</td>
<td>--</td>
<td>{totalMain < 0 ? <span className='successTxt'>{parseFloat(Math.abs(totalMain).toFixed(2)).toLocaleString()}</span> : totalMain > 0 ? <span className='dangerTxt'>{'-'+parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>{totalMain > 0 ? <span className='successTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : totalMain < 0 ? <span className='dangerTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>0</td>
<td>--</td>
</tr>
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default EventPls;
