import axios from 'axios';
import $ from 'jquery';

var startData;
var eventID;
var matchOddsRunner = [];

function clearId(){
matchOddsRunner = [];  
}

async function checkUser(eventid){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
eventID = eventid;
startData = setInterval(fetchData, 1000);
}
else{
console.log('login error');
}
}

async function fetchData(){
//console.log('ok run ready for refresh data' + eventID);
try {
const getData = await axios.get('/server2/tennis-market/'+eventID);
const resData = await getData.data;
if(resData.length !== 0 && resData.data !== undefined){
//console.log(resData);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

if(allSec.length > 0){
allSec.forEach((ele) => {
//console.log(resData);
resData.data.filter((e) => e.mname.trim() === ele.trim()).forEach((currVal) => {
//console.log(currVal);
if(currVal.gtype.trim() === 'match'){
if(currVal.status.trim() !== 'open'){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

currVal.section.forEach(element => {
if(element.gstatus.trim() === 'active' || element.gstatus.trim() === 'open'){
var currBack;
var currLay;

element.odds.forEach((ex) => {
if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back3'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#d7e8f4'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back2'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#b7d5eb'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'back' && ex.oname.trim() === 'back1'){
currBack = $('#price_back_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currBack) !== parseFloat(ex.odds)){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+element.mid+ex.sid+ex.tno).css({background: '#72bbef'});
},200);
}

$('#price_back_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_size_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay1'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#faa9ba'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay2'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#efd3d9'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}

if(ex.otype.trim() === 'lay' && ex.oname.trim() === 'lay3'){
currLay = $('#price_lay_'+element.mid+ex.sid+ex.tno).text();

if(parseFloat(currLay) !== parseFloat(ex.odds)){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#80ffbf'});
setTimeout(function(){
$('#oddsDivLay_'+element.mid+ex.sid+ex.tno).css({background: '#f6e6ea'});
},200);
}

$('#price_lay_'+element.mid+ex.sid+ex.tno).text(ex.odds);
$('#price_laysize_'+element.mid+ex.sid+ex.tno).text(numFormat(ex.size));
}
}); 

}
});

}
else if(currVal.gtype.trim() === 'match1'){
if(currVal.iplay === false){
$('#blockTbody_'+currVal.mid).css({display: 'flex'});
$('#blockBodyTxt_'+currVal.mid).text(currVal.status);
}
else{
$('#blockTbody_'+currVal.mid).hide();
}

currVal.section.forEach((bo) => {
if(currVal.iplay !== false && bo.gstatus.trim() !== 'active'){
$('#blockTdSingle_'+bo.mid+bo.sid).css({display: 'flex'});
$('#blockTdSingleTxt_'+bo.mid+bo.sid).text(bo.gstatus);
$('#placeBet_'+bo.mid+bo.sid).hide();
}
else{
$('#blockTdSingle_'+bo.mid+bo.sid).hide();
}

var currBookBack;
var currBookLay;

bo.odds.forEach((od) => {

if(bo.sid === od.sid){
if(od.otype.trim() === 'back' && od.oname.trim() === 'back3' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back3' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back2' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){

currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back2' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'back' && od.oname.trim() === 'back1' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookBack = $('#price_back_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookBack) !== parseFloat(od.odds)){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).css({background: 'rgb(114, 187, 239)'});
},200);
}

$('#oddsDiv_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_size_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'back' && od.oname.trim() === 'back1' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDiv_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_back_'+bo.mid+od.sid+od.tno).text('');
$('#price_size_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay1' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#faa9ba'});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay1' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) < 1){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay2' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay2' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay3' && bo.gstatus.trim() === 'active' && parseFloat(od.odds) > 0){
currBookLay = $('#price_lay_'+bo.mid+od.sid+od.tno).text();

if(parseFloat(currBookLay) !== parseFloat(od.odds)){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: '#ffe680'});
setTimeout(function(){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).css({background: ''});
},200);
}

$('#oddsDivLay_'+bo.mid+od.sid+od.tno).removeAttr('disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text(od.odds);
$('#price_laysize_'+bo.mid+od.sid+od.tno).text(numFormat(od.size));
}
else if(od.otype.trim() === 'lay' && od.oname.trim() === 'lay3' && bo.gstatus.trim() !== 'active' && parseFloat(od.odds) <= 0){
$('#oddsDivLay_'+bo.mid+od.sid+od.tno).attr('disabled', 'disabled');
$('#price_lay_'+bo.mid+od.sid+od.tno).text('');
$('#price_laysize_'+bo.mid+od.sid+od.tno).text('');
}

}
});
});

}
});
});
}
else{
$('.oddsDiv').attr('disabled', 'disabled');
}
} 
} catch (error) {
clearInterval(startData);
setTimeout(function(){
startData = setInterval(fetchData, 1000);
},1000);
}
}

async function matchOdds_pls(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
if(matchOddsRunner.length > 0){

//console.log(response);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

allSec.forEach((ex) => {
//console.log(ex);
const findTeam = matchOddsRunner.filter((e) => e.sec.trim() === ex.trim());
//console.log(findTeam);
if(findTeam.length > 0){
//console.log(findTeam[0].rc);
//var currSec = '';
var t1Prf = 0;
var t2Prf = 0;
var t3Prf = 0;

response.filter((e) => e.sec.trim() === ex.trim()).forEach((exx) => {
//console.log(exx.sec);
var runner1 = null;
var runner2 = null;
var runner3 = null;

if(findTeam[0].rc === 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
}
else if(findTeam[0].rc === 2){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
}

//console.log(exx.sec +' -- '+ runner1 +' --'+ runner2 +' -- '+ runner3 +' -- '+ runner4 
//+' --'+ runner5 +' --'+ runner6 +' -- '+ runner7 +' -- '+ runner8 +' -- '+ runner9);

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'back'){
//currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}

});

//console.log(currSec +' -- '+ t1Prf +' -- '+ t2Prf +' -- '+ t3Prf +' -- '+ t4Prf +' -- '+ t5Prf 
//+' -- '+ t6Prf +' -- '+ t7Prf +' -- '+ t8Prf +' -- '+ t9Prf);

if(findTeam[0] !== undefined){
if(t1Prf > 0){
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html('-'+t1Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(Math.abs(t1Prf)).css({color: '#44b10a'});
}
}

if(findTeam[1] !== undefined){
if(t2Prf > 0){
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html('-'+t2Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(Math.abs(t2Prf)).css({color: '#44b10a'});
}
}

if(findTeam[2] !== undefined){
if(t3Prf > 0){
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html('-'+t3Prf).css({color: '#bd0000'});
}
else{
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(Math.abs(t3Prf)).css({color: '#44b10a'});
}
}

}
});
}
}
} catch (error) {
console.log('backend tennis matchOdds pls bets api : ' + error);
}
}

function numFormat(number){
if (number < 1000) {
return number;
} else if (number >= 1000 && number < 1_000_000) {
return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
} else if (number >= 1_000_000 && number < 1_000_000_000) {
return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
} else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
}
}

export {
checkUser,
startData,
matchOddsRunner,
matchOdds_pls,
clearId
};
