import React from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';


const MyRolling = () => {

const { userActive } = GlobalContext();

const closeRollComm2 = () => {
$('#RollcommisionModal2').hide();
}

return (
<>
<div className='modalsSm' id='RollcommisionModal2'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>rolling commision - </span> <span id='rollUser2'></span> <i className='fa fa-close right' onClick={() => closeRollComm2()}></i></div>
<div className='modalsSm_body'>

{
userActive.length > 0 &&
<div className='wrapRolling'>
<div className='rolling-content'>
<span>fancy</span>
<span>{userActive[0].fancy}</span>
</div>
<div className='rolling-content'>
<span>casino</span>
<span>{userActive[0].casino}</span>
</div>
<div className='rolling-content'>
<span>sportsbook</span>
<span>{userActive[0].sportsbook}</span>
</div>
<div className='rolling-content'>
<span>bookmaker</span>
<span>{userActive[0].bookmaker}</span>
</div>
<div className='rolling-content'>
<span>virtual sports</span>
<span>{userActive[0].virtualsport}</span>
</div>
</div>
}

</div>
</div>
</div>
</>
)
}

export default MyRolling;
