import axios from 'axios';
import $ from 'jquery';

var start_ab;
var allSid_ab = [];

async function check_ab(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_ab = setInterval(fetch_ab, 1000);
}
else{
console.log('login error');
}
}

async function fetch_ab(){
try {
const getData = await axios.get('/server2/andarBahar_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
//$('.odds_cas').attr('disabled', 'disabled');
clearInterval(start_ab);
setTimeout(function(){
start_ab = setInterval(fetch_ab, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('.abPls').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_ab').text(resData.data.t1[0].autotime);
$('#roundId_ab').text(resData.data.t1[0].mid);
$('#eventid_ab').text(resData.data.t1[0].mid);

resData.data.t2.forEach(element => {
if(element.gstatus === '0'){
$('#ab_Team_'+element.sid).attr('disabled','disabled');
$('#ab_TeamTx2_'+element.sid).show().html('<i class="fa fa-lock"></i>');
}
else if(element.gstatus !== '0'){
$('#ab_Team_'+element.sid).removeAttr('disabled');
$('#ab_TeamTx2_'+element.sid).hide().html('');
}
});

resData.data.t3.forEach((cardEle) => {
var andarCards = cardEle.aall.split(',');
var baharCards = cardEle.ball.split(',');
var andCard = document.getElementById('CradsAndar');
var bahCard = document.getElementById('CradsBahar');
var andCardLength = $('#CradsAndar img').length;
var bahCardLength = $('#CradsBahar img').length;
//console.log(andCard);
//console.log(bahCard);
//console.log(andarCards.length);
//console.log(andCardLength);
//console.log(baharCard);

if(andarCards.length !== andCardLength && andCardLength >= 0 && andCard !== null){
andCard.innerHTML = '';
andarCards.forEach((caSet) => {
var imgUrl = '';
//console.log(caSet);
if(caSet !== ''){
imgUrl = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+caSet.toUpperCase()+'.jpg';
}
else{
imgUrl = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg';
}

if(andCard !== null){
andCard.innerHTML += `<img src='${imgUrl}' alt='casino cards' />`;
setTimeout(() => {
andCard.scrollLeft = andCard.scrollWidth;
},1000);
}
});
}

if(baharCards.length !== bahCardLength && bahCardLength >= 0 && bahCard !== null){
bahCard.innerHTML = '';
baharCards.forEach((caSet) => {
var imgUrl = '';
//console.log(caSet);
if(caSet !== ''){
imgUrl = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+caSet.toUpperCase()+'.jpg';
}
else{
imgUrl = 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg';
}

if(bahCard !== null){
bahCard.innerHTML += `<img src='${imgUrl}' alt='casino cards' />`;
setTimeout(() => {
bahCard.scrollLeft = bahCard.scrollWidth;
},1000);
}
});
}

});
}
} catch (error) {
clearInterval(start_ab);
setTimeout(function(){
start_ab = setInterval(fetch_ab, 1000);
},1000);
console.log('frontend refresh_ab_data error : ' + error);
}
}

async function pls_ab(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
const reduceData = response.reduce((c, e) => {
const { team, loss } = e;
const cteam = c.find((h) => h.team === team);
let lossNew = 0;
lossNew = lossNew + loss;
cteam ? cteam.lossNew += lossNew : c.push({team,lossNew});
return c;
},[]);

reduceData.length > 0 && reduceData.forEach(element => {
allSid_ab.forEach((ele) => {
if(element.team === ele.nat){
$('#ab_Pls_'+ele.sid).html(parseFloat(element.lossNew.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
});
});

}
} catch (error) {
console.log('backend ab_pls bets api : ' + error);
}
}

export {
check_ab,
start_ab,
pls_ab,
allSid_ab
};