import axios from 'axios';
import $ from 'jquery';

var allTeamBall = [];

async function ballPls(eventid){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamBall.length > 0){
//console.log(allTeamBall);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'ball').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamBall.length; p++){
if(filterData[i].team === allTeamBall[p].runner && allTeamBall[p].sec === 'ball'){
$('#teamPrf_'+allTeamBall[p].mid+allTeamBall[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend ball fancy pls error : ' + error);
}
}

export { ballPls, allTeamBall };