import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../../context/AppContext';
import { ballPls, allTeamBall } from './js/ballPls';
import { book_Fancy } from './js/book';

const Ball = () => {

const location = useLocation();
const { cricMarket } = GlobalContext();
const [currData, setCurrData] = useState([]);

useEffect(() => {
if(cricMarket.length !== 0){
setCurrData(cricMarket.data !== undefined && cricMarket.data.filter((e) => e.mname.trim() === "ball by ball" && e.gtype === "fancy"));
}
},[cricMarket]);

useEffect(() => {
if(currData.length > 0){
ballPls((location.state !== null ? location.state.eventid : 0));
}
},[currData, location.state]);

return (
<>
{
currData.length > 0 && 
<table className='oddsTable fancyTab'>
<thead>
<tr>
<th className='minWidth' colSpan='2'>
<span className='min-max diskHide'><span className='minF'>min/max :</span> <span className='minS matchMinFancy'>0-1</span></span>
</th>
<th className='noth'>no</th>
<th className='yesth'>yes</th>
<th className='minMaxTh mobHide'>Min-Max</th>
</tr>
</thead>
{
currData[0].section.map((data, index) => {
var RunData = {
sec : 'ball',
runner: data.nat,
mid: data.mid,
sid: data.sid,
}

const findSid = allTeamBall.findIndex(o => o.sid === data.sid);

if(findSid === -1){
allTeamBall.push(RunData);
}
return (
<tbody key={index} style={{position: 'relative'}}>

{
currData.length > 0 &&
<tr className='blockTrF' id={'blockTrF_'+currData[0].mid+data.sid}>
<td colSpan='5'><span>suspended</span></td>   
</tr>
}

<tr>
<td>
<span className='teamName' id={'teamSel_'+data.mid+data.sid}>{data.nat}</span>
<span className='pls' id={'plsArr_'+data.mid+data.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data.mid+data.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data.mid+data.sid}></span>
</td>
<td className='bookBtnTd'>
<button className='bookBtn' onClick={() => book_Fancy((location.state !== null ? location.state.eventid : 0),data.nat,data.mid,data.sid)}>book</button>
</td>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((data2, index2) => {
return(
<td className='counts-td lay1Div' key={index2}>
{
data2.otype === 'lay' && data2.oname === 'lay1' &&
<button className='oddsDiv' id={'oddsDivLay_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_lay_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data2.sid+data2.tno}>{data2.size}</span>
</button>
}

<div className='suspendSmall' id={'suspendFancy_'+data.mid+data2.sid}>
<span id={'suspendFancyTxt_'+data.mid+data2.sid}>suspended</span>
</div>
</td>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((data2, index2) => {
return(
<td className='counts-td back1Div' key={index2}>
{
data2.otype === 'back' && data2.oname === 'back1' &&
<button className='oddsDiv' id={'oddsDiv_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_back_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+data.mid+data2.sid+data2.tno}>{data2.size}</span>
</button>
}
</td>
)
})   
}

<td className='minMaxTd mobHide'>
<span className='minMax matchMinFancy'>0-1</span>
</td>
</tr>

</tbody>
)
})
}
</table>
}
</>
)
}

export default Ball;
