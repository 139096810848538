import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import AllOdds from './AllOdds';
import MasterBook from '../masterBook/MasterBook';
import { checkUser, startData, clearId } from './js/refresh';
import { GlobalContext } from '../../../../context/AppContext';

const apiMarket = '/server2/soccer-market';

const SoccerMarket = () => {

const location = useLocation();
const { GetSoccerMarket, isSoccMarLoad, soccMarket } = GlobalContext();

const showOdds = (mid) => {
//alert(mid);
if(soccMarket.length !== 0 && soccMarket.data !== undefined){
soccMarket.data.forEach(element => {
if(mid !== 0){
if(element.mid === mid){
$('#OddsDiv_'+element.mid).show();
}
else{
$('#OddsDiv_'+element.mid).hide();
}
}
else{
$('#OddsDiv_'+element.mid).show();   
}
});
}
}

const reloadMarket = () => {
GetSoccerMarket(`${apiMarket}/${location.state !== null ? location.state.eventid : 0}`);
}

useEffect(() => {
GetSoccerMarket(`${apiMarket}/${location.state !== null ? location.state.eventid : 0}`);
checkUser(location.state !== null ? location.state.eventid : 0);
return () => {
clearInterval(startData);
clearId();
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.state]);

if(isSoccMarLoad){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents'>
<div className='cols'>
<div className='multimarketdIV'>
<img src='/images/soccer.png' alt='highImg' /> 
{
location.state !== null ? 
<span id='eventname'>{location.state.eventname}</span>
:
<span id='eventname'>no event name (market not found)</span>
}
</div>

<div className='market-divWrap'>

<div className='pinDiv'>
<button onClick={() => reloadMarket()} className='pinBtn2'><i className='fa fa-refresh pinBtnIcon'></i></button>
</div>

{
(soccMarket.length !== 0) &&
<div className='oddsAvailsBtns'>
<div className='scrollMenu'>
<button className='topOddsBtn' onClick={() => showOdds(0)}>All</button>
{
soccMarket.data !== undefined && soccMarket.data.map((data, index) => {
return (
<button className='topOddsBtn' onClick={() => showOdds(data.mid)} key={index}>{data.mname.replaceAll('_', ' ')}</button>
)
})
}
</div>
</div>
}

<AllOdds />

</div>
</div>

<div className='cols'>
<MasterBook />
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default SoccerMarket;
