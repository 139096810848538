import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const usersList_api = '/server2/users-list';
const userActive_api = '/server2/active-user';
const userBanking_api = '/server2/user_bankingData';

const WithDrawMod = () => {

const { userActive, getUserLists, activeUser, userBanking } = GlobalContext();

const closeBank2 = () => {
var id = $('#depoIdBank2').val();
$('#ModalBanking2').hide();
$('.clearBankFrm3').val('');
$('#amountBanking_'+id).val('');
$('#remarkBanking_'+id).val('');
}

const withdrawBanking2 = async() => {
var id = $('#depoIdBank2').val();
var newBank = $('#bankAmt2').text().replaceAll(',', '');
var newRemark = $('#bankRmk2').text();
var newBankPass = $('#adminBankPass2').val();
var availDrw = $('#availDrw').val();

if(newBank <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newRemark === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newBankPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(newBank) > parseFloat(availDrw)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('insufficiant balance');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/withdrawl_banking', {
id: id,
newBank: newBank,
newRemark: newRemark  
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('withdrawal banking sccessfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
$('#ModalBanking2').hide();
$('.clearBankFrm3').val('');
$('#amountBanking_'+id).val('');
$('#remarkBanking_'+id).val('');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend withdrawl2-banking-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='ModalBanking2'>
<div className='modalsSm_content'>
<div className='modalsSm_header'>
<span>withdraw banking - </span> 
<span className='roleTxt' id='rollUserBank2'></span>
<span className='headTxtMod' id='UserBank2'></span>
<i className='fa fa-close right' onClick={() => closeBank2()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>withdraw amount</label></div>
<div><span id='bankAmt2'></span></div>
</div>
<div className='formControls'>
<div><label>withdraw remark</label></div>
<div><span id='bankRmk2'></span></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div>
<input className='clearBankFrm3' type='password' name='adminBankPass2' id='adminBankPass2' placeholder='admin password...' />
<input className='clearBankFrm3' type='hidden' name='depoIdBank2' id='depoIdBank2' />
<input className='clearBankFrm3' type='hidden' name='availDrw' id='availDrw' />
</div>
</div>
<div className='formControlsSubt2'>
<button onClick={() => withdrawBanking2()}>withdraw</button>
<button onClick={() => closeBank2()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default WithDrawMod;
