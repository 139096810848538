import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useLocation } from 'react-router-dom';

const MasterBook = ({reqSec, setReqSec}) => {

const location = useLocation();
const { unsetBets, cricMarket, soccMarket, tennMarket, userList, userActive, allUsers } = GlobalContext();
const [currTeams, setCurrTeams] = useState([]);
const [usersPls, setUsersPls] = useState([]);

const closeMasterBook = () =>{
$('#masterBookModal').hide();
setReqSec(null);
}

const findBook = (user) => {
var allBooks2 = [];
if(currTeams.length > 0){
allUsers.length > 0 && allUsers.filter((e) => e.creator === user).forEach((ex) => {
let plsuser = ex.customer;
let plsuserRole = ex.user_role;
let pls = 0;
let pls1 = 0;
let pls2 = 0;
let pls3 = 0;
let pls4 = 0;
let pls5 = 0;
let pls6 = 0;
let pls7 = 0;
let pls8 = 0;
let pls9 = 0;
let pls10 = 0;
let pls11 = 0;
let pls12 = 0;
let pls13 = 0;
let pls14 = 0;
let pls15 = 0;
let pls16 = 0;
let pls17 = 0;
let pls18 = 0;

var runner1 = currTeams[0] !== undefined ? currTeams[0].team : null;
var runner2 = currTeams[1] !== undefined ? currTeams[1].team : null;
var runner3 = currTeams[2] !== undefined ? currTeams[2].team : null;
var runner4 = currTeams[3] !== undefined ? currTeams[3].team : null;
var runner5 = currTeams[4] !== undefined ? currTeams[4].team : null;
var runner6 = currTeams[5] !== undefined ? currTeams[5].team : null;
var runner7 = currTeams[6] !== undefined ? currTeams[6].team : null;
var runner8 = currTeams[7] !== undefined ? currTeams[7].team : null;
var runner9 = currTeams[8] !== undefined ? currTeams[8].team : null;
var runner10 = currTeams[9] !== undefined ? currTeams[9].team : null;
var runner11 = currTeams[10] !== undefined ? currTeams[10].team : null;
var runner12 = currTeams[11] !== undefined ? currTeams[11].team : null;
var runner13 = currTeams[12] !== undefined ? currTeams[12].team : null;
var runner14 = currTeams[13] !== undefined ? currTeams[13].team : null;
var runner15 = currTeams[14] !== undefined ? currTeams[14].team : null;
var runner16 = currTeams[15] !== undefined ? currTeams[15].team : null;
var runner17 = currTeams[16] !== undefined ? currTeams[16].team : null;
var runner18 = currTeams[17] !== undefined ? currTeams[17].team : null;
var runner19 = currTeams[18] !== undefined ? currTeams[18].team : null;

unsetBets.length > 0 && unsetBets.filter((e) => e.eventid === (location.state !== null ? location.state.eventid : 0) && e.sec === reqSec && (plsuserRole === 'super_role' ? e.super_master === plsuser : plsuserRole === 'master_role' ? e.master === plsuser : plsuserRole === 'agent_role' ? e.agent ===  plsuser : plsuserRole === 'customer_role' && e.customer ===  plsuser)).forEach(ele => {

if(ele.bettype === 'back' && ele.team === runner1){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner2){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner3){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner4){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner5){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner6){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner7){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner8){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner9){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner10){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner11){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner12){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner13){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner14){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner15){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner16){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner17){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner18){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner19){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner1){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner2){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner3){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner4){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner5){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner6){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner7){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner8){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner9){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner10){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner11){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner12){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner13){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner14){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner15){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner16){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner17){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner18){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner19){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

});

var data = {
user: plsuser,
role: plsuserRole, 
pls: pls,
pls1: pls1,
pls2: pls2,
pls3: pls3,
pls4: pls4,
pls5: pls5,
pls6: pls6,
pls7: pls7,
pls8: pls8,
pls9: pls9,
pls10: pls10,
pls11: pls11,
pls12: pls12,
pls13: pls13,
pls14: pls14,
pls15: pls15,
pls16: pls16,
pls17: pls17,
pls18: pls18
}

allBooks2.push(data);
});   
}

setUsersPls(allBooks2.filter((e) => e.pls !== 0));
}

useEffect(() => {
var teams = []
if(window.location.pathname.split('_')[1].replace('/', '') === '4' && cricMarket.length !== 0){
var filterSec = cricMarket.data !== undefined && cricMarket.data.filter((e) => e.mname === reqSec);
if(filterSec.length > 0){
filterSec[0].section.forEach(element => {
var data = {
team: element.nat
}

const findMatch = teams.findIndex((o) => o.team === element.nat);

if(findMatch === -1){
teams.push(data);  
}
});
}

setCurrTeams(teams);
}
else if(window.location.pathname.split('_')[1].replace('/', '') === '1' && soccMarket.length !== 0){
var filterSec2 = soccMarket.data !== undefined && soccMarket.data.filter((e) => e.mname === reqSec);
if(filterSec2.length > 0){
filterSec2[0].section.forEach(element => {
var data = {
team: element.nat
}

const findMatch = teams.findIndex((o) => o.team === element.nat);

if(findMatch === -1){
teams.push(data);  
}
});
}

setCurrTeams(teams);
}
else if(window.location.pathname.split('_')[1].replace('/', '') === '2' && tennMarket.length !== 0){
var filterSec3 = tennMarket.data !== undefined && tennMarket.data.filter((e) => e.mname === reqSec);
if(filterSec3.length > 0){
filterSec3[0].section.forEach(element => {
var data = {
team: element.nat
}

const findMatch = teams.findIndex((o) => o.team === element.nat);

if(findMatch === -1){
teams.push(data);  
}
});
}

setCurrTeams(teams);
}

},[cricMarket, soccMarket, tennMarket, reqSec])

useEffect(() => {
var allBooks = [];
if(currTeams.length > 0){
userActive.length > 0 && userList.length > 0 && userList.filter((e) => e.creator === userActive[0].customer && (e.user_role !== 'customer_role' || e.user_role !== 'demo_role')).forEach((ex) => {
let plsuser = ex.customer;
let plsuserRole = ex.user_role;
let pls = 0;
let pls1 = 0;
let pls2 = 0;
let pls3 = 0;
let pls4 = 0;
let pls5 = 0;
let pls6 = 0;
let pls7 = 0;
let pls8 = 0;
let pls9 = 0;
let pls10 = 0;
let pls11 = 0;
let pls12 = 0;
let pls13 = 0;
let pls14 = 0;
let pls15 = 0;
let pls16 = 0;
let pls17 = 0;
let pls18 = 0;

var runner1 = currTeams[0] !== undefined ? currTeams[0].team : null;
var runner2 = currTeams[1] !== undefined ? currTeams[1].team : null;
var runner3 = currTeams[2] !== undefined ? currTeams[2].team : null;
var runner4 = currTeams[3] !== undefined ? currTeams[3].team : null;
var runner5 = currTeams[4] !== undefined ? currTeams[4].team : null;
var runner6 = currTeams[5] !== undefined ? currTeams[5].team : null;
var runner7 = currTeams[6] !== undefined ? currTeams[6].team : null;
var runner8 = currTeams[7] !== undefined ? currTeams[7].team : null;
var runner9 = currTeams[8] !== undefined ? currTeams[8].team : null;
var runner10 = currTeams[9] !== undefined ? currTeams[9].team : null;
var runner11 = currTeams[10] !== undefined ? currTeams[10].team : null;
var runner12 = currTeams[11] !== undefined ? currTeams[11].team : null;
var runner13 = currTeams[12] !== undefined ? currTeams[12].team : null;
var runner14 = currTeams[13] !== undefined ? currTeams[13].team : null;
var runner15 = currTeams[14] !== undefined ? currTeams[14].team : null;
var runner16 = currTeams[15] !== undefined ? currTeams[15].team : null;
var runner17 = currTeams[16] !== undefined ? currTeams[16].team : null;
var runner18 = currTeams[17] !== undefined ? currTeams[17].team : null;
var runner19 = currTeams[18] !== undefined ? currTeams[18].team : null;

unsetBets.length > 0 && unsetBets.filter((e) => e.eventid === (location.state !== null ? location.state.eventid : 0) && e.sec === reqSec && (plsuserRole === 'subadmin_role' ? e.sub_admin === plsuser : plsuserRole === 'super_role' ? e.super_master === plsuser : plsuserRole === 'master_role' ? e.master === plsuser : plsuserRole === 'agent_role' && e.agent === plsuser)).forEach(ele => {

if(ele.bettype === 'back' && ele.team === runner1){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner2){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner3){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner4){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner5){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner6){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner7){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner8){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner9){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner10){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner11){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner12){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner13){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner14){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner15){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner16){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner17){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner18){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

if(ele.bettype === 'back' && ele.team === runner19){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner1){
pls = parseFloat(pls) - parseFloat(ele.loss);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner2){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) - parseFloat(ele.loss);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner3){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) - parseFloat(ele.loss);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner4){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) - parseFloat(ele.loss);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner5){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) - parseFloat(ele.loss);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner6){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) - parseFloat(ele.loss);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner7){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) - parseFloat(ele.loss);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner8){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) - parseFloat(ele.loss);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner9){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) - parseFloat(ele.loss);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner10){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) - parseFloat(ele.loss);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner11){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) - parseFloat(ele.loss);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner12){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) - parseFloat(ele.loss);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner13){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) - parseFloat(ele.loss);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner14){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) - parseFloat(ele.loss);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner15){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) - parseFloat(ele.loss);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner16){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) - parseFloat(ele.loss);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner17){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) - parseFloat(ele.loss);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner18){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) - parseFloat(ele.loss);
pls18 = parseFloat(pls18) + parseFloat(ele.profit);
}

if(ele.bettype === 'lay' && ele.team === runner19){
pls = parseFloat(pls) + parseFloat(ele.profit);
pls1 = parseFloat(pls1) + parseFloat(ele.profit);
pls2 = parseFloat(pls2) + parseFloat(ele.profit);
pls3 = parseFloat(pls3) + parseFloat(ele.profit);
pls4 = parseFloat(pls4) + parseFloat(ele.profit);
pls5 = parseFloat(pls5) + parseFloat(ele.profit);
pls6 = parseFloat(pls6) + parseFloat(ele.profit);
pls7 = parseFloat(pls7) + parseFloat(ele.profit);
pls8 = parseFloat(pls8) + parseFloat(ele.profit);
pls9 = parseFloat(pls9) + parseFloat(ele.profit);
pls10 = parseFloat(pls10) + parseFloat(ele.profit);
pls11 = parseFloat(pls11) + parseFloat(ele.profit);
pls12 = parseFloat(pls12) + parseFloat(ele.profit);
pls13 = parseFloat(pls13) + parseFloat(ele.profit);
pls14 = parseFloat(pls14) + parseFloat(ele.profit);
pls15 = parseFloat(pls15) + parseFloat(ele.profit);
pls16 = parseFloat(pls16) + parseFloat(ele.profit);
pls17 = parseFloat(pls17) + parseFloat(ele.profit);
pls18 = parseFloat(pls18) - parseFloat(ele.loss);
}

});

var data = {
user: plsuser,
role: plsuserRole, 
pls: pls,
pls1: pls1,
pls2: pls2,
pls3: pls3,
pls4: pls4,
pls5: pls5,
pls6: pls6,
pls7: pls7,
pls8: pls8,
pls9: pls9,
pls10: pls10,
pls11: pls11,
pls12: pls12,
pls13: pls13,
pls14: pls14,
pls15: pls15,
pls16: pls16,
pls17: pls17,
pls18: pls18
}

allBooks.push(data);
});   
}

setUsersPls(allBooks.filter((e) => e.pls !== 0));

},[currTeams, unsetBets, location.state, userActive, userList, reqSec]);

return (
<>
<div className='modalsSm' id='masterBookModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>master book</span> <i className='fa fa-close right' onClick={() => closeMasterBook()}></i></div>
<div className='modalsSm_body'>

<div className='table-responsive'>
<table className='usersTableBook'>
<thead>
<tr>
<th>username</th>
<th>role</th>
<th>sec</th>
{
currTeams.length > 0 && currTeams.map((data, index) => {
return (
<th key={index}>{data.team}</th>
)
})
}
</tr>
</thead>
<tbody>
{
usersPls.length > 0 && usersPls.map((data, index) => {
return (
<tr key={index}>
<td>{data.role === 'customer_role' || data.role === 'demo_role' ? data.user : <span className='links' onClick={() => findBook(data.user)}>{data.user}</span>}</td>
<td>{data.role.split('_')[0]}</td>
<td>{reqSec}</td>
<td>{data.pls > 0 ? <span className='successTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span>}</td>
<td>{data.pls1 > 0 ? <span className='successTxt'>{parseFloat(data.pls1.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls1.toFixed(2)).toLocaleString()}</span>}</td>
{
(currTeams.length > 0 && currTeams[2] !== undefined) &&
<td>{data.pls2 > 0 ? <span className='successTxt'>{parseFloat(data.pls2.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls2.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[3] !== undefined) &&
<td>{data.pls3 > 0 ? <span className='successTxt'>{parseFloat(data.pls3.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls3.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[4] !== undefined) &&
<td>{data.pls4 > 0 ? <span className='successTxt'>{parseFloat(data.pls4.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls4.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[5] !== undefined) &&
<td>{data.pls5 > 0 ? <span className='successTxt'>{parseFloat(data.pls5.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls5.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[6] !== undefined) &&
<td>{data.pls6 > 0 ? <span className='successTxt'>{parseFloat(data.pls6.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls6.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[7] !== undefined) &&
<td>{data.pls7 > 0 ? <span className='successTxt'>{parseFloat(data.pls7.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls7.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[8] !== undefined) &&
<td>{data.pls8 > 0 ? <span className='successTxt'>{parseFloat(data.pls8.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls8.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[9] !== undefined) &&
<td>{data.pls9 > 0 ? <span className='successTxt'>{parseFloat(data.pls9.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls9.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[10] !== undefined) &&
<td>{data.pls10 > 0 ? <span className='successTxt'>{parseFloat(data.pls10.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls10.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[11] !== undefined) &&
<td>{data.pls11 > 0 ? <span className='successTxt'>{parseFloat(data.pls11.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls11.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[12] !== undefined) &&
<td>{data.pls12 > 0 ? <span className='successTxt'>{parseFloat(data.pls12.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls12.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[13] !== undefined) &&
<td>{data.pls13 > 0 ? <span className='successTxt'>{parseFloat(data.pls13.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls13.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[14] !== undefined) &&
<td>{data.pls14 > 0 ? <span className='successTxt'>{parseFloat(data.pls14.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls14.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[15] !== undefined) &&
<td>{data.pls15 > 0 ? <span className='successTxt'>{parseFloat(data.pls15.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls15.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[16] !== undefined) &&
<td>{data.pls16 > 0 ? <span className='successTxt'>{parseFloat(data.pls16.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls16.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[17] !== undefined) &&
<td>{data.pls17 > 0 ? <span className='successTxt'>{parseFloat(data.pls17.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls17.toFixed(2)).toLocaleString()}</span>}</td>
}
{
(currTeams.length > 0 && currTeams[18] !== undefined) &&
<td>{data.pls18 > 0 ? <span className='successTxt'>{parseFloat(data.pls18.toFixed(2)).toLocaleString()}</span> : <span className='dangerTxt'>{parseFloat(data.pls18.toFixed(2)).toLocaleString()}</span>}</td>
}
</tr>
)
})
}
</tbody>
</table>
</div>

</div>
</div>
</div>
</>
)
}

export default MasterBook;
