import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/AppContext';

const MarketAnal = () => {

const { unsetBets } = GlobalContext();
const [currSports, setCurrSports] = useState([]);
const [currSportsList, setCurrSportsList] = useState([]);
const Navigate = useNavigate();

const openMarket = (sport,eventid,eventname) => {
//alert(sport +' -- '+ eventid);
if(sport === 'cricket'){
Navigate('/market_4/', {state:{eventid:eventid, eventname:eventname}});
}
else if(sport === 'soccer'){
Navigate('/market_1/', {state:{eventid:eventid, eventname:eventname}});
}
else if(sport === 'tennis'){
Navigate('/market_2/', {state:{eventid:eventid, eventname:eventname}});
}
else if(sport === 't20'){
Navigate('/casino/t20/');
}
else if(sport === 't20_1day'){
Navigate('/casino/t201day/');
}
else if(sport === 'dt20'){
Navigate('/casino/dt20/');
}
else if(sport === 'dt202'){
Navigate('/casino/dt202/');
}
else if(sport === 'dt_1day'){
Navigate('/casino/dt1day/');
}
else if(sport === 'andar_bahar'){
Navigate('/casino/ab/');
}
else if(sport === 'lucky7a'){
Navigate('/casino/lucky7a/');
}
else if(sport === 'lucky7b'){
Navigate('/casino/lucky7b/');
}
else if(sport === 'teen_test'){
Navigate('/casino/teen_test/');
}
}

useEffect(() => {
if(unsetBets.length > 0){
var sports = [];
var sportsList = [];

unsetBets.forEach(element => {
if(element.eventName !== 'virtual casino'){
var data = '';
if(element.eventName !== 'casino'){
data = {
sport: element.sport
}
}
else{
data = {
sport: element.eventName
}    
}

var data2 = {
sport: element.sport,
eventname: element.eventName,
eventid: element.eventid
}

const findIndex = sports.findIndex((p) => p.sport === element.sport || p.sport === element.eventName);
const findIndex2 = sportsList.findIndex((u) => u.sport === element.sport && u.eventname === element.eventName);

if(findIndex === -1){
sports.push(data); 
}

if(findIndex2 === -1){
sportsList.push(data2); 
}
}
});
setCurrSports(sports);
setCurrSportsList(sportsList);
}
},[unsetBets]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

{
currSports.length > 0 ? currSports.map((data, index) => {
return (
<div className='profilePage' key={index}>
<div className='marketAnalDiv'><span>{data.sport}</span></div>

<ul className='match-list'>
{
currSportsList.length > 0 && currSportsList.filter((e) => e.sport === data.sport || e.eventname === data.sport).map((data2, index2) => {
var totalBets = unsetBets.filter((c) => c.eventid === data2.eventid);
return (
<li key={index2}>
<div><span onClick={() => openMarket(data2.sport,data2.eventid,data2.eventname)}>{data2.eventname === 'casino' ? data2.sport : data2.eventname}</span></div>
<div>{data2.eventname !== 'casino' && <span>total bets {totalBets.length}</span>}</div>
</li>
)
})
}
</ul>

</div>
)
})
:
<div className='profilePage'>
<div className='noRecordDiv'>
<img src='/images/cloud.png' alt='cloud' />
<span>currently no bets available...</span>
</div>
</div>
}

</div>
</div>
</div>
</>
)
}

export default MarketAnal;
