import axios from 'axios';
import $ from 'jquery';

var start_dt20;
var allSid_dt20 = [];

async function check_dt20(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_dt20 = setInterval(fetchDt20, 1000);
}
else{
console.log('login error');
}
}

async function fetchDt20(){
try {
const getData = await axios.get('/server2/dt20_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
//$('.odds_cas').attr('disabled', 'disabled');
clearInterval(start_dt20);
setTimeout(function(){
start_dt20 = setInterval(fetchDt20, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('.dtPls').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_dt20').text(resData.data.t1[0].autotime);
$('#roundId_dt20').text(resData.data.t1[0].mid);
$('#eventid_dt20').text(resData.data.t1[0].mid);

$('#card1_dt20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_dt20').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');

resData.data.t2.forEach(element => {
if(element.gstatus === '0'){
$('#dt20_Team_'+element.sid).attr('disabled','disabled');
$('#dt20_TeamTx2_'+element.sid).show().html('<i class="fa fa-lock"></i>');
}
else if(element.gstatus !== '0'){
$('#dt20_Team_'+element.sid).removeAttr('disabled');
$('#dt20_TeamTx2_'+element.sid).hide().html('');
}
});

}
} catch (error) {
clearInterval(start_dt20);
setTimeout(function(){
start_dt20 = setInterval(fetchDt20, 1000);
},1000);
console.log('backend refresh_dt20_data error : ' + error);
}
}

async function pls_dt20(eventid,sport){
//console.log(allSid_dt20);
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
//console.log(resData);
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
const reduceData = response.reduce((c, e) => {
const { team, loss } = e;
const cteam = c.find((h) => h.team === team);
let lossNew = 0;
lossNew = lossNew + loss;
cteam ? cteam.lossNew += lossNew : c.push({team,lossNew});
return c;
},[]);

//console.log(reduceData);

reduceData.length > 0 && reduceData.forEach(element => {
allSid_dt20.forEach((ele) => {
if(element.team === ele.nation){
$('#dt20_Pls_'+ele.sid).html(parseFloat(element.lossNew.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
});
});

}
} catch (error) {
console.log('backend dt20_pls bets api : ' + error);
}
}

export {
check_dt20,
start_dt20,
pls_dt20,
allSid_dt20
};