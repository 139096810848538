import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';
import CreditModal from '../modals/CreditModal';
import Banking from '../modals/Banking';
import Status from '../modals/Status';
import AddUser from '../modals/AddUser';
import SportsModal from '../modals/SportsModal';
import ExposureLimit from '../modals/ExposureLimit';
import TotalBal from './TotalBal';
import Remove from '../modals/Remove';
import StatusAlert from '../pages/StatusAlert';

const userExpo_api = '/server2/exposure-user';
const usersList_api = '/server2/users-list';

const AllUsers = () => {

const { userList, useExpo, getUserLists, userExpo, userList_load, expoLoad } = GlobalContext();
const [currUsers, setCurrUsers] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const [sportUser, setSportUser] = useState(null);
const Navigate = useNavigate();

const showUsersAdd = () => {
$('#usersModal').show();
}

const reloadUsers = () => {
getUserLists(usersList_api);
userExpo(userExpo_api);
}

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const creditRef = (id,user,credit) => {
$('#creditModal').show();
$('#userEditM').text(user);
$('#oldCreditRef').text(parseFloat(credit.toFixed(2)).toLocaleString());
$('#creditId').val(id);
}

const expoOpen = (id,user,expLimit) => {
$('#ExpoLimitModal').show();
$('#userEditU').text(user);
$('#oldExpoLimit').text(parseFloat(expLimit.toFixed(2)).toLocaleString());
$('#expoLimitId').val(id);
}

const userBanking =(id,role,name,bal,total) => {
$('#bankingModal').show();
var a = role.split('_');
$('#cuurBankUserRole').text(a[0]);
$('#cuurBankUser').text(name);
$('#clientBalB').text(parseFloat(bal.toFixed(2)).toLocaleString());
$('#availBal').val(total);
$('#bankingId').val(id);
}

const userStatus = (id,role,name,status) => {
$('#statusModal').show();
var a = role.split('_'); 
$('#statusUserRole').text(a[0]);
$('#cuurStatusUser').text(name);
$('#statusId').val(id);
$('#statusUser').text(status);
}

const userSports = (role,user) => {
$('#sportsModal').show();
var a = role.split('_');
$('#sportsUserRole').text(a[0]);
$('#sportsUser').text(user);
setSportUser(user);
}

const userProfile = (user,userRole) => {
Navigate('/user_profile', {state:{name:user, role:userRole}});
}

const userProfitLoss = (user,userRole) => {
Navigate('/profit_loss', {state:{name:user, role:userRole}});
}

const userBetHistory = (user,userRole) => {
Navigate('/bet_history', {state:{name:user, role:userRole}});
}

const removeUsers = (id,expo,bal,role,user) => {
// alert(expo +' -- '+ bal +' -- '+ role +' -- '+ user);
if(expo > 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('waiting for clear exposure');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(bal > 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('withdrawal all avail. balance');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
const a = role.split('_');
$('#removeModal').show();
$('#removeUserId').val(id);
$('#roleRemove').text(a[0]);
$('#userRemove').text(user);
}
}

const searchUsers = (name) => {
setCurrUsers(userList.filter((e) => e.user_role.trim() === 'customer_role' && e.customer.includes(name)));
}

useEffect(() => {
if(userList.length > 0){
setCurrUsers(userList.filter((e) => e.user_role.trim() === 'customer_role'));
}
},[userList]);

if(userList_load && expoLoad){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='firstColDiv'>
<div className='content'>
<div className='addUserBtnDiv'>
<button onClick={() => showUsersAdd()}><i className='fa fa-user-plus'></i> add users</button>
<button onClick={() => reloadUsers()}><img src='/images/refresh-arrow.png' alt='reload' /></button>
</div>
</div>
</div>

<TotalBal />

<div className='firstColDiv tableWrap'>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>currency</th>
<th>credit ref.</th>
<th>balance</th>
<th>exposure</th>
<th>exposure limit</th>
<th>avail. bal.</th>
<th>ref. p/l</th>
<th>partnership</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
var currStatus = null;
if(data.status === 'active'){
currStatus = <span className='statusTd actGreen'>active</span>;   
}
else if(data.status === 'suspended'){
currStatus = <span className='statusTd actRed'>suspended</span>;   
}
else{
currStatus = <span className='statusTd actYell'>locked</span>;   
}

const currExpo = useExpo.filter((e) => e.customer === data.customer);
//console.log(currExpo);
return(
<tr key={index}>
<td><span className='roleTxt'>{data.user_role.slice(0, 3)}</span> {data.customer}</td>
<td>{data.currency}</td>
<td>{parseFloat(data.creditRefrence.toFixed(2)).toLocaleString()} <i className='fa fa-edit editIcon' onClick={() => creditRef(data.id,data.customer,data.creditRefrence)}></i></td>
<td>{parseFloat(data.total_balance.toFixed(2)).toLocaleString()}</td>
<td><span className='dangerTxt'>({currExpo.length > 0 ? parseFloat(currExpo[0].exposure.toFixed(2)).toLocaleString() : 0})</span></td>
<td>{parseFloat(data.ExposureLimit.toFixed(2)).toLocaleString()} <i className='fa fa-edit editIcon' onClick={() => expoOpen(data.id,data.customer,data.ExposureLimit)}></i></td>
<td>{parseFloat((data.total_balance - (currExpo.length > 0 ? currExpo[0].exposure : 0)).toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.creditRefrence) > parseFloat(data.total_balance) ? <span className='dangerTxt'>({parseFloat((data.total_balance - data.creditRefrence).toFixed(2)).toLocaleString()})</span> : <span className='successTxt'>({parseFloat((data.total_balance - data.creditRefrence).toFixed(2)).toLocaleString()})</span>}</td>
<td>{data.partnership}</td>
<td>{currStatus}</td>
<td>
<button onClick={() => userBanking(data.id,data.user_role,data.customer,data.total_balance,(data.total_balance - (currExpo.length > 0 ? currExpo[0].exposure : 0)))} className='tdBtn'><img src='/images/bank-building.png' alt='bank-building' /></button>
<button onClick={() => userProfitLoss(data.customer,data.user_role)} className='tdBtn'><img src='/images/upDown.png' alt='upDown' /></button>
<button onClick={() => userBetHistory(data.customer,data.user_role)} className='tdBtn'><img src='/images/history.png' alt='history' /></button>
<button onClick={() => userStatus(data.id,data.user_role,data.customer,data.status)} className='tdBtn'><img src='/images/settings.png' alt='bank-building' /></button>
<button onClick={() => userProfile(data.customer,data.user_role)} className='tdBtn'><img src='/images/user.png' alt='bank-building' /></button>
<button  onClick={() => userSports(data.user_role,data.customer)} className='tdBtn'><img src='/images/lock.png' alt='bank-building' /></button>
<button onClick={() => removeUsers(data.id,(currExpo.length > 0 ? currExpo[0].exposure : 0),data.total_balance,data.user_role,data.customer)} className='tdBtn'><img src='/images/trash-bin.png' alt='bank-building' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='11' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>

<AddUser />
<CreditModal />
<Banking />
<Status />
<SportsModal user={sportUser} />
<ExposureLimit />
<Remove />

</>
)
}

export default AllUsers;
