import axios from 'axios';
import $ from 'jquery';

var allTeamOdd = [];

async function oddEvenPls(eventid){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamOdd.length > 0){
//console.log(allTeamOdd);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'oddeven').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamOdd.length; p++){
if(filterData[i].team === allTeamOdd[p].runner && allTeamOdd[p].sec === 'oddeven'){
$('#teamPrf_'+allTeamOdd[p].mid+allTeamOdd[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend oddEven fancy pls error : ' + error);
}
}

export { oddEvenPls, allTeamOdd };