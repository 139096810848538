import axios from 'axios';
import $ from 'jquery';

var allTeamMeter = [];

async function meterPls(eventid){
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamMeter.length > 0){
//console.log(allTeamMeter);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'meter').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

// console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamMeter.length; p++){
if(filterData[i].team === allTeamMeter[p].runner && allTeamMeter[p].sec === 'meter'){
$('#teamPrf_'+allTeamMeter[p].mid+allTeamMeter[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend meter fancy pls error : ' + error);
}
}

export { meterPls, allTeamMeter };