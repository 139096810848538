import { useEffect } from 'react'

const Online = () => {

useEffect(() => {
if(!navigator.onLine){
window.location.replace('/');
}
}, []);
return null;
}

export default Online;
