import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const MasRep = () => {

const Navigate = useNavigate();
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const { userList, allUsers } = GlobalContext();
const [currList, setCurrList] = useState([]);
const [currRepo, setCurrRepo] = useState([]);
const [currRepoVal, setCurrRepoVal] = useState([]);
const [currTotal, setCurrTotal] = useState(0);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currList.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currList.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

console.log(currList);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const masterPls = (role,user) => {
if(role !== 'customer_role'){
setCurrList(allUsers.filter((e) => e.creator === user));
}
else{
Navigate('/profit_loss', {state:{name: user, role: role, startDate: startDate, endDate: endDate}});
}
}

const searchUsers = (name) => {
setCurrList(userList.filter((e) => e.customer.includes(name)));
}

useEffect(() => {
if(userList.length > 0){
setCurrList(userList.filter((e) => e.user_role !== 'demo_role'));
}
},[userList]);

useEffect(() => {

async function fetchData(){
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();

try {
const sendData = await axios.post('/server2/settle_bets', {
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
if(resData.length > 0){
setCurrRepo(resData);
}
else{
setCurrRepo([]);
}
} catch (error) {
console.log('backend master-settle-bet-api error : ' + error);
}
}

fetchData();
},[startDate, endDate]);

useEffect(() => {
var allTotal = [];

currList.length > 0 && currList.forEach(element => {

var customerTotal = '';
var subTotal = '';
var superTotal = '';
var masterTotal = '';
var agentTotal = '';

if(element.user_role === 'customer_role'){
customerTotal = currRepo.filter((e) => e.settlement === 'settle' && e.customer === element.customer).reduce((a, b) => {
const { customer, profit, loss, win_status } = b;
const item = a.find((O) => O.customer === customer);
let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit; 
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({customer,finalLos});
return a;
},[]);
}
if(element.user_role === 'subadmin_role'){
subTotal = currRepo.filter((e) => e.settlement === 'settle' && e.sub_admin === element.customer).reduce((a, b) => {
const { sub_admin, profit, loss, win_status } = b;
const item = a.find((O) => O.sub_admin === sub_admin);
let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit; 
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({sub_admin,finalLos});
return a;
},[]);
}
if(element.user_role === 'super_role'){
superTotal = currRepo.filter((e) => e.settlement === 'settle' && e.super_master === element.customer).reduce((a, b) => {
const { super_master, profit, loss, win_status } = b;
const item = a.find((O) => O.super_master === super_master);
let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit; 
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({super_master,finalLos});
return a;
},[]);
}
if(element.user_role === 'master_role'){
masterTotal = currRepo.filter((e) => e.settlement === 'settle' && e.master === element.customer).reduce((a, b) => {
const { master, profit, loss, win_status } = b;
const item = a.find((O) => O.master === master);
let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit; 
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({master,finalLos});
return a;
},[]);
}
if(element.user_role === 'agent_role'){
agentTotal = currRepo.filter((e) => e.settlement === 'settle' && e.agent === element.customer).reduce((a, b) => {
const { agent, profit, loss, win_status } = b;
const item = a.find((O) => O.agent === agent);
let newPrf = 0;
let newLos = 0;
let finalLos = 0;

if(win_status === 'win'){
newPrf = newPrf + profit; 
}
else{
newLos = newLos + loss;
}

finalLos = parseFloat(newPrf) - parseFloat(newLos);
item ? item.finalLos += finalLos : a.push({agent,finalLos});
return a;
},[]);
}

if(customerTotal.length > 0){
customerTotal.forEach((e) => {
var data = {
customer: e.customer,
pls: e.finalLos
}

const findKey = allTotal.findIndex((i) => i.customer === e.customer);

if(findKey === -1){
allTotal.push(data);
}
});
}
if(subTotal.length > 0){
subTotal.forEach((e) => {
var data = {
customer: e.sub_admin,
pls: e.finalLos
}

const findKey = allTotal.findIndex((i) => i.customer === e.sub_admin);

if(findKey === -1){
allTotal.push(data);
}
});
}
if(superTotal.length > 0){
superTotal.forEach((e) => {
var data = {
customer: e.super_master,
pls: e.finalLos
}

const findKey = allTotal.findIndex((i) => i.customer === e.super_master);

if(findKey === -1){
allTotal.push(data);
}
});
}
if(masterTotal.length > 0){
masterTotal.forEach((e) => {
var data = {
customer: e.master,
pls: e.finalLos
}

const findKey = allTotal.findIndex((i) => i.customer === e.master);

if(findKey === -1){
allTotal.push(data);
}
});
}
if(agentTotal.length > 0){
agentTotal.forEach((e) => {
var data = {
customer: e.agent,
pls: e.finalLos
}

const findKey = allTotal.findIndex((i) => i.customer === e.agent);

if(findKey === -1){
allTotal.push(data);
}
});
}

});

setCurrRepoVal(allTotal);
},[currRepo, currList]);

useEffect(() => {
if(currRepoVal.length > 0){
const totalCount = currRepoVal.reduce((a, b) => {
a = a + b.pls;
return a;
},0);

setCurrTotal(totalCount);
}
else{
setCurrTotal(0);
}
},[currRepoVal]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='dateDivWrap'>
<div className='dateForm'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>downline profit / loss</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currList.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable2'>
<thead>
<tr>
<th>customer name</th>
<th>upline profit/loss</th>
<th>downline profit/loss</th>
<th>commision</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
let usrAmt = 0;
const findusrAmt = currRepoVal.filter((e) => e.customer === data.customer);
if(findusrAmt.length > 0){
usrAmt = findusrAmt[0].pls; 
}
return(
<tr key={index}>
<td>
<span className='wrapRoleUsr'>
<span className='roleTxt'>{data.user_role.slice(0, 3)}</span>
<span className='links inLineBlock ml5' onClick={() => masterPls(data.user_role,data.customer)}>{data.customer}</span>
</span>
</td>
<td>{usrAmt < 0 ? <span className='successTxt'>{parseFloat(Math.abs(usrAmt).toFixed(2)).toLocaleString()}</span> : usrAmt > 0 ? <span className='dangerTxt'>{'-'+parseFloat(usrAmt.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>{usrAmt > 0 ? <span className='successTxt'>{parseFloat(usrAmt.toFixed(2)).toLocaleString()}</span> : usrAmt < 0 ? <span className='dangerTxt'>{parseFloat(usrAmt.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>0</td>
</tr>
)
})
:
<tr>
<td colSpan='4' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
<tr>
<td><b>Total</b></td>
<td>{currTotal < 0 ? <span className='successTxt'>{parseFloat(Math.abs(currTotal).toFixed(2)).toLocaleString()}</span> : currTotal > 0 ? <span className='dangerTxt'>{'-'+parseFloat(currTotal.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>{currTotal > 0 ? <span className='successTxt'>{parseFloat(currTotal.toFixed(2)).toLocaleString()}</span> : currTotal < 0 ? <span className='dangerTxt'>{parseFloat(currTotal.toFixed(2)).toLocaleString()}</span> : 0}</td>
<td>0</td>
</tr>

</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default MasRep;
