import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthUser = () => {

const Navigate = useNavigate();

useEffect(() => {
async function fetchData(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length <= 0){
async function ipLogOut() {
try {
const sendData = await axios.get('/server2/logout');
const resData = await sendData.data;
if(resData === 'success'){
Navigate('/');
return false;
}
} catch (error) {
console.log('backend logout-api error : ' + error);
} 
}

ipLogOut();
}
}

fetchData();
},[Navigate]);

return null;
}

export default AuthUser;
