import axios from 'axios';

async function getAllBetsMarket(eventid){
//console.log(eventid);
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
if(resData.length > 0){
//console.log(resData);
const findBets = resData.filter((e) => e.eventid === eventid);

//console.log(findBets);
if(findBets.length > 0){
if(document.getElementById('allMarketBets') !== null && document.getElementById('allMarket_bets') !== null){
document.getElementById('allMarketBets').innerHTML = '';
document.getElementById('allMarket_bets').style.display = 'block';
findBets.forEach(element => {
if(element.bettype === 'back'){
document.getElementById('allMarketBets').innerHTML += `<tr class='backTr'>
<td>${element.customer}</td>
<td>${element.team}</td>
<td>${element.sec}</td>
<td>${element.bettype}</td>
<td>${element.betRate}</td>
<td>${element.betSize !== null ? element.betSize : 0}</td>
<td>${(element.betamount).toFixed(2)}</td>
<td>${(element.profit).toFixed(2)}</td>
<td>${(element.loss).toFixed(2)}</td>
<td>${element.bet_dateTime}</td>
</tr>`;
}
if(element.bettype === 'lay'){
document.getElementById('allMarketBets').innerHTML += `<tr class='layTr'>
<td>${element.customer}</td>
<td>${element.team}</td>
<td>${element.sec}</td>
<td>${element.bettype}</td>
<td>${element.betRate}</td>
<td>${element.betSize !== null ? element.betSize : 0}</td>
<td>${(element.betamount).toFixed(2)}</td>
<td>${(element.profit).toFixed(2)}</td>
<td>${(element.loss).toFixed(2)}</td>
<td>${element.bet_dateTime}</td>
</tr>`;
}
});
}
}
}
} catch (error) {
console.log('backend market bets data api error : ' + error);
}
}


export {getAllBetsMarket};