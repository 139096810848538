import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MarketBets from '../../../../allBets/MarketBets';
import { startResult_lu7a, runresult_lu7a } from './js/result';
import { 
check_lu7a,
start_lu7a,
pls_lu7a,
allSid_lu7a
} from './js/refresh';
import Lu7Mod from '../modals/Lu7Mod';
import Lu7Rules from '../modals/Lu7Rules';

const Lucky7A = () => {

const [load_lu7a, setLoad_lu7a] = useState(true);
const [currlu7a, setCurrLu7a] = useState([]);

const openlu7aRules = () => {
$('#lu7Modal').show();
}

useEffect(() => {
async function fetchLucky7a() {
try {
const getData = await axios.get('/server2/lucky7a_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nation : element.nation
}

const findInd = allSid_lu7a.findIndex((o) => o.sid === element.sid && o.nation === element.nation);
if(findInd === -1){
allSid_lu7a.push(data);
}
});
setLoad_lu7a(false);
setCurrLu7a(resData);
}
else{
setLoad_lu7a(true);
setCurrLu7a([]);
}
} catch (error) {
console.log('backend casino_lucky7a_data error : ' + error);
}
}

fetchLucky7a();
},[]);

useEffect(() => {
check_lu7a();
runresult_lu7a();
pls_lu7a(currlu7a.data !== undefined ? currlu7a.data.t1[0].mid : 0,'lucky7a');
return () => {
clearInterval(startResult_lu7a);
clearInterval(start_lu7a);
}
},[currlu7a]);

if(load_lu7a){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
currlu7a.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>lucky 7A</span>
<span className='casGamerule' onClick={openlu7aRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_lu7a'>{currlu7a.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_lu7a' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currlu7a.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_lu7a'>{currlu7a.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3058' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>low / high </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[0].sid}>{currlu7a.data.t2[0].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[0].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[0].sid}>{currlu7a.data.t2[0].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[0].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[0].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[1].sid}>{currlu7a.data.t2[1].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[1].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[1].sid}>{currlu7a.data.t2[1].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[1].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[1].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>even / odd </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[2].sid}>{currlu7a.data.t2[2].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[2].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[2].sid}>{currlu7a.data.t2[2].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[2].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[2].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[3].sid}>{currlu7a.data.t2[3].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[3].sid}>
<span id={'lu7a_TeamTx3_'+currlu7a.data.t2[3].sid}>{currlu7a.data.t2[3].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[3].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[3].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[4].sid}>{currlu7a.data.t2[4].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[4].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[4].sid}>{currlu7a.data.t2[4].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[4].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[4].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[5].sid}>{currlu7a.data.t2[5].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[5].sid}>
<span id={'lu7a_TeamTx5_'+currlu7a.data.t2[5].sid}>{currlu7a.data.t2[5].nation}</span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[5].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[5].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>all cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[6].sid}>{currlu7a.data.t2[6].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[6].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[6].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[6].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[6].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[7].sid}>{currlu7a.data.t2[7].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[7].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[7].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[7].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[7].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[8].sid}>{currlu7a.data.t2[8].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[8].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[8].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[9].sid}>{currlu7a.data.t2[9].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[9].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[9].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[10].sid}>{currlu7a.data.t2[10].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[10].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[10].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[11].sid}>{currlu7a.data.t2[11].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[11].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[11].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[12].sid}>{currlu7a.data.t2[12].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[12].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[12].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[12].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[13].sid}>{currlu7a.data.t2[13].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[13].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[13].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[14].sid}>{currlu7a.data.t2[14].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[14].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[14].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[15].sid}>{currlu7a.data.t2[15].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[15].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[15].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[16].sid}>{currlu7a.data.t2[16].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[16].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[16].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[17].sid}>{currlu7a.data.t2[17].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[17].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[17].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'lu7a_Rate_'+currlu7a.data.t2[18].sid}>{currlu7a.data.t2[18].rate}</span>
<button id={'lu7a_Team_'+currlu7a.data.t2[18].sid}>
<span id={'lu7a_TeamTx1_'+currlu7a.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'lu7a_TeamTx2_'+currlu7a.data.t2[18].sid}></span>
</button>
<span className='dtPls lu7aPls' id={'lu7a_Pls_'+currlu7a.data.t2[18].sid}>0</span>
</div>

</div>
</div>

</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_lu7a'></div>
</div>

<MarketBets eventid={currlu7a.data !== undefined ? currlu7a.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<Lu7Mod />
<Lu7Rules />
</>
)
}

export default Lucky7A;
