import axios from 'axios';
import $ from 'jquery';

var start_lu7a;
var allSid_lu7a = [];

async function check_lu7a(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_lu7a = setInterval(fetch_lu7a, 1000);
}
else{
console.log('login error');
}
}

async function fetch_lu7a(){
try {
const getData = await axios.get('/server2/lucky7a_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
//$('.odds_cas').attr('disabled', 'disabled');
clearInterval(start_lu7a);
setTimeout(function(){
start_lu7a = setInterval(fetch_lu7a, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('.lu7aPls').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_lu7a').text(resData.data.t1[0].autotime);
$('#roundId_lu7a').text(resData.data.t1[0].mid);
$('#eventid_lu7a').text(resData.data.t1[0].mid);

$('#card1_lu7a').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');

resData.data.t2.forEach(element => {
if(element.gstatus === '0'){
$('#lu7a_Team_'+element.sid).attr('disabled','disabled');
$('#lu7a_TeamTx2_'+element.sid).show().html('<i class="fa fa-lock"></i>');
}
else if(element.gstatus !== '0'){
$('#lu7a_Team_'+element.sid).removeAttr('disabled');
$('#lu7a_TeamTx2_'+element.sid).hide().html('');
}
});
}
} catch (error) {
clearInterval(start_lu7a);
setTimeout(function(){
start_lu7a = setInterval(fetch_lu7a, 1000);
},1000);
console.log('backend refresh_lu7a_data error : ' + error);
}
}

async function pls_lu7a(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
const reduceData = response.reduce((c, e) => {
const { team, loss } = e;
const cteam = c.find((h) => h.team === team);
let lossNew = 0;
lossNew = lossNew + loss;
cteam ? cteam.lossNew += lossNew : c.push({team,lossNew});
return c;
},[]);

reduceData.length > 0 && reduceData.forEach(element => {
allSid_lu7a.forEach((ele) => {
if(element.team === ele.nation){
$('#lu7a_Pls_'+ele.sid).html(parseFloat(element.lossNew.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
});
});

}
} catch (error) {
console.log('backend lu7a_pls bets api : ' + error);
}
}

export {
check_lu7a,
start_lu7a,
pls_lu7a,
allSid_lu7a
};