import axios from 'axios';
import $ from 'jquery';

var startResult_dt20;

function runresult_dt20(){
fetchResult_dt20();
startResult_dt20 = setInterval(fetchResult_dt20, 2000);
}

async function fetchResult_dt20() {
try {
const getData = await axios.get('/server2/dt20_Result');
const resData = await getData.data;
//console.log('result');
if(resData.data === undefined){
clearInterval(startResult_dt20);
setTimeout(function(){
startResult_dt20 = setInterval(fetchResult_dt20, 1000);
},1000);
}
else{
if(document.getElementById('allResCas_dt20') !== null){
document.getElementById('allResCas_dt20').innerHTML = '';
resData.data.forEach(element => {
document.getElementById('allResCas_dt20').innerHTML += `<span id='casResFull_${element.mid}' class=${element.result === '1' ? 'dotOne_dt20' : 'dotTwo_dt20'}>${element.result === '1' ? 'D' : 'T'}</span>`;
});   
}
}
} catch (error) {
clearInterval(startResult_dt20);
setTimeout(function(){
startResult_dt20 = setInterval(fetchResult_dt20, 1000);
},1000);
console.log('backend casino_dt20_result error : ' + error);
}
}

$(document).on('click', '.dotOne_dt20', function(e){
casinoResMod_dt20(e.target.id);
$('#casResModaldt').show();
$('.casDetResLoaddt').css({display: 'flex'});
$('.resCasCondt').hide();
});

$(document).on('click', '.dotTwo_dt20', function(e){
casinoResMod_dt20(e.target.id);
$('#casResModaldt').show();
$('.casDetResLoaddt').css({display: 'flex'});
$('.resCasCondt').hide();
});

async function casinoResMod_dt20(mid) {
$('#record_no_dt').hide();
var midSplit = mid.split('_')[1];
$('#img1dt').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
$('#img2dt').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/1.jpg');
try {
const getData = await axios.get('/server2/casinoDetailsResult/'+midSplit);
const resData = await getData.data;
if(resData.data !== undefined){
//console.log(resData.data);
const allcard = resData.data[0].cards.toUpperCase().split(',');
const desc = resData.data[0].desc.split('*');
//console.log(allcard);
var card1 = allcard[0].split('');
var card1set = card1[1] + card1[2];
var card2 = allcard[1].split('');
var card2set = card2[1] + card2[2];
var winnerCard = desc[0].split('|');
var dragonCard = desc[1].split('|');
var tigerCard = desc[2].split('|');
var cardColour = null;
var cardColour2 = null;

if(card1set === 'HH' || card1set === 'DD' || card1set === '0H' || card1set === '0D'){
cardColour = 'red';
}
else{
cardColour = 'black'; 
}

if(card2set === 'HH' || card2set === 'DD' || card2set === '0H' || card2set === '0D'){
cardColour2 = 'red';
}
else{
cardColour2 = 'black'; 
}

//console.log('cardColour1 - ' + cardColour +' cardColour2 - ' + cardColour2);

$('#img1dt').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[0]+'.jpg');
$('#img2dt').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+allcard[1]+'.jpg');
$('#winCasdt').text(winnerCard[0] +' | '+ winnerCard[1]);
$('#dragonResdt').text(cardColour +' | '+ dragonCard[1] +' | '+ dragonCard[2]);
$('#tigerResdt').text(cardColour2 +' | '+ tigerCard[1] +' | '+ tigerCard[2]);
$('#winCasMiddt').text(resData.data[0].mid).show();
$('.casDetResLoaddt').css({display: 'none'});
$('.resCasCondt').show();
}
else{
$('#winCasMiddt').hide();
$('.casDetResLoaddt').hide();
$('.resCasCondt').hide();
$('#record_no_dt').show();  
}
} catch (error) {
console.log('backend casino_dt20_details_result error : ' + error);
}
}

export { startResult_dt20, runresult_dt20, casinoResMod_dt20 };