import axios from 'axios';
import $ from 'jquery';

var allTeamOver = [];

async function overPls(eventid){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamOver.length > 0){
//console.log(allTeamOver);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'over').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamOver.length; p++){
if(filterData[i].team === allTeamOver[p].runner && allTeamOver[p].sec === 'over'){
$('#teamPrf_'+allTeamOver[p].mid+allTeamOver[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend over fancy pls error : ' + error);
}
}

export { overPls, allTeamOver };