import axios from 'axios';
import $ from 'jquery';

var allTeamFancy1 = [];

async function fancy1Pls(eventid, sport){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamFancy1.length > 0){
//console.log(allTeamFancy1);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'fancy1').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamFancy1.length; p++){
if(filterData[i].team === allTeamFancy1[p].runner && allTeamFancy1[p].sec === 'fancy1'){
$('#teamPrf_'+allTeamFancy1[p].mid+allTeamFancy1[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend fancy1 pls error : ' + error);
}
}

export { fancy1Pls, allTeamFancy1 };