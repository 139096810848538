import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalContext } from '../../context/AppContext';
import StatusAlert from '../StatusAlert';

const usersList_api = '/server2/users-list';
const userActive_api = '/server2/active-user';
const userBanking_api = '/server2/user_bankingData';

const Commision = () => {

const { userActive, userList, activeUser, getUserLists, userBanking } = GlobalContext();
const [commData, setCommData] = useState([]);
const [currUsers, setCurrUsers] = useState([]);
const [allTurnOver, setAllTurnOver] = useState([]);
const [currMenuComm, setCurrMenuComm] = useState('fancy');
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const settleComm = async(id,role,selections) => {
var comm_type = null;
var user = $('#comm_user_'+id).text();
var turn_over = $('#comm_turnAmt_'+id).text().replaceAll(',', '');
var comm_amount = $('#comm_amt_'+id).text().replaceAll(',', '');
var currentComm = $('#currentComm_'+id).text();
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
var currSec = currMenuComm;

//alert(user +' -- '+ role +' --/ '+ turn_over +' --/ '+ comm_amount +' --/ '+ currentComm +' -- '+ currSec);
//console.log(turn_over +' -- '+ comm_amount);
//return false;

if(selections === 'settle'){
if(comm_amount > 0){
comm_type = true; 
}
else if(comm_amount < 0){
comm_type = false;    
}
}
else{
comm_type = 'reject';    
}

if(turn_over === 0 || turn_over === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('currently no turn over');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(comm_amount === 0 || comm_amount === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('currently no commision');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/commision_history', {
id: id,
comm_type: comm_type,
user: user,
turn_over: turn_over,
comm_amount: comm_amount,
currentComm: currentComm,
start_date: start_date,
end_date: end_date,
currSec: currSec,
role: role
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('commision settled successfully');
setTimeout(async function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
},1000);
return false;   
}else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend settle-commision-api error : ' + error);
}
}
}

const searchUsers = (name) => {
setCurrUsers(userList.filter((e) => e.user_role.trim() !== 'demo_role' && e.customer.includes(name)));
}

useEffect(() => {
const fetchComm = async() => {
var admin = userActive.length > 0 ? userActive[0].customer : null;
var admin_role = userActive.length > 0 ? userActive[0].user_role : null;
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();

try {
const sendData = await axios.post('/server2/user_commision', {
admin: admin,
admin_role: admin_role,
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
if(resData.length > 0){
setCommData(resData); 
}
else{
setCommData([]); 
}
} catch (error) {
console.log('backend commision-api error : ' + error);
}

}

fetchComm();
},[userActive, startDate, endDate]);

useEffect(() => {
if(userList.length > 0){
setCurrUsers(userList.filter((e) => e.user_role !== 'demo_role'));
}

$(document).on('click', '.commisionMenuUl li', function(){
$(this).addClass('active').siblings().removeClass('active');
});
},[userList]);

useEffect(() => {
var allPushTurn = [];
if(currUsers.length > 0){
currUsers.forEach((f) => {
//console.log(f.user_role +' -- '+ f.customer);
var turnOvercustomer = '';
var turnOverAgent = '';
var turnOverMaster = '';
var turnOverSuper = '';
var turnOverSub = '';

if(f.user_role === 'customer_role'){
if(currMenuComm === 'fancy'){
turnOvercustomer = commData.filter((e) => (e.customer === f.customer && (e.customer_commision !== 'settle' && e.customer_commision !== 'rejected')) && (e.sec === 'fancy' || e.sec === 'over' || e.sec === 'oddeven' || e.sec === 'meter' || e.sec === 'line' || e.sec === 'khado' || e.sec === 'ball' || e.sec === 'fancy1')).reduce((n, m) => {
const { profit, loss, customer, win_status} = m;
const item = n.find((o) => o.customer === customer);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({customer, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'sportsbook'){
turnOvercustomer = commData.filter((e) => (e.customer === f.customer && (e.customer_commision !== 'settle' && e.customer_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((!e.sec.match('bookmaker')) && (!e.sec.match('bm')) && e.sec !== 'fancy' && e.sec !== 'over' && e.sec !== 'oddeven' && e.sec !== 'meter' && e.sec !== 'line' && e.sec !== 'khado' && e.sec !== 'ball' && e.sec !== 'fancy1')).reduce((n, m) => {
const { profit, loss, customer, win_status} = m;
const item = n.find((o) => o.customer === customer);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({customer, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'bookmaker'){
turnOvercustomer = commData.filter((e) => (e.customer === f.customer && (e.customer_commision !== 'settle' && e.customer_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((e.sec.match('bookmaker')) || (e.sec.match('bm')))).reduce((n, m) => {
const { profit, loss, customer, win_status} = m;
const item = n.find((o) => o.customer === customer);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({customer, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'casino'){
turnOvercustomer = commData.filter((e) => e.customer === f.customer && (e.customer_commision !== 'settle' && e.customer_commision !== 'rejected') && e.sport === 'casino').reduce((n, m) => {
const { profit, loss, customer, win_status} = m;
const item = n.find((o) => o.customer === customer);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({customer, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'virtual'){
turnOvercustomer = commData.filter((e) => e.customer === f.customer && (e.customer_commision !== 'settle' && e.customer_commision !== 'rejected') && e.sport === 'virtual casino').reduce((n, m) => {
const { profit, loss, customer, win_status} = m;
const item = n.find((o) => o.customer === customer);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({customer, finalBal});

return n;
}, []);
}
}
else if(f.user_role === 'agent_role'){
if(currMenuComm === 'fancy'){
turnOverAgent = commData.filter((e) => e.agent === f.customer && (e.agent_commision !== 'settle' && e.agent_commision !== 'rejected') && (e.sec === 'fancy' || e.sec === 'over' || e.sec === 'oddeven' || e.sec === 'meter' || e.sec === 'line' || e.sec === 'khado' || e.sec === 'ball' || e.sec === 'fancy1')).reduce((n, m) => {
const { profit, loss, agent, win_status} = m;
const item = n.find((o) => o.agent === agent);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({agent, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'sportsbook'){
turnOverAgent = commData.filter((e) => (e.agent === f.customer && (e.agent_commision !== 'settle' && e.agent_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((!e.sec.match('bookmaker')) && (!e.sec.match('bm')) && e.sec !== 'fancy' && e.sec !== 'over' && e.sec !== 'oddeven' && e.sec !== 'meter' && e.sec !== 'line' && e.sec !== 'khado' && e.sec !== 'ball' && e.sec !== 'fancy1')).reduce((n, m) => {
const { profit, loss, agent, win_status} = m;
const item = n.find((o) => o.agent === agent);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({agent, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'bookmaker'){
turnOverAgent = commData.filter((e) => (e.agent === f.customer && (e.agent_commision !== 'settle' && e.agent_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((e.sec.match('bookmaker')) || (e.sec.match('bm')))).reduce((n, m) => {
const { profit, loss, agent, win_status} = m;
const item = n.find((o) => o.agent === agent);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({agent, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'casino'){
turnOverAgent = commData.filter((e) => e.agent === f.customer && (e.agent_commision !== 'settle' && e.agent_commision !== 'rejected') && e.sport === 'casino').reduce((n, m) => {
const { profit, loss, agent, win_status} = m;
const item = n.find((o) => o.agent === agent);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({agent, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'virtual'){
turnOverAgent = commData.filter((e) => e.agent === f.customer && (e.agent_commision !== 'settle' && e.agent_commision !== 'rejected') && e.sport === 'virtual casino').reduce((n, m) => {
const { profit, loss, agent, win_status} = m;
const item = n.find((o) => o.agent === agent);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({agent, finalBal});

return n;
}, []);
}
}
else if(f.user_role === 'master_role'){
if(currMenuComm === 'fancy'){
turnOverMaster = commData.filter((e) => e.master === f.customer && (e.master_commision !== 'settle' && e.master_commision !== 'rejected') && (e.sec === 'fancy' || e.sec === 'over' || e.sec === 'oddeven' || e.sec === 'meter' || e.sec === 'line' || e.sec === 'khado' || e.sec === 'ball' || e.sec === 'fancy1')).reduce((n, m) => {
const { profit, loss, master, win_status} = m;
const item = n.find((o) => o.master === master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'sportsbook'){
turnOverMaster = commData.filter((e) => (e.master === f.customer && (e.master_commision !== 'settle' && e.master_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((!e.sec.match('bookmaker')) && (!e.sec.match('bm')) && e.sec !== 'fancy' && e.sec !== 'over' && e.sec !== 'oddeven' && e.sec !== 'meter' && e.sec !== 'line' && e.sec !== 'khado' && e.sec !== 'ball' && e.sec !== 'fancy1')).reduce((n, m) => {
const { profit, loss, master, win_status} = m;
const item = n.find((o) => o.master === master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'bookmaker'){
turnOverMaster = commData.filter((e) => (e.master === f.customer && (e.master_commision !== 'settle' && e.master_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((e.sec.match('bookmaker')) || (e.sec.match('bm')))).reduce((n, m) => {
const { profit, loss, master, win_status} = m;
const item = n.find((o) => o.master === master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'casino'){
turnOverMaster = commData.filter((e) => e.master === f.customer && (e.master_commision !== 'settle' && e.master_commision !== 'rejected') && e.sport === 'casino').reduce((n, m) => {
const { profit, loss, master, win_status} = m;
const item = n.find((o) => o.master === master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'virtual'){
turnOverMaster = commData.filter((e) => e.master === f.customer && (e.master_commision !== 'settle' && e.master_commision !== 'rejected') && e.sport === 'virtual casino').reduce((n, m) => {
const { profit, loss, master, win_status} = m;
const item = n.find((o) => o.master === master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({master, finalBal});

return n;
}, []);
}
}
else if(f.user_role === 'super_role'){
if(currMenuComm === 'fancy'){
turnOverSuper = commData.filter((e) => e.super_master === f.customer && (e.super_commision !== 'settle' && e.super_commision !== 'rejected') && (e.sec === 'fancy' || e.sec === 'over' || e.sec === 'oddeven' || e.sec === 'meter' || e.sec === 'line' || e.sec === 'khado' || e.sec === 'ball' || e.sec === 'fancy1')).reduce((n, m) => {
const { profit, loss, super_master, win_status} = m;
const item = n.find((o) => o.super_master === super_master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({super_master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'sportsbook'){
turnOverSuper = commData.filter((e) => (e.super_master === f.customer && (e.super_commision !== 'settle' && e.super_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((!e.sec.match('bookmaker')) && (!e.sec.match('bm')) && e.sec !== 'fancy' && e.sec !== 'over' && e.sec !== 'oddeven' && e.sec !== 'meter' && e.sec !== 'line' && e.sec !== 'khado' && e.sec !== 'ball' && e.sec !== 'fancy1')).reduce((n, m) => {
const { profit, loss, super_master, win_status} = m;
const item = n.find((o) => o.super_master === super_master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({super_master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'bookmaker'){
turnOverSuper = commData.filter((e) => (e.super_master === f.customer && (e.super_commision !== 'settle' && e.super_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((e.sec.match('bookmaker')) || (e.sec.match('bm')))).reduce((n, m) => {
const { profit, loss, super_master, win_status} = m;
const item = n.find((o) => o.super_master === super_master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({super_master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'casino'){
turnOverSuper = commData.filter((e) => e.super_master === f.customer && (e.super_commision !== 'settle' && e.super_commision !== 'rejected') && e.sport === 'casino').reduce((n, m) => {
const { profit, loss, super_master, win_status} = m;
const item = n.find((o) => o.super_master === super_master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({super_master, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'virtual'){
turnOverSuper = commData.filter((e) => e.super_master === f.customer && (e.super_commision !== 'settle' && e.super_commision !== 'rejected') && e.sport === 'virtual casino').reduce((n, m) => {
const { profit, loss, super_master, win_status} = m;
const item = n.find((o) => o.super_master === super_master);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({super_master, finalBal});

return n;
}, []);
}
}
else if(f.user_role === 'subadmin_role'){
if(currMenuComm === 'fancy'){
turnOverSub = commData.filter((e) => e.sub_admin === f.customer && (e.sub_commision !== 'settle' && e.sub_commision !== 'rejected') && (e.sec === 'fancy' || e.sec === 'over' || e.sec === 'oddeven' || e.sec === 'meter' || e.sec === 'line' || e.sec === 'khado' || e.sec === 'ball' || e.sec === 'fancy1')).reduce((n, m) => {
const { profit, loss, sub_admin, win_status} = m;
const item = n.find((o) => o.sub_admin === sub_admin);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({sub_admin, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'sportsbook'){
turnOverSub = commData.filter((e) => (e.sub_admin === f.customer && (e.sub_commision !== 'settle' && e.sub_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((!e.sec.match('bookmaker')) && (!e.sec.match('bm')) && e.sec !== 'fancy' && e.sec !== 'over' && e.sec !== 'oddeven' && e.sec !== 'meter' && e.sec !== 'line' && e.sec !== 'khado' && e.sec !== 'ball' && e.sec !== 'fancy1')).reduce((n, m) => {
const { profit, loss, sub_admin, win_status} = m;
const item = n.find((o) => o.sub_admin === sub_admin);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({sub_admin, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'bookmaker'){
turnOverSub = commData.filter((e) => (e.sub_admin === f.customer && (e.sub_commision !== 'settle' && e.sub_commision !== 'rejected') && e.sport !== 'casino' && e.sport !== 'virtual casino') && ((e.sec.match('bookmaker')) || (e.sec.match('bm')))).reduce((n, m) => {
const { profit, loss, sub_admin, win_status} = m;
const item = n.find((o) => o.sub_admin === sub_admin);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({sub_admin, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'casino'){
turnOverSub = commData.filter((e) => e.sub_admin === f.customer && (e.sub_commision !== 'settle' && e.sub_commision !== 'rejected') && e.sport === 'casino').reduce((n, m) => {
const { profit, loss, sub_admin, win_status} = m;
const item = n.find((o) => o.sub_admin === sub_admin);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({sub_admin, finalBal});

return n;
}, []);
}
else if(currMenuComm === 'virtual'){
turnOverSub = commData.filter((e) => e.sub_admin === f.customer && (e.sub_commision !== 'settle' && e.sub_commision !== 'rejected') && e.sport === 'virtual casino').reduce((n, m) => {
const { profit, loss, sub_admin, win_status} = m;
const item = n.find((o) => o.sub_admin === sub_admin);
let newprofit = 0;
let newloss = 0;
let finalBal = 0;

if(win_status === 'win'){
newprofit = newprofit + profit;
}
if(win_status === 'lose'){
newloss = newloss + loss;
}

finalBal = parseFloat(newprofit) - parseFloat(newloss);
item ? item.finalBal += finalBal : n.push({sub_admin, finalBal});

return n;
}, []);
}
}

//console.log(turnOvercustomer);
//console.log(turnOverAgent);
//console.log(turnOverMaster);
//console.log(turnOverSuper);
//console.log(turnOverSub);

if(turnOvercustomer.length > 0){
turnOvercustomer.forEach((j) => {
var data = {
customer : j.customer,
turnAmt : j.finalBal
}

const findKey = allPushTurn.findIndex((o) => o.customer === j.customer);
if(findKey === -1){
allPushTurn.push(data);
}
}); 
}
if(turnOverAgent.length > 0){
turnOverAgent.forEach((j) => {
var data = {
customer : j.agent,
turnAmt : j.finalBal
}

const findKey = allPushTurn.findIndex((o) => o.customer === j.agent);
if(findKey === -1){
allPushTurn.push(data);
}
}); 
}
if(turnOverMaster.length > 0){
turnOverMaster.forEach((j) => {
var data = {
customer : j.master,
turnAmt : j.finalBal
}

const findKey = allPushTurn.findIndex((o) => o.customer === j.master);
if(findKey === -1){
allPushTurn.push(data);
}
}); 
}
if(turnOverSuper.length > 0){
turnOverSuper.forEach((j) => {
var data = {
customer : j.super_master,
turnAmt : j.finalBal
}

const findKey = allPushTurn.findIndex((o) => o.customer === j.super_master);
if(findKey === -1){
allPushTurn.push(data);
}
}); 
}
if(turnOverSub.length > 0){
turnOverSub.forEach((j) => {
var data = {
customer : j.sub_admin,
turnAmt : j.finalBal
}

const findKey = allPushTurn.findIndex((o) => o.customer === j.sub_admin);
if(findKey === -1){
allPushTurn.push(data);
}
}); 
}

//console.log(allPushTurn);
setAllTurnOver(allPushTurn);

});
}
},[currUsers, commData, currMenuComm]);

useEffect(() => {
if(records.length > 0){
if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.commBtn').css({background: '#ccc'}).attr('disabled', 'disabled');
}
}
},[userActive, records]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='rowWrapFull'>

<div className='dateDivWrap'>
<div className='dateForm'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>commision</span></div>

<div className='commisionMenu'>
<ul className='commisionMenuUl'>
<li onClick={() => setCurrMenuComm('fancy')} className='active'>fancy</li>
<li onClick={() => setCurrMenuComm('sportsbook')}>sportsbook</li>
<li onClick={() => setCurrMenuComm('bookmaker')}>bookmaker</li>
<li onClick={() => setCurrMenuComm('casino')}>casino</li>
<li onClick={() => setCurrMenuComm('virtual')}>virtual sports</li>
</ul>
</div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable2'>
<thead>
<tr>
<th>username</th>
<th>turn over</th>
<th>curr. commision</th>
<th>commision</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
let turnNew = 0;
turnNew = allTurnOver.length > 0 && allTurnOver.filter((d) => d.customer === data.customer);

return(
<tr key={index}>
<td>
<span className='wrapRoleUsr'>
<span className='roleTxt'>{data.user_role.slice(0, 3)}</span>
<span id={'comm_user_'+data.id} className='inLineBlock ml5'>{data.customer}</span>
</span>
</td>
{
turnNew.length > 0 ?
<td>{turnNew[0].turnAmt > 0 ? <span id={'comm_turnAmt_'+data.id} className='successTxt'>{parseFloat(turnNew[0].turnAmt.toFixed(2)).toLocaleString()}</span> : <span id={'comm_turnAmt_'+data.id} className='dangerTxt'>{parseFloat(turnNew[0].turnAmt.toFixed(2)).toLocaleString()}</span>}</td>
:
<td>0</td>
}
{
turnNew.length > 0 ?
<td>
{
turnNew[0].turnAmt > 0 ? 
<span id={'comm_amt_'+data.id} className='successTxt'>
{
(parseFloat((turnNew[0].turnAmt * (currMenuComm === 'fancy' ? 
data.fancy : currMenuComm === 'virtual' ? data.virtualsport : currMenuComm === 'sportsbook' ?
data.sportsbook : currMenuComm === 'bookmaker' ? data.bookmaker : currMenuComm === 'casino' ?
data.casino : 0)) / 100).toFixed(2)).toLocaleString()
}
</span> : 
<span id={'comm_amt_'+data.id} className='dangerTxt'>
{
(parseFloat((turnNew[0].turnAmt * (currMenuComm === 'fancy' ? 
data.fancy : currMenuComm === 'virtual' ? data.virtualsport : currMenuComm === 'sportsbook' ?
data.sportsbook : currMenuComm === 'bookmaker' ? data.bookmaker : currMenuComm === 'casino' ?
data.casino : 0)) / 100).toFixed(2)).toLocaleString()
}
</span>
}
</td>
:
<td>0</td>
}
<td>
<span id={'currentComm_'+data.id}>
{
currMenuComm === 'fancy' ? 
data.fancy : currMenuComm === 'virtual' ? data.virtualsport : currMenuComm === 'sportsbook' ?
data.sportsbook : currMenuComm === 'bookmaker' ? data.bookmaker : currMenuComm === 'casino' ?
data.casino : 0
}
</span>
</td>
<td>
<button onClick={() => settleComm(data.id,data.user_role,'settle')} className='commBtn success'>settle</button>
<button onClick={() => settleComm(data.id,data.user_role,'reject')} className='commBtn danger'>reject</button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Commision;
