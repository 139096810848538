import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';

const CreditModal = () => {

const { userActive, getUserLists } = GlobalContext();

const closeCredit = () => {
$('#creditModal').hide();
$('.clearCredFrm').val('');
}

const submitCredit = async() => {
var id = $('#creditId').val();
var newCredit = $('#newCredit').val();
var newCreditPass = $('#newCreditPass').val();

if(newCredit < 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new credit ref. required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newCreditPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/creditRef_update', {
id: id,
newCredit: newCredit,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('credit ref. updated');
setTimeout(function(){
$('#msgAlert').hide();
getUserLists(usersList_api);
$('#creditModal').hide();
$('.clearCredFrm').val('');
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend credit-ref-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='creditModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>edit credit ref -</span> <span id='userEditM'></span> <i className='fa fa-close right' onClick={() => closeCredit()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>old credit ref.</label></div>
<div><span id='oldCreditRef'></span></div>
<input className='clearCredFrm' type='hidden' id='creditId' />
</div>
<div className='formControls flexControl'>
<div><label>new credit ref.</label></div>
<div><input className='clearCredFrm' type='number' name='newCredit' id='newCredit' placeholder='new credit refrence...' /></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearCredFrm' type='password' name='newCreditPass' id='newCreditPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt2'>
<button className='submitBtn' onClick={() => submitCredit()}>submit</button>
<button onClick={() => closeCredit()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default CreditModal;
