import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const userActive_api = '/server2/active-user';
const userBanking_api = '/server2/user_bankingData';

const SelfDepo = () => {

const { userActive, activeUser, userBanking } = GlobalContext();

const addSelfBal = async() => {
var bal = $('#newBalSelf').val();
var currBal = userActive.length > 0 ? userActive[0].total_balance : 0;
var user = userActive.length > 0 ? userActive[0].customer : null;
var userRole = userActive.length > 0 ? userActive[0].user_role : null;
if(bal === '' || bal === '0'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new bal required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(userRole !== 'admin_role'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('not allowed. only admin allowed');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false; 
}
else {
try {
const sendData = await axios.post('/server2/self_depositBal', {
user: user,
bal: bal,
currBal: currBal
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('balance added successfully');
setTimeout(function(){
activeUser(userActive_api);
userBanking(userBanking_api);
$('#newBalSelf').val(0);
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend self-add-bal error : ' + error);
}
}
}

const checkNewBal = (bal) => {
if(bal <= 0){
$('#newBalSelf').val(0); 
}
}

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='selfDepoBox'>
<div className='profilePage'>
<div className='headerProfile'><span>self deposit</span></div>

<div className='stateBody'>
{
userActive.length > 0 &&
<div className='depoSelfForm'>
<div className='depoForm'>
<label>avail. bal :</label>
<span>{parseFloat(userActive[0].total_balance.toFixed(2)).toLocaleString()}</span>
</div>

<div className='depoForm'>
<label>add bal :</label>
<input type='number' name='newBalSelf' id='newBalSelf' placeholder='enter new balance...' onChange={(e) => checkNewBal(e.target.value)} />
</div>

<div className='depoFormButton'>
<button onClick={addSelfBal}>add now</button>
</div>
</div>
}
</div>

</div>
</div>

</div>
</div>
</div>
</>
)
}

export default SelfDepo;
