import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';
const allUsers_api = '/server2/users-list-all';

const Status = () => {

const { userActive, getUserLists, userAll } = GlobalContext();

const closeStatus = () =>{
$('#statusModal').hide();
$('.clearStatusFrm').val('');
$('select').prop('selectedIndex', 0);
}

const submitStatus = async() => {
var id = $('#statusId').val();
var user = $('#cuurStatusUser').text();
var userRole = $('#statusUserRole').text();
var status = $('#statusSel').val();
var newstatusPass = $('#newstatusPass').val();

//alert(user +' -- '+ userRole);
//return false;

if(status === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new status required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newstatusPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/update_userStatus', {
id: id,
user: user,
userRole: userRole,
status: status
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('status changed successfully');
setTimeout(function(){
$('#msgAlert').hide();
getUserLists(usersList_api);
userAll(allUsers_api);
$('#statusModal').hide();
$('.clearStatusFrm').val('');
$('select').prop('selectedIndex', 0);
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend change-status-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='statusModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>change status </span> <i className='fa fa-close right' onClick={() => closeStatus()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>username</label></div>
<div>
<span className='roleTxt' id='statusUserRole'></span> 
<span id='cuurStatusUser'></span>
<input className='clearStatusFrm' type='hidden' id='statusId' />
</div>
</div>
<div className='formControls'>
<div><label>curr. status</label></div>
<div>
<span id='statusUser'></span> 
</div>
</div>
<div className='formControls'>
<div><label>select status</label></div>
<div>
<select id='statusSel'>
<option value='null'>--select one status--</option>
<option value='active'>active</option>
<option value='suspended'>suspended</option>
<option value='locked'>locked</option>
</select>
</div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearStatusFrm' type='password' name='newstatusPass' id='newstatusPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt2'>
<button onClick={() => submitStatus()}>submit</button>
<button onClick={() => closeStatus()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Status;
