import axios from 'axios';
import $ from 'jquery';

var allTeamKhado = [];

async function khadoPls(eventid){
try {
const getData = await axios.get('/server2/fancy_exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamKhado.length > 0){
//console.log(allTeamKhado);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'khado').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

// console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamKhado.length; p++){
if(filterData[i].team === allTeamKhado[p].runner && allTeamKhado[p].sec === 'khado'){
$('#teamPrf_'+allTeamKhado[p].mid+allTeamKhado[p].sid).html(filterData[i].loss).css({color: '#44b10a'});
}
}
}
}
}
}
} catch(error){
console.log('backend khado fancy pls error : ' + error);
}
}

export { khadoPls, allTeamKhado };