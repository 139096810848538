import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';
const allUsers_api = '/server2/users-list-all';
const removeUsersList_api = '/server2/remove_usersList';

const RestoreUser = () => {

const { getUserLists, userAll, getRemoveUsrList } = GlobalContext();

const closeRestore = () => {
$('#restoreModal').hide();
$('.clearRestore').val('');
}

const confirmRestore = async() => {
var id = $('#restoreUserId').val();
var user = $('#userRestore').text();
var role = $('#roleRestore').text();
//alert(id +' -- '+ user +' -- '+ role);

try {
const sendData = await axios.post('/server2/restore_users', {
id: id,
user: user,
role: role
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('user restored successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#restoreModal').hide();
$('.clearRestore').val('');
getUserLists(usersList_api);
userAll(allUsers_api);
getRemoveUsrList(removeUsersList_api);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend restore-users-api error : ' + error);
}
}

return (
<>
<div className='modalsSm' id='restoreModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>restore user - </span> <span className='roleTxt' id='roleRestore'></span> <span id='userRestore'></span> <i className='fa fa-close right' onClick={() => closeRestore()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/restore2.png' alt='restore2' />
<span>are you sure? you want to restore this user?</span>
<input type='hidden' name='restoreUserId' className='clearRestore' id='restoreUserId' />
<button onClick={() => confirmRestore()}>yes</button>
<button onClick={() => closeRestore()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default RestoreUser;
