import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';

const TotalBal = ({check}) => {

const { userActive, total_bal_Users, useExpo } = GlobalContext();
const [totalExpo, setTotalExpo] = useState(0);
const [refPl, setRefPl] = useState(0);

useEffect(() => {
if(useExpo.length > 0){
let total_expo = useExpo.reduce((c, b) => {
c = c + b.exposure;
return c;
},0);
setTotalExpo(total_expo);
}
},[useExpo]);

useEffect(() => {
let totalAvail = parseFloat((userActive.length > 0 ? userActive[0].total_balance : 0) + (total_bal_Users.length > 0 ? total_bal_Users[0].totalBal : 0));
let openingBal = userActive.length > 0 ? userActive[0].openingBalance : 0;
//console.log(totalAvail +' -- '+ openingBal);
if(totalAvail >= openingBal){
setRefPl(totalAvail - openingBal);
}else{
setRefPl(totalAvail - openingBal);    
}
},[userActive, total_bal_Users]);

useEffect(() => {
if(Math.abs(refPl) > 0){
let totalAvail = parseFloat((userActive.length > 0 ? userActive[0].total_balance : 0) + (total_bal_Users.length > 0 ? total_bal_Users[0].totalBal : 0));
let openingBal = userActive.length > 0 ? userActive[0].openingBalance : 0;
//console.log(totalAvail +' -- '+ openingBal +' -- '+ check);
//console.log(refPl);
if(check){
if(totalAvail >= openingBal){
//console.log(((totalAvail - openingBal) * (userActive.length > 0 && userActive[0].partnership)) / 100);
$('#refpl').addClass('successTxt').text(parseFloat((((totalAvail - openingBal) * (userActive.length > 0 && userActive[0].partnership)) / 100).toFixed(2)).toLocaleString());
}else{
$('#refpl').addClass('dangerTxt').text((parseFloat(((totalAvail - openingBal) * (userActive.length > 0 && userActive[0].partnership)) / 100).toFixed(2)).toLocaleString());  
}
}
else{
if(totalAvail >= openingBal){
//console.log('ok2');
$('#refpl').addClass('successTxt').text(parseFloat((totalAvail - openingBal).toFixed(2)).toLocaleString());  
}else{
$('#refpl').addClass('dangerTxt').text(parseFloat((totalAvail - openingBal).toFixed(2)).toLocaleString());  
}
}
}
},[userActive, total_bal_Users, check, refPl]);

return (
<>
<div className='firstColDiv'>
<div className='content2'>
<div className='rows6'>
<div className='col'>
<span>total balance</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} {total_bal_Users.length > 0 ? (total_bal_Users[0].totalBal !== null ? parseFloat(total_bal_Users[0].totalBal.toFixed(2)).toLocaleString() : 0) : 0}</span>
</div>
<div className='col'>
<span>total exposure</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} <span className='dangerTxt'>({parseFloat(totalExpo.toFixed(2)).toLocaleString()})</span></span>
</div>
<div className='col'>
<span>available balance</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} {parseFloat(((total_bal_Users.length > 0 ? total_bal_Users[0].totalBal : 0) - totalExpo).toFixed(2)).toLocaleString()}</span>
</div>
<div className='col'>
<span>balance</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} {userActive.length > 0 ? parseFloat(userActive[0].total_balance.toFixed(2)).toLocaleString() : 0}</span>
</div>
<div className='col'>
<span>total aval. bal.</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} {parseFloat(((userActive.length > 0 ? userActive[0].total_balance : 0) + (total_bal_Users.length > 0 ? total_bal_Users[0].totalBal : 0)).toFixed(2)).toLocaleString()}</span>
</div>
<div className='col'>
<span>upline p/l</span>
<span>{userActive.length > 0 ? userActive[0].currency : null} {
refPl > 0 ? <span id='refpl' className='successTxt'>{parseFloat(refPl.toFixed(2)).toLocaleString()}</span> :
refPl < 0 ? <span id='refpl' className='dangerTxt'>{parseFloat(refPl.toFixed(2)).toLocaleString()}</span> : 0
}
</span>
</div>
</div>
</div>
</div>
</>
)
}

export default TotalBal;
