import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const PageSideMenu2 = () => {

const { userActive } = GlobalContext();
const Navigate = useNavigate();

const userProfile2 = () => {
Navigate('/my_account');
}

const userStatement2 = () => {
Navigate('/my_account/user_statement', {state:{name:userActive[0].customer, role:userActive[0].user_role}});
}

const userActivity2 = () => {
Navigate('/my_account/user_activity', {state:{name:userActive[0].customer, role:userActive[0].user_role}});
}

useEffect(() => {
$(document).on('click', '.profileUl li', function(){
$(this).addClass('active').siblings().removeClass('active');
});

if(window.location.pathname === '/my_account'){
$('.1A').addClass('active');
}
if(window.location.pathname === '/my_account/user_statement'){
$('.4A').addClass('active');
}
if(window.location.pathname === '/my_account/user_activity'){
$('.5A').addClass('active');
}

},[]);

return (
<>
<div className='sideUlPage'> 
<div><span>my account</span></div>
<ul className='profileUl'>
<li onClick={() => userProfile2()} className='1A'>my profile</li>
<li onClick={() => userStatement2()} className='4A'>account statement</li>
<li onClick={() => userActivity2()} className='5A'>activity log</li>
</ul>
</div>
</>
)
}

export default PageSideMenu2;
