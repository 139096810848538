import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MarketBets from '../../../../allBets/MarketBets';
import { check_t201day, start_t201day, pls_t201day } from './js/refresh';
import { startResult_t201day, runresult_t201day } from './js/result';
import ResultT20 from '../modals/ResultT20';
import TeenRules from '../modals/TeenRules';

const T201Day = () => {

const [load_t201day, setLoadT201Day] = useState(true);
const [curr_t201day, setCurrT201day] = useState([]);

const openteen1DayRules = () => {
$('#teenModal').show();
}

useEffect(() => {
async function fetchData_t201day() {
try {
const getData = await axios.get('/server2/teenpattiT20onedayData');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadT201Day(false);
setCurrT201day(resData);
}
else{
setLoadT201Day(true);
setCurrT201day([]);
}
} catch (error) {
console.log('backend casino_t20_oneday_data error : ' + error);
}
}

fetchData_t201day();
},[]);

useEffect(() => {
check_t201day();
runresult_t201day();
pls_t201day(curr_t201day.data !== undefined ? curr_t201day.data.t1[0].marketid : 0,'t20_1day');
return () => {
clearInterval(start_t201day);
clearInterval(startResult_t201day);
}
},[curr_t201day]);

if(load_t201day){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
curr_t201day.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti 1Day</span>
<span className='casGamerule' onClick={openteen1DayRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_t201day'>{curr_t201day.data.t1[0].marketid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>player a</span>
<div className='teamLiveCards'>
<img id='card1_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>player b</span>
<div className='teamLiveCards'>
<img id='card4_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_t201day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t201day.data.t1[1].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_t201day'>{curr_t201day.data.t1[0].lasttime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3031' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh2'><span>back</span></th>
<th className='layTh'><span>lay</span></th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t201day.data.t1[0].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t201day_'+curr_t201day.data.t1[0].sectionid}>0.00</span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t201day_'+curr_t201day.data.t1[0].sectionid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_t201day_'+curr_t201day.data.t1[0].sectionid}>
<span className='casRate' id={'casRateBack_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].b1}</span>
<span className='casSize' id={'casSizeBack_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_t201day_'+curr_t201day.data.t1[0].sectionid}>
<span className='casRate' id={'casRateLay_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].l1}</span>
<span className='casSize' id={'casSizeLay_t201day_'+curr_t201day.data.t1[0].sectionid}>{curr_t201day.data.t1[0].ls1}</span>
</button>
</td>
</tr>

<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t201day.data.t1[1].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t201day_'+curr_t201day.data.t1[1].sectionid}>0.00</span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t201day_'+curr_t201day.data.t1[1].sectionid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_t201day_'+curr_t201day.data.t1[1].sectionid}>
<span className='casRate' id={'casRateBack_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].b1}</span>
<span className='casSize' id={'casSizeBack_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_t201day_'+curr_t201day.data.t1[1].sectionid}>
<span className='casRate' id={'casRateLay_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].l1}</span>
<span className='casSize' id={'casSizeLay_t201day_'+curr_t201day.data.t1[1].sectionid}>{curr_t201day.data.t1[1].ls1}</span>
</button>
</td>
</tr>
</tbody>
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_t201day'></div>
</div>

<MarketBets eventid={curr_t201day.data !== undefined ? curr_t201day.data.t1[0].marketid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<ResultT20 />
<TeenRules />
</>
)
}

export default T201Day;
