import React from 'react';
import { GlobalContext } from '../context/AppContext';

const StatusAlert = () => {

const { userActive } = GlobalContext();

const hideStatusAlert = () => {
document.getElementById('statusAlert').style.display = 'none';
}

return (
<>
{
userActive.length > 0 && userActive[0].status !== 'active' &&
<div className='statusAlert' id='statusAlert'>
<span>your account is temporarily {userActive[0].status}!</span>
<span>please contact your service provider...</span>
<i className='fa fa-close statusAlertIco' onClick={hideStatusAlert}></i>
</div>
}
</>
)
}

export default StatusAlert;
