import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const noticeApi = '/server2/notification_Data';

const Notification = () => {

const { allNotice, getNotice } = GlobalContext();

const removeNotice = async(id) => {
try {
const sendData = await axios.post('/server2/notification_remove', {id:id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('message removed successfully');
setTimeout(function(){
$('#msgAlert').hide();
getNotice(noticeApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('backend notification-remove-api error : ' + error) 
}
}

const handleCheck = async(id,allow) => {
var checks = $('#checkItem_'+allow+'_'+id).prop('checked') ? true : false;

try {
const sendData = await axios.post('server2/notificationUpdate', {
id: id,
allow: allow,
checks: checks
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('notification enabled');
setTimeout(function(){
$('#msgAlert').hide();
getNotice(noticeApi);
},1000); 
return false;
}
else if(resData === 'error'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('notification disabled');
setTimeout(function(){
$('#msgAlert').hide();
},1000); 
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);    
}
} catch (error) {
console.log('backend notification-update-api error : ' + error);
}
}

const createNotice = async() => {
var msg = $('#notice_msg').val();

if(msg === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('message required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/notifications', {msg: msg});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('message created successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#notice_msg').val('');
getNotice(noticeApi);
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend create-notification-api error : ' + error);
}
}
}

useEffect(() => {
if(allNotice.length > 0){
for(let i = 0; i < allNotice.length; i++){
if(allNotice[i].adm_allow === 'true'){
$('#checkItem_admin_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].adm_allow !== 'true'){
$('#checkItem_admin_'+allNotice[i].id).prop('checked', false);   
}

if(allNotice[i].sub_allow === 'true'){
$('#checkItem_sub_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].sub_allow !== 'true'){
$('#checkItem_sub_'+allNotice[i].id).prop('checked', false);   
}

if(allNotice[i].sup_allow === 'true'){
$('#checkItem_super_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].sup_allow !== 'true'){
$('#checkItem_super_'+allNotice[i].id).prop('checked', false);   
}

if(allNotice[i].mas_allow === 'true'){
$('#checkItem_master_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].mas_allow !== 'true'){
$('#checkItem_master_'+allNotice[i].id).prop('checked', false);   
}

if(allNotice[i].agn_allow === 'true'){
$('#checkItem_agent_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].agn_allow !== 'true'){
$('#checkItem_agent_'+allNotice[i].id).prop('checked', false);   
}

if(allNotice[i].cus_allow === 'true'){
$('#checkItem_cust_'+allNotice[i].id).prop('checked', true);
}else if(allNotice[i].cus_allow !== 'true'){
$('#checkItem_cust_'+allNotice[i].id).prop('checked', false);   
}

}
}
},[allNotice]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>
    
<div className='profilePage'>
<div className='headerProfile'><span>notification setting</span></div>

<div className='stateBody'>
<div className='formControl_notice'>
<label>message :</label>
<textarea rows='8' placeholder='Enter notification message...' name='notice_msg' id='notice_msg'></textarea>
</div>
<div className='formControl_noticeBtn'>
<button onClick={createNotice}>create notice</button>
</div>
</div>

</div>

<div className='profilePage profilePage2'>
<div className='headerProfile'><span>all notification</span></div>

<div className='stateBody'>
<div className='table-responsive'>
<table className='usersTable NoticeTab'>
<thead>
<tr>
<th>message</th>
<th>admin allow</th>
<th>subadmin allow</th>
<th>super allow</th>
<th>master allow</th>
<th>agent allow</th>
<th>customer allow</th>
<th>created date</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
allNotice.length > 0 ? allNotice.map((data, index) => {
return (
<tr key={index}>
<td>{data.msg}</td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_admin_'+data.id} onChange={() => handleCheck(data.id,'admin')} /></td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_sub_'+data.id} onChange={() => handleCheck(data.id,'sub')} /></td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_super_'+data.id} onChange={() => handleCheck(data.id,'super')} /></td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_master_'+data.id} onChange={() => handleCheck(data.id,'master')} /></td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_agent_'+data.id} onChange={() => handleCheck(data.id,'agent')} /></td>
<td><input type='checkbox' className='noticeCheck' id={'checkItem_cust_'+data.id} onChange={() => handleCheck(data.id,'cust')} /></td>
<td>{data.createDate}</td>
<td>
<button className="tdBtn" onClick={() => removeNotice(data.id)}><img src="/images/trash-bin.png" alt="bank-building" /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>
</div>

</div>

</div>
</div>
</div>
</>
)
}

export default Notification;
