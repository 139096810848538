import React, { useState } from 'react';
import Cricket from './Cricket';
import Soccer from './Soccer';
import Tennis from './Tennis';

const BetSetting = () => {

const [betSetBtn, setBetSetBtn] = useState('cricket');

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>bet setting</span></div>

<div className='betSportMenu'>
<button onClick={() => setBetSetBtn('cricket')}>cricket</button>
<button onClick={() => setBetSetBtn('soccer')}>soccer</button>
<button onClick={() => setBetSetBtn('tennis')}>tennis</button>
</div>

{betSetBtn === 'cricket' ? <Cricket /> : betSetBtn === 'soccer' ? <Soccer /> : <Tennis />}

</div>

</div>
</div>
</div>
</>
)
}

export default BetSetting;
