import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import numFormat from '../../../NumFormat';
import { GlobalContext } from '../../../../../context/AppContext';
import { linePls, allTeamLine } from './js/linePls';


const Line = () => {

const location = useLocation();
const { cricMarket } = GlobalContext();
const [currData, setCurrData] = useState([]);

useEffect(() => {
if(cricMarket.length !== 0){
setCurrData(cricMarket.data !== undefined && cricMarket.data.filter((e) => e.gtype === "fancy2"));
}
},[cricMarket]);

useEffect(() => {
if(currData.length > 0){
linePls((location.state !== null ? location.state.eventid : 0));
}
},[currData, location.state]);

return (
<>
{
currData.length > 0 && 
<table className='oddsTable fancyTab'>
<thead>
<tr>
<th className='minWidth' colSpan='2'>
<span className='min-max diskHide'><span className='minF'>min/max :</span> <span className='minS matchMinFancy'>0-1</span></span>
</th>
<th className='noth' style={{width: '11%'}}>no</th>
<th className='yesth' style={{width: '11%'}}>yes</th>
<th className='minMaxTh mobHide'>Min-Max</th>
</tr>
</thead>
{
currData.map((main,mainIndex) => {
var RunData = {
sec : 'line',
runner: main.mname,
mid: main.mid,
}

const findSid = allTeamLine.findIndex(o => o.mid === main.mid);

if(findSid === -1){
allTeamLine.push(RunData);
}
return (
<tbody key={mainIndex} style={{position: 'relative'}}>

{
currData.length > 0 &&
<tr className='blockTrF' id={'blockTrF_'+main.mid}>
<td colSpan='5'><span id={'blockTrF_txt_'+main.mid}>suspended</span></td>   
</tr>
}

<tr>
<td colSpan='5' className='teamLine' id={'teamSel_'+main.mid}>{main.mname}</td>
</tr>

{
main.section.map((data, index) => {
return (
<tr key={index}>
<td style={{verticalAlign: 'top'}}>
<span className='teamName' style={{marginTop:'5px'}}>{data.nat}</span>
<span className='pls' id={'plsArr_'+data.mid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data.mid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data.mid}></span>
</td>

<td></td>

<td colSpan='2' style={{position: 'relative', padding: '0'}}>
<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_lay_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_back_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay2').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_lay_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back2').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_back_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay3').map((data2, index2) => {
return(
<div className='counts-tdFull lastCount' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_lay_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back3').map((data2, index2) => {
return(
<div className='counts-tdFull lastCount' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+data.mid+data2.sid+data2.tno}>
<span className='price' id={'price_back_'+data.mid+data2.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+data.mid+data2.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='suspendSmall2' id={'suspendFancy_'+data.mid+data.sid}>
<span id={'suspendFancyTxt_'+data.mid+data.sid}>suspended</span>
</div>
</td>

<td style={{verticalAlign: 'top'}} className='minMaxTd mobHide'>
<span className='minMax matchMinFancy'>0-1</span>
</td>

</tr>
)
})
}

</tbody>
)
})
}
</table>
}
</>
)
}

export default Line;
