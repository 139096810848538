import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import MarketBets from '../../../../allBets/MarketBets';
import { check_dt202, start_dt202, pls_dt202, allSid_dt202 } from './js/refresh';
import { startResult_dt202, runresult_dt202 } from './js/result';
import Resultdt from '../modals/Resultdt';
import DtRules from '../modals/DtRules';

const Dt202 = () => {

const [load_dt202, setLoad_dt202] = useState(true);
const [currdt202, setCurrDt202] = useState([]);

const opendt202Rules = () => {
$('#dtModal').show();
}

useEffect(() => {
async function fetchDt202() {
try {
const getData = await axios.get('/server2/dt202_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nat : element.nat
}

const findInd = allSid_dt202.findIndex((o) => o.sid === element.sid && o.nat === element.nat);
if(findInd === -1){
allSid_dt202.push(data);
}
});
setLoad_dt202(false);
setCurrDt202(resData);
}
else{
setLoad_dt202(true);
setCurrDt202([]);
}
} catch (error) {
console.log('backend casino_dt202_data error : ' + error);
}
}

fetchDt202();
},[]);

useEffect(() => {
check_dt202();
runresult_dt202();
pls_dt202(currdt202.data !== undefined ? currdt202.data.t1[0].mid : 0,'dt202');
return () => {
clearInterval(start_dt202);
clearInterval(startResult_dt202);
}
},[currdt202]);

if(load_dt202){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
currdt202.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>DT 20 20 2</span>
<span className='casGamerule' onClick={opendt202Rules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_dt202'>{currdt202.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_dt202' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt202.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_dt202' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt202.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_dt202'>{currdt202.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3059' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon / tiger </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[0].sid}>{currdt202.data.t2[0].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[0].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[0].sid}>{currdt202.data.t2[0].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[0].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[0].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[1].sid}>{currdt202.data.t2[1].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[1].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[1].sid}>{currdt202.data.t2[1].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[1].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[1].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tie / pair </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[2].sid}>{currdt202.data.t2[2].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[2].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[2].sid}>{currdt202.data.t2[2].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[2].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[2].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[3].sid}>{currdt202.data.t2[3].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[3].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[3].sid}>{currdt202.data.t2[3].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[3].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[3].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[4].sid}>{currdt202.data.t2[4].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[4].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[4].sid}>{currdt202.data.t2[4].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[4].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[4].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[5].sid}>{currdt202.data.t2[5].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[5].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[5].sid}>{currdt202.data.t2[5].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[5].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[5].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>d red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[6].sid}>{currdt202.data.t2[6].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[6].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[6].sid}>{currdt202.data.t2[6].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[6].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[6].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[7].sid}>{currdt202.data.t2[7].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[7].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[7].sid}>{currdt202.data.t2[7].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[7].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[7].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>dragon cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[8].sid}>{currdt202.data.t2[8].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[8].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[8].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[9].sid}>{currdt202.data.t2[9].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[9].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[9].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[10].sid}>{currdt202.data.t2[10].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[10].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[10].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[11].sid}>{currdt202.data.t2[11].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[11].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[11].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[12].sid}>{currdt202.data.t2[12].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[12].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[12].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[12].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[13].sid}>{currdt202.data.t2[13].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[13].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[13].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[14].sid}>{currdt202.data.t2[14].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[14].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[14].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[15].sid}>{currdt202.data.t2[15].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[15].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[15].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[16].sid}>{currdt202.data.t2[16].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[16].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[16].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[17].sid}>{currdt202.data.t2[17].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[17].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[17].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[18].sid}>{currdt202.data.t2[18].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[18].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[18].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[18].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[19].sid}>{currdt202.data.t2[19].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[19].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[19].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[19].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[19].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[20].sid}>{currdt202.data.t2[20].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[20].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[20].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[20].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[20].sid}>0</span>
</div>

</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t odd / even </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[21].sid}>{currdt202.data.t2[21].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[21].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[21].sid}>{currdt202.data.t2[21].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[21].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[21].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[22].sid}>{currdt202.data.t2[22].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[22].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[22].sid}>{currdt202.data.t2[22].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[22].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[22].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>t red / black </span></div>
<div className='dtRow'>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[23].sid}>{currdt202.data.t2[23].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[23].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[23].sid}>{currdt202.data.t2[23].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[23].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[23].sid}>0</span>
</div>
<div className='col'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[24].sid}>{currdt202.data.t2[24].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[24].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[24].sid}>{currdt202.data.t2[24].nat}</span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[24].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[24].sid}>0</span>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>tiger cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[25].sid}>{currdt202.data.t2[25].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[25].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[25].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[25].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[25].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[26].sid}>{currdt202.data.t2[26].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[26].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[26].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[26].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[26].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[27].sid}>{currdt202.data.t2[27].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[27].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[27].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[27].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[27].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[28].sid}>{currdt202.data.t2[28].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[28].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[28].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[28].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[28].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[29].sid}>{currdt202.data.t2[29].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[29].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[29].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[29].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[29].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[30].sid}>{currdt202.data.t2[30].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[30].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[30].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[30].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[30].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[31].sid}>{currdt202.data.t2[31].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[31].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[31].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[31].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[31].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[32].sid}>{currdt202.data.t2[32].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[32].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[32].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[32].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[32].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[33].sid}>{currdt202.data.t2[33].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[33].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[33].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[33].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[33].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[34].sid}>{currdt202.data.t2[34].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[34].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[34].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[34].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[34].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[35].sid}>{currdt202.data.t2[35].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[35].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[35].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[35].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[35].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[36].sid}>{currdt202.data.t2[36].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[36].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[36].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[36].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[36].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'dt202_Rate_'+currdt202.data.t2[37].sid}>{currdt202.data.t2[37].rate}</span>
<button id={'dt202_Team_'+currdt202.data.t2[37].sid}>
<span id={'dt202_TeamTx1_'+currdt202.data.t2[37].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'dt202_TeamTx2_'+currdt202.data.t2[37].sid}></span>
</button>
<span className='dtPls' id={'dt202_Pls_'+currdt202.data.t2[37].sid}>0</span>
</div>

</div>
</div>

</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_dt202'></div>
</div>

<MarketBets eventid={currdt202.data !== undefined ? currdt202.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<Resultdt />
<DtRules />
</>
)
}

export default Dt202;
