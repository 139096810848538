import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../context/AppContext';
import numFormat from '../../NumFormat';
import { matchOddsRunner, matchOdds_pls } from './js/refresh';
import $ from 'jquery';

const AllOdds = () => {

const {cricMarket, betSet, cricCompAll} = GlobalContext();
const [currData, setCurrData] = useState([]);
const [availBetSet, setAvailBetSet] = useState([]);
const location = useLocation();

useEffect(() => {
if(cricMarket.length !== 0){
setCurrData(cricMarket);
}
},[cricMarket]);

useEffect(() => {
if(currData.length !== 0){
matchOdds_pls((location.state !== null ? location.state.eventid : 0),'cricket');
}
},[currData, location.state]);

useEffect(() => {
if(betSet.length > 0){
const availData = betSet.filter((e) => cricCompAll.find((f) => parseFloat(e.eventid) === parseFloat(f.comp_id) && parseFloat(f.event_id) === parseFloat(location.state !== null ? location.state.eventid : 0)));
if(availData.length > 0){
const availData2 = betSet.filter((e) => availData.find((f) => e.sec !== f.sec && e.sport === 'cricket'));
setAvailBetSet(availData.concat(availData2));
}
else if(availData.length <= 0){
const availData2 = betSet.filter((e) => e.sport === 'cricket' && e.type === 'default');  
setAvailBetSet(availData2);
}
}
},[cricCompAll, betSet, location.state]);

useEffect(() => {
if(availBetSet.length > 0){
availBetSet.forEach((e) => {
if(e.sec === 'match_odds'){
$('.matchMin').text(e.min_bet +'-'+ e.max_bet); 
}
if(e.sec === 'bookmaker'){
$('.matchMinBook').text(e.min_bet +'-'+ e.max_bet); 
}
if(e.sec === 'fancy'){
$('.matchMinFancy').text(e.min_bet +'-'+ e.max_bet); 
}
});
}
}, [availBetSet]);

return (
<>
{
currData.length !== 0 && cricMarket.data !== undefined && currData.data.filter((e) => e.gtype.trim() === 'match' || e.gtype.trim() === 'match1').map((data, index) => {
//console.log(data);
return (
<div className='marketOdds-wrap' id={'OddsDiv_'+data.mid} key={index}>

<div className='headTitleRow-market'>
<div className='col'>
<div className='oddsTitleNew'>
<div><span>{data.mname.replaceAll('_', ' ')}</span></div> 
<div><i className='fa fa-exclamation-circle'></i></div>
</div>
</div>
<div className='col'>
<div className='gameStatusSec'>
<span>in-play</span>
{
data.iplay === (true || 'true') ?
<img src='/images/bell-green.png' alt='bell-green' />
:
<img src='/images/bell-red.png' alt='bell-red' />
}
</div>
</div>
</div>

{
data.gtype.trim() === 'match' ?
<table className='oddsTable'>
<thead>
{
parseInt(data.ocnt) === (6 || '6') ?
<tr>
<th colSpan='3' className='mobHide'></th>
<th className='diskHide'>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th> 
<th className='backth'>back</th>
<th className='layth'>lay</th>
<th colSpan='2' className='mobHide'>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th>
</tr>
: parseInt(data.ocnt) === (2 || '2') ?
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th> 
<th className='yesth'>back</th>
<th className='noth'>lay</th>
</tr>
:
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th> 
<th className='yesth'>back</th>
</tr>
}
</thead>

<tbody className='blockTbody' id={'blockTbody_'+data.mid}>
<tr>
<td><span id={'blockBodyTxt_'+data.mid}>{data.status}</span></td> 
</tr>
</tbody>

{
data.section.map((data2, index2) => {
var RunData = {
rc: data.rc,
sec : data.mname,
runner: data2.nat,
mid: data2.mid,
sid: data2.sid,
}

const findSid = matchOddsRunner.findIndex(o => o.sid === data2.sid && o.mid === data2.mid);

if(findSid === -1){
matchOddsRunner.push(RunData);
}
return (
<tbody key={index2}>
<tr>
<td>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back3').map((oddsData, index4) => {
return (
<td className='counts-td back3Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back2').map((oddsData, index4) => {
return (
<td className='counts-td back2Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((oddsData, index4) => {
return (
<td className='counts-td back1Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((oddsData, index4) => {
return (
<td className='counts-td lay1Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay2').map((oddsData, index4) => {
return (
<td className='counts-td lay2Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay3').map((oddsData, index4) => {
return (
<td className='counts-td lay3Div' key={index4}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+oddsData.sid+oddsData.tno}>
<span className='price' id={'price_back_'+data.mid+oddsData.sid+oddsData.tno}>{oddsData.odds}</span>
<span className='size' id={'price_size_'+data.mid+oddsData.sid+oddsData.tno}>{numFormat(oddsData.size)}</span>
</button>
</td>
)
})
}

</tr>
</tbody>
)
})
}
</table>
:
<div className='bookmakerWrap'>
<div className='scrollTxt_flat'><div><span>{data.rem}</span></div></div>
<table className='oddsTable bookmaker_Table'>
<thead>
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMinBook'>0-1</span></span>
</th>
<th className='backth_book' colSpan='3'>back</th>
<th className='layth_book' colSpan='3'>lay</th>
</tr>
</thead>

<tbody className='blockTbody' id={'blockTbody_'+data.mid}>
<tr>
<td><span id={'blockBodyTxt_'+data.mid}>{data.status}</span></td>   
</tr>
</tbody>

{
data.section.map((data2, index2) => {
var RunData = {
rc: data.rc,
sec : data.mname,
runner: data2.nat,
mid: data2.mid,
sid: data2.sid,
}

const findSid = matchOddsRunner.findIndex(o => o.sid === data2.sid && o.mid === data2.mid);

if(findSid === -1){
matchOddsRunner.push(RunData);
}
if(data2.odds.length > 2) {
return (
<tbody key={index2}>
<tr>
<td className='teamTd'>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>
<td colSpan='3' className='bookTd1'>
<div className='bookBack'>
{
data2.odds.map((data3, index3) => {
if(data3.otype === 'back'){
return(
<div key={index3}>
{
data3.otype === 'back' && data3.oname === 'back3' &&
<button className='' id={'oddsDiv_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'back' && data3.oname === 'back2' &&
<button className='' id={'oddsDiv_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'back' && data3.oname === 'back1' &&
<button className='bookBackMain' id={'oddsDiv_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}
</div>
)
}
else { return null; }
}) 
}
</div>

<div className='blockTdSingle' id={'blockTdSingle_'+data2.mid+data2.sid}>
<span id={'blockTdSingleTxt_'+data2.mid+data2.sid}>{data2.gstatus}</span>
</div>
</td>

<td colSpan='3' className='bookTd2'>
<div className='bookLay'>
{
data2.odds.map((data3, index3) => {
if(data3.otype === 'lay'){
return(
<div key={index3}>
{
data3.otype === 'lay' && data3.oname === 'lay1' &&
<button className='bookLayMain' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'lay' && data3.oname === 'lay2' &&
<button className='' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'lay' && data3.oname === 'lay3' &&
<button className='' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}
</div>
)
}
else { return null; }
}) 
}
</div>
</td>
</tr>
</tbody>
)
}
else{
return(
<tbody key={index2}>
<tr>  
<td className='teamTd'>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>

<td colSpan='3' className='bookTd1'>
<div className='bookBack'>
<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

{
data2.odds[0].otype === 'back' && data2.odds[0].oname === 'back1' ?
<div>
<button className='bookBackMain' id={'oddsDiv_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>
<span className='price' id={'price_back_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].odds !== 0 ? data2.odds[0].odds : ''}</span>
<span className='size' id={'price_size_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].size !== 0 ? numFormat(data2.odds[0].size) : ''}</span>
</button>
</div>
:
<div>
<button className='bookBackMain' id={'oddsDiv_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>
<span className='price' id={'price_back_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].odds !== 0 ? data2.odds[1].odds : ''}</span>
<span className='size' id={'price_size_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].size !== 0 ? numFormat(data2.odds[1].size) : ''}</span>
</button>
</div>
}
</div>

<div className='blockTdSingle' id={'blockTdSingle_'+data2.mid+data2.sid}>
<span id={'blockTdSingleTxt_'+data2.mid+data2.sid}>{data2.gstatus}</span>
</div>
</td>

<td colSpan='3' className='bookTd2'>
<div className='bookLay'>
{
data2.odds[1].otype === 'lay' && data2.odds[1].oname === 'lay1' ?
<div>
<button className='bookLayMain' id={'oddsDivLay_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>
<span className='price' id={'price_lay_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].odds !== 0 ? data2.odds[1].odds : ''}</span>
<span className='size' id={'price_laysize_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].size !== 0 ? numFormat(data2.odds[1].size) : ''}</span>
</button>
</div>
:
<div>
<button className='bookLayMain' id={'oddsDivLay_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>
<span className='price' id={'price_lay_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].odds !== 0 ? data2.odds[0].odds : ''}</span>
<span className='size' id={'price_laysize_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].size !== 0 ? numFormat(data2.odds[0].size) : ''}</span>
</button>
</div>
}

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>
</div>
</td>
</tr>

</tbody>
)
}
})
}
</table>
</div>
}
</div>
)
})
}
</>
)
}

export default AllOdds;
