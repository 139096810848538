import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';

const Rolling = () => {

const { userActive, getUserLists } = GlobalContext();

const closeRollComm = () => {
$('#RollcommisionModal').hide();
$('.clearCRoll').val('');
}

const updateRolling = async() => {
var id = $('#rollId').val();
var editFancy = $('#editFancy').val();
var editCasino = $('#editCasino').val();
var editSportsBook = $('#editSportsBook').val();
var editBookmaker = $('#editBookmaker').val();
var editVirCas = $('#editVirCas').val();
var editPassRoll = $('#editPassRoll').val();

if(editPassRoll !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/update_rolling', {
id: id,
editFancy: editFancy,
editCasino: editCasino,
editSportsBook: editSportsBook,
editBookmaker: editBookmaker,
editVirCas: editVirCas,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('rolling comm. update successfully');
setTimeout(function(){
$('#msgAlert').hide();
getUserLists(usersList_api);
$('#RollcommisionModal').hide();
$('.clearCRoll').val('');
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend rolling-commision-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='RollcommisionModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>rolling commision - </span> <span id='rollUser'></span> <i className='fa fa-close right' onClick={() => closeRollComm()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls flexControl'>
<div><label>fancy</label></div>
<div>
<input className='clearCRoll' type='hidden' id='rollId' /> 
<input className='clearCRoll' type='number' name='editFancy' id='editFancy' placeholder=' fancy...' />
</div>
</div>
<div className='formControls flexControl'>
<div><label>casino</label></div>
<div><input className='clearCRoll' type='number' name='editCasino' id='editCasino' placeholder=' casino...' /></div>
</div>
<div className='formControls'>
<div><label>sportsbook</label></div>
<div><input className='clearCRoll' type='number' name='editSportsBook' id='editSportsBook' placeholder=' sportsbook...' /></div>
</div>
<div className='formControls'>
<div><label>bookmaker</label></div>
<div><input className='clearCRoll' type='number' name='editBookmaker' id='editBookmaker' placeholder=' bookmaker...' /></div>
</div>
<div className='formControls'>
<div><label>virtual sports</label></div>
<div><input className='clearCRoll' type='number' name='editVirCas' id='editVirCas' placeholder=' virtual sports...' /></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearCRoll' type='password' name='editPassRoll' id='editPassRoll' placeholder=' admin password...' /></div>
</div>
<div className='formControlsSm'>
<button onClick={() => updateRolling()}>submit</button>
<button onClick={() => closeRollComm()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Rolling;
