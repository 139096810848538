import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const userActive_api = '/server2/active-user';
const usersList_api = '/server2/users-list';
const userBanking_api = '/server2/user_bankingData';
const totalBal_api = '/server2/users-total-bal';
const userExpo_api = '/server2/exposure-user';
const allUsers_api = '/server2/users-list-all';

const AddUser = () => {

const [isChecked, setIsChecked] = useState(false);
const { userActive, userBanking, getUserLists, activeUser, userBalTotal, userExpo, userAll } = GlobalContext();

const closeUsersAdd = () => {
$('#usersModal').hide();
$('.formUser').val('');
$('#currencyU').prop('selectedIndex', 0);
}

const createUser = async() => {
var username = $('#usernameU').val();
var name = $('#nameU').val();
var aacount = 'customer';
var currency = $('#currencyU').val();
var commision = $('#commisionU').val();
var opening = $('#openingU').val();
var expolU = $('#expolU').val();
var credit = $('#creditU').val();
var mobile = $('#mobileU').val();
var partner = 100;
var password = $('#passwordU').val();
var confPassword = $('#confpassU').val();
var fancy = $('#fancyU').val();
var casino = $('#casinoU').val();
var sportsbook = $('#sportsbookU').val();
var bookmaker = $('#bookmakerU').val();
var virtual = $('#virtualU').val();
var adminPassword = $('#adminPasswordU').val();
var creator = userActive.length > 0 ? userActive[0].customer : null;
var creator_admin = null;
var creator_subAdmin = null;
var creator_super = null;
var creator_master = null;
var creator_agent = null;
if(userActive.length > 0 && userActive[0].user_role === 'admin_role'){
creator_admin = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'subadmin_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'super_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].sub_admin;
creator_super = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'master_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].sub_admin;
creator_super = userActive[0].super_master;
creator_master = userActive[0].customer;
}
else if(userActive.length > 0 && userActive[0].user_role === 'agent_role'){
creator_admin = userActive[0].admin;
creator_subAdmin = userActive[0].sub_admin;
creator_super = userActive[0].super_master;
creator_master = userActive[0].master;
creator_agent = userActive[0].customer;
}

if(username === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('username required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(currency === 'null'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('currency type required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(opening <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('opening balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(expolU <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('expo. limit required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(mobile === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('mobile number required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(password.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min 6 digit password required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(password !== confPassword){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('confirm password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(adminPassword !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/add-users', {
username: username,
name: name,
aacount: aacount,
currency: currency,
commision: commision,
opening: opening,
expolU: expolU,
credit: credit,
mobile: mobile,
partner: partner,
password: password,
fancy: fancy,
casino: casino,
sportsbook: sportsbook,
bookmaker: bookmaker,
virtual: virtual,
creator: creator,
creator_admin: creator_admin,
creator_subAdmin: creator_subAdmin,
creator_super: creator_super,
creator_master: creator_master,
creator_agent: creator_agent
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('user created successfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userAll(allUsers_api);
userBanking(userBanking_api);
userBalTotal(totalBal_api);
userExpo(userExpo_api);
$('#usersModal').hide();
$('.formUser').val('');
$('#currencyU').prop('selectedIndex', 0);
setIsChecked(false);
$('#toogleDivU').hide();
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log("backend add-user-api error : " + error);
}
}
}

const sliderCheck = () => {
setIsChecked(!isChecked);
//console.log(isChecked);
if(isChecked === false){
$('#toogleDivU').show();
}else{
$('#toogleDivU').hide();
}
}

useEffect(() => {
if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.formControlsSubt button').css({background: '#ccc'}).attr('disabled', 'disabled');
} 
},[userActive]);

return (
<>
<div className='modals' id='usersModal'>
<div className='modalsContent'>
<div className='modalsHeader'>
<span><i className='fa fa-user-plus'></i> add user</span>
<i className='fa fa-close right' onClick={() => closeUsersAdd()}></i>
</div>

<div className='modalsBody'>

<div className='formDiv'>

<div className='formControls'>
<div><label>username*</label></div>
<div><input type='text' className='formUser' name='usernameU' id='usernameU' placeholder='username...' /></div>
</div>
<div className='formControls'>
<div><label>name</label></div>
<div><input type='text' className='formUser' name='nameU' id='nameU' placeholder='name...' /></div>
</div>
<div className='formControls'>
<div><label>currency type*</label></div>
<div>
{
userActive.length > 0 && userActive[0].user_role !== 'admin_role' ?
<select id='currencyU' disabled>
<option value={userActive[0].currency}>{userActive[0].currency.toUpperCase()}</option>   
</select>
:
<select id='currencyU'>
<option value='null'>--select currency type--</option>
<option value='inr'>INR</option>
<option value='hkd'>HKD</option>
</select>
}
</div>
</div>
<div className='formControls'>
<div><label>commision(%)</label></div>
<div><input type='number' className='formUser' name='commU' id='commisionU' placeholder='commision...' /></div>
</div>
<div className='formControls'>
<div><label>opening balance*</label></div>
<div><input type='number' className='formUser' name='openU' id='openingU' placeholder='opening...' /></div>
</div>
<div className='formControls'>
<div><label>exposure limit*</label></div>
<div><input type='number' className='formUser' name='expolU' id='expolU' placeholder='exposure limit...' /></div>
</div>
<div className='formControls'>
<div><label>credit refrence</label></div>
<div><input type='number' className='formUser' name='creditU' id='creditU' placeholder='credit refrence...' /></div>
</div>
<div className='formControls'>
<div><label>mobile number*</label></div>
<div><input type='number' className='formUser' name='mobileU' id='mobileU' placeholder='mobile number...' /></div>
</div>
<div className='formControls'>
<div><label>password*</label></div>
<div><input type='password' className='formUser' name='passwordU' id='passwordU' placeholder='password...' /></div>
</div>
<div className='formControls'>
<div><label>conf. password*</label></div>
<div><input type='text' className='formUser' name='confpassU' id='confpassU' placeholder='confirm password...' /></div>
</div>

<div className='formControls'>
<div><label>rolling commision</label></div>
<div>
<label className='slidersM'>
<input type='checkbox' checked={isChecked} onChange={sliderCheck} />
<span className='sliderBox'></span>
</label>
</div>
</div>
<div className='toogleDiv' id='toogleDivU'>
<div className='formControls'>
<div><label>fancy</label></div>
<div><input type='number' className='formUser' name='fancyU' id='fancyU' placeholder='fancy...' /></div>
</div>
<div className='formControls'>
<div><label>casino</label></div>
<div><input type='number' className='formUser' name='casinoU' id='casinoU' placeholder='casino...' /></div>
</div>
<div className='formControls'>
<div><label>sportsbook</label></div>
<div><input type='number' className='formUser' name='sportsbookU' id='sportsbookU' placeholder='sportsbook...' /></div>
</div>
<div className='formControls'>
<div><label>bookmaker</label></div>
<div><input type='number' className='formUser' name='bookmakerU' id='bookmakerU' placeholder='bookmaker...' /></div>
</div>
<div className='formControls'>
<div><label>virtual sports</label></div>
<div><input type='number' className='formUser' name='virtualU' id='virtualU' placeholder='virtual sports...' /></div>
</div>
</div>
<hr className='hrForm' />
<div className='formControls'>
<div><label>admin password*</label></div>
<div><input type='password' className='formUser' name='adminPasswordU' id='adminPasswordU' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt'>
<button onClick={() => createUser()}>create</button>
</div>

</div>

</div>

</div>
</div>
</>
)
}

export default AddUser;
