import axios from 'axios';
import $ from 'jquery';

var start_teenTest;

async function check_teenTest(){
const getData = await axios.get('/server2/active-user');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
start_teenTest = setInterval(fetchteenTest, 1000);
}
else{
console.log('login error');
}
}

async function fetchteenTest(){
try {
const getData = await axios.get('/server2//t20test_Data');
const resData = await getData.data;
//console.log('refresh');
if(resData.data === undefined){
clearInterval(start_teenTest);
setTimeout(function(){
start_teenTest = setInterval(fetchteenTest, 1000);
},1000);
}
else{
if(resData.data.t1[0].mid === '0'){
$('.teenTestPls').text('0').css({color: 'black'});
$('#allMarket_bets').hide();
}
$('#autoTime_teenTest').text(resData.data.t1[0].autotime);
$('#roundId_teenTest').text(resData.data.t1[0].mid);
$('#eventid_teenTest').text(resData.data.t1[0].mid);

$('#card1_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c1.toUpperCase()+'.jpg');
$('#card2_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c2.toUpperCase()+'.jpg');
$('#card3_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c3.toUpperCase()+'.jpg');
$('#card4_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c4.toUpperCase()+'.jpg');
$('#card5_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c5.toUpperCase()+'.jpg');
$('#card6_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c6.toUpperCase()+'.jpg');
$('#card7_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c7.toUpperCase()+'.jpg');
$('#card8_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c8.toUpperCase()+'.jpg');
$('#card9_teenTest').attr('src', 'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+resData.data.t1[0].c9.toUpperCase()+'.jpg');

resData.data.t2.forEach((element, i) => {
if(element.tstatus === 'false' && element.lstatus === 'false' && element.dstatus === 'false'){
$('#locCasino_teenTest_'+i).css({display: 'flex'});
}
else{
$('#locCasino_teenTest_'+i).hide(); 
}
});

}
} catch (error) {
clearInterval(start_teenTest);
setTimeout(function(){
start_teenTest = setInterval(fetchteenTest, 1000);
},1000);
console.log('backend refresh_teenTest_data error : ' + error);
}
}

async function pls_teenTest(eventid,sport){
try {
const getData = await axios.get('/server2/unsettle_bets_List');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
var reducePls = response.reduce((a, b) => {
const { team, loss } = b;
const item = a.find((o) => o.team === team);
item ? item.loss += loss : a.push({team, loss});
return a;
},[]);

//console.log(reducePls);

if(reducePls.length > 0){
reducePls.forEach(element => {
var split1 = element.team.split('_');
$('#pls_teenTest_'+split1[2]).text(element.loss).css({color: '#44b10a'});
});
}

}
} catch (error) {
console.log('backend teenTest_pls bets api : ' + error);
}
}

export {
check_teenTest,
start_teenTest,
pls_teenTest
};