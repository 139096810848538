import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalContext } from '../../context/AppContext';

const ReSettlement = () => {

const { allBetsSettle } = GlobalContext();
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [sports, setSports] = useState([]);
const [events, setEvents] = useState([]);
const [resSport, setResSport] = useState('cricket');
const navigate = useNavigate();

const openReSettle = (sport,event,eventid) => {
navigate('/view_resettle', {state:{sport: sport, event: event, eventid: eventid}});
}

useEffect(() => {
if(allBetsSettle.length > 0){
const findData = allBetsSettle.filter((e) => e.sport === resSport && e.bet_date >= Date.parse(new Date(startDate).toDateString()) && e.bet_date <= Date.parse(new Date(endDate).toDateString()));
var allSports = [];
var allEvents = [];
findData.forEach((e) => {
if(e.eventName !== 'virtual casino' && e.eventName !== 'casino'){
var data = {
sport: e.sport
}

var data2 = {
sport: e.sport,
eventname: e.eventName,
eventid: e.eventid, 
}

const findInd = allSports.findIndex((o) => o.sport === e.sport);
const findInd2 = allEvents.findIndex((o) => o.eventname === e.eventName && parseInt(o.eventid) === parseInt(e.eventid) && o.bet_date === e.bet_date);

if(findInd === -1){
allSports.push(data); 
}

if(findInd2 === -1){
allEvents.push(data2); 
}
}
});
setSports(allSports);
setEvents(allEvents);
}
},[allBetsSettle, resSport, startDate, endDate]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='dateDivWrap'>
<div className='dateForm2'>
<label>select sport*</label>
<select id='selResSport' onChange={(e) => setResSport(e.target.value)}>
<option value='cricket'>cricket</option>
<option value='soccer'>soccer</option>
<option value='tennis'>tennis</option>
</select>
</div>
<div className='dateForm2'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm2'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>re-settlement</span></div>

<div className='stateBody'>
{
sports.length > 0 ? sports.map((data, index) => {
return (
<div className='sportsWrap_settle' key={index}>
<div className='sportsTitle_settle'><span>{data.sport}</span></div>
<div className='sportsBody_settle'>
{
events.length > 0 && events.filter((e) => e.sport === data.sport).map((data2, index2) => {
return (
<span onClick={() => openReSettle(data2.sport,data2.eventname,data2.eventid)} key={index2}>{data2.eventname}</span>
)
})
}
</div> 
</div>
)
})
:
<div className='norecordSettle'>
<img src='/images/cloud.png' alt='cloud' />
<span>no record found...</span>
</div>
}
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default ReSettlement;
