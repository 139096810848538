import React, {useEffect, useState} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { GlobalContext } from '../../../context/AppContext';

const CompetitionCricListApi = '/server2/competition-list';
const gameSetData = '/server2/game_settingsData';

const Cricket = () => {

const [cricCompData, setCricCompData] = useState([]);
const { gameSet, getGameSet } = GlobalContext();

const handleCheck = async(id) => {
var currCheck = $('#checkB_'+id).prop('checked') ? true : false;
var sport = 'cricket';

try {
const sendData = await axios.post('/server2/game_settings', {
id: id,
currCheck: currCheck,
sport: sport
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('series locked successfully');
setTimeout(function(){
$('#msgAlert').hide();
getGameSet(gameSetData);
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('series active successfully');
setTimeout(function(){
$('#msgAlert').hide();
getGameSet(gameSetData);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend cricket-game-setting-data error : ' + error);
}
}

useEffect(() => {
async function fetchData(){
try {
const getData = await axios.get(`${CompetitionCricListApi}/4`);
const resData = await getData.data;
if(resData.length > 0){
setCricCompData(resData); 
}
} catch (error) {
console.log('backend game-setting-cricket-api error : ' + error);
}
}

fetchData();
},[]);

useEffect(() => {
const matchId = cricCompData.filter((e) => gameSet.find((f) => parseFloat(e.competition.id) === parseFloat(f.eventid)));
const nomatchId = cricCompData.filter((e) => !gameSet.find((f) => parseFloat(e.competition.id) === parseFloat(f.eventid)));

if(matchId.length > 0){
matchId.forEach((j) => {
$('#checkB_'+j.competition.id).prop('checked', true);
$('#seriesStatus_'+j.competition.id).addClass('locked').removeClass('active').html('locked')
});
}

if(nomatchId.length > 0){
nomatchId.forEach((j) => {
$('#checkB_'+j.competition.id).prop('checked', false);
$('#seriesStatus_'+j.competition.id).addClass('active').removeClass('locked').html('active')
});
}

},[gameSet, cricCompData]);

return (
<>
<div className='stateWrap' id='cricketGameSetting'>
<div className='stateHead'><span>cricket game setting</span></div>

<div className='stateBody'>
{
cricCompData.length > 0 ?
<table className='settingTable'>
<thead>
<tr>
<th>series name</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
cricCompData.map((data, index) => {
return(
<tr key={index}>
<td>{data.competition.name} <b>(Cid: {data.competition.id})</b></td>
<td>
<span className='seriesStatus' id={'seriesStatus_'+data.competition.id}>active</span>
</td>
<td>
<label className='switchWrap'>
<input type='checkbox' id={'checkB_'+data.competition.id} className='checkSwitch' onChange={() => handleCheck(data.competition.id)} />
<span className='switchSlider'></span>
</label>
</td>
</tr>
)
})
}
</tbody>
</table>
:
<div className='pageLoader'>
<div className='pageLoaderCon'>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
<span className='Loaddot'></span>
</div>
<div className='pageLoaderFoot'><span>loading...</span></div>
</div> 
}
</div>
</div>
</>
)
}

export default Cricket;
