import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const usersList_api = '/server2/users-list';
const userActive_api = '/server2/active-user';
const userBanking_api = '/server2/user_bankingData';

const DepositMod = () => {

const { userActive, getUserLists, activeUser, userBanking } = GlobalContext();

const closeBank = () => {
var id = $('#depoIdBank').val();
$('#ModalBanking').hide();
$('.clearBankFrm2').val('');
$('#amountBanking_'+id).val('');
$('#remarkBanking_'+id).val('');
}

const depositBanking2 = async() => {
var id = $('#depoIdBank').val();
var newBank = $('#bankAmt').text().replaceAll(',', '');
var newRemark = $('#bankRmk').text();
var newBankPass = $('#adminBankPass').val();

if(newBank <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new balance required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newRemark === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('remark required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newBankPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/deposit_userBanking', {
id: id,
newBank: newBank,
newRemark: newRemark,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('deposit banking successfully');
setTimeout(function(){
$('#msgAlert').hide();
activeUser(userActive_api);
getUserLists(usersList_api);
userBanking(userBanking_api);
$('#ModalBanking').hide();
$('.clearBankFrm2').val('');
$('#amountBanking_'+id).val('');
$('#remarkBanking_'+id).val('');
},1000);
return false;
}else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend deposit2-banking-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='ModalBanking'>
<div className='modalsSm_content'>
<div className='modalsSm_header'>
<span>deposit banking - </span> 
<span className='roleTxt' id='rollUserBank'></span>
<span className='headTxtMod' id='UserBank'></span>
<i className='fa fa-close right' onClick={() => closeBank()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>deposit amount</label></div>
<div><span id='bankAmt'></span></div>
</div>
<div className='formControls'>
<div><label>deposit remark</label></div>
<div><span id='bankRmk'></span></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div>
<input className='clearBankFrm2' type='password' name='adminBankPass' id='adminBankPass' placeholder='admin password...' />
<input className='clearBankFrm2' type='hidden' name='depoIdBank' id='depoIdBank' />
</div>
</div>
<div className='formControlsSubt2'>
<button onClick={() => depositBanking2()}>deposit</button>
<button onClick={() => closeBank()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default DepositMod;
