import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const usersList_api = '/server2/users-list';

const PartnerModal = () => {

const { userActive, getUserLists } = GlobalContext();

const closePartner = () => {
$('#partnerModal').hide();
$('.clearPartFrm').val('');
}

const submitPartner = async() => {
var id = $('#partnerId').val();
var newPartner = $('#newPartner').val();
var newPartnerPass = $('#newPartnerPass').val();

if(newPartner <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new partnership required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPartnerPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/partnership_update', {
id: id,
newPartner: newPartner,
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('partnership updated');
setTimeout(function(){
$('#msgAlert').hide();
getUserLists(usersList_api);
$('#partnerModal').hide();
$('.clearPartFrm').val('');
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend partnership-api error : ' + error);
}
}

}

return (
<>
<div className='modalsSm' id='partnerModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>edit partnership -</span> <span id='userPartM'></span> <i className='fa fa-close right' onClick={() => closePartner()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls'>
<div><label>old partnership</label></div>
<div><span id='oldPartnerRef'></span></div>
<input className='clearPartFrm' type='hidden' id='partnerId' />
</div>
<div className='formControls flexControl'>
<div><label>new partnership</label></div>
<div><input className='clearPartFrm' type='number' name='newPartner' id='newPartner' placeholder='new partnership...' /></div>
</div>
<div className='formControls'>
<div><label>admin password</label></div>
<div><input className='clearPartFrm' type='password' name='newPartnerPass' id='newPartnerPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSubt2'>
<button className='submitBtn' onClick={() => submitPartner()}>submit</button>
<button onClick={() => closePartner()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default PartnerModal;
