import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const userActive_api = '/server2/active-user';

const DefaultPass = () => {

const { userActive, activeUser } = GlobalContext();

const confirmAutoChange = async() => {
var oldPass = $('#oldPassAuto').val();
var newPass = $('#newPassAuto').val();
var confPass = $('#confPassAuto').val();
var id = userActive.length > 0 ? userActive[0].id : null;
var customer = userActive.length > 0 ? userActive[0].customer : null;
var currPass = userActive.length > 0 ? userActive[0].password : null;

if(oldPass === '' || newPass === '' || confPass === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('all password field required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(oldPass !== currPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('wrong old password');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(oldPass === confPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('type another password');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPass !== confPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('no match confirm password');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPass.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min 6 digit password required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/updateAdmin_passwordSelf', {
id: id,
customer: customer,
confPass: confPass,
changePass: true
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('password changed successfully');
setTimeout(function(){
$('#msgAlert').hide();
$('#autoChangePass').hide();
$('.formUserAuto').val('');
activeUser(userActive_api);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend change-auto-password-api error : ' + error);
}
}
}

useEffect(() => {
if(userActive.length > 0){
if(userActive[0].changepass === null){
$('#autoChangePass').show();
}
}
},[userActive]);

return (
<>
<div className='modals' id='autoChangePass'>
<div className='modalsContent'>
<div className='modalsHeader'>
<span><i className='fa fa-key'></i> change password</span>
</div>

<div className='modalsBody'>

<div className='formDiv'>
<div className='formControls'>
<div><label>old password*</label></div>
<div><input type='password' className='formUserAuto' name='oldPassAuto' id='oldPassAuto' placeholder='old password...' /></div>
</div>
<div className='formControls'>
<div><label>new password*</label></div>
<div><input type='text' className='formUserAuto' name='newPassAuto' id='newPassAuto' placeholder='new password...' /></div>
</div>
<div className='formControls'>
<div><label>confirm password*</label></div>
<div><input type='password' className='formUserAuto' name='confPassAuto' id='confPassAuto' placeholder='confirm password...' /></div>
</div>

<div className='formControlsSubt2'>
<button onClick={() => confirmAutoChange()}>confirm changes</button>
</div>
</div>

</div>

</div>
</div>
</>
)
}

export default DefaultPass;
