import React, { useState } from 'react';
import Cricket from './Cricket';
import Soccer from './Soccer';
import Tennis from './TennisData';

const GameSetting = () => {

const [gameSetMenu, setGameSetMenu] = useState('cricket');

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>game setting</span></div>

<div className='betSportMenu'>
<button onClick={() => setGameSetMenu('cricket')}>cricket</button>
<button onClick={() => setGameSetMenu('soccer')}>soccer</button>
<button onClick={() => setGameSetMenu('tennis')}>tennis</button>
</div>

{gameSetMenu === 'cricket' ? <Cricket /> : gameSetMenu === 'soccer' ? <Soccer /> : <Tennis />}

</div>

</div>
</div>
</div>
</>
)
}

export default GameSetting;
