import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const LiveBets = () => {

const location = useLocation();
const { unsetBets } = GlobalContext();
const [viewBets, setViewBets] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = viewBets.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(viewBets.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const closeViewMoreBet = () => {
$('#liveBetsModal').hide();
}

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const parentList = (customer,admin,sub_admin,super_master,master,agent) => {
$('#parentListModal').show();
if(customer !== 'null'){
$('#customerParent').text(customer);
$('#customerParentRole').show();
}
if(admin !== 'null'){
$('#adminParent').text(admin);
$('#adminParentRole').show();
}
if(sub_admin !== 'null'){
$('#sub_adminParent').text(sub_admin);
$('#sub_adminParentRole').show();
}
if(super_master !== 'null'){
$('#super_masterParent').text(super_master);
$('#super_masterParentRole').show();
}
if(master !== 'null'){
$('#masterParent').text(master);
$('#masterParentRole').show();
}
if(agent !== 'null'){
$('#agentParent').text(agent);
$('#agentParentRole').show();
}
}

useEffect(() => {
if(unsetBets.length > 0){
setViewBets(unsetBets.filter((e) => e.eventid === (location.state !== null ? location.state.eventid : 0)));
}
}, [unsetBets, location.state]);

return (
<>
<div className='modalLg' id='liveBetsModal'>
<div className='modalLg_content'>
<div className='modalLg_header'><span>View More Live Bets</span> <i className='fa fa-close right' onClick={() => closeViewMoreBet()}></i></div>
<div className='modalLg_body'>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {viewBets.length} records...</span>
</div>

<div className='table-responsive'>
<table className='liveBetsTableLg'>
<thead>
<tr>
<th>customer</th>
<th>team</th>
<th>bet type</th>
<th>sec</th>
<th>odds</th>
<th>size</th>
<th>stake</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
const selfDate = data.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
if(data.bettype === 'back'){
return(
<tr key={index} className='liveBack_tr'>
<td><span className='parentUser' onClick={() => parentList(data.customer,data.admin,data.sub_admin,data.super_master,data.master,data.agent)}>{data.customer}</span></td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'over' || data.sec === 'oddeven'
|| data.sec === 'meter' || data.sec === 'line' || data.sec === 'khado'
|| data.sec === 'ball' || data.sec === 'fancy1' ? 'yes' : data.bettype 
}
</td>
<td>{data.sec}</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{data.betamount}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
}
else{
return(
<tr key={index} className='liveLay_tr'>
<td><span className='parentUser' onClick={() => parentList(data.customer,data.admin,data.sub_admin,data.super_master,data.master,data.agent)}>{data.customer}</span></td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'over' || data.sec === 'oddeven'
|| data.sec === 'meter' || data.sec === 'line' || data.sec === 'khado'
|| data.sec === 'ball' || data.sec === 'fancy1' ? 'no' : data.bettype 
}
</td>
<td>{data.sec}</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)  
}
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default LiveBets;
