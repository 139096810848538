import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MarketBets from '../../../../allBets/MarketBets';
import { startResult_ab, runresult_ab } from './js/result';
import AbMod from '../modals/AbMod';
import { 
check_ab,
start_ab,
pls_ab,
allSid_ab
} from './js/refresh';

const AndarBahar = () => {

const [load_ab, setLoad_Ab] = useState(true);
const [currab, setCurrAb] = useState([]);

const andLeft = () => {
var cardId = document.getElementById('CradsAndar');
cardId.scrollLeft -= 10;
}

const andRight = () => {
var cardId = document.getElementById('CradsAndar');
cardId.scrollLeft += 10;
}

const bahLeft = () => {
var cardId = document.getElementById('CradsBahar');
cardId.scrollLeft -= 10;
}

const bahRight = () => {
var cardId = document.getElementById('CradsBahar');
cardId.scrollLeft += 10;
}

useEffect(() => {
async function fetchAb() {
try {
const getData = await axios.get('/server2/andarBahar_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nat : element.nation
}

const findInd = allSid_ab.findIndex((o) => o.sid === element.sid && o.nat === element.nation);
if(findInd === -1){
allSid_ab.push(data);
}
});
setLoad_Ab(false);
setCurrAb(resData);
}
else{
setLoad_Ab(true);
setCurrAb([]);
}
} catch (error) {
console.log('backend casino_andarBahar_data error : ' + error);
}
}

fetchAb();
},[]);

useEffect(() => {
check_ab();
runresult_ab();
pls_ab(currab.data !== undefined ? currab.data.t1[0].mid : 0,'andar_bahar');
return () => {
clearInterval(startResult_ab);
clearInterval(start_ab);
}
},[currab]);

if(load_ab){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
currab.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>andar bahar</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_ab'>{currab.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='teamLiveCards'>
<div className='longDivCard'>
<span className='cardTeam'>andar</span>
<div className='abCradsWrap'>
<div className='forwardBtn' onClick={andLeft}><span><i className='fa fa-angle-left'></i></span></div>
<div className='longCrads' id='CradsAndar'></div>
<div className='forwardBtn' onClick={andRight}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>

<div className='longDivCard'>
<span className='cardTeam'>bahar</span>
<div className='abCradsWrap'>
<div className='forwardBtn' onClick={bahLeft}><span><i className='fa fa-angle-left'></i></span></div>
<div className='longCrads' id='CradsBahar'></div>
<div className='forwardBtn' onClick={bahRight}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>
</div>
</div>  

<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_ab'>{currab.data.t1[0].autotime}</span></div>   
</div> 

<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3053' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>andar cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[0].sid}>{currab.data.t2[0].rate}</span>
<button id={'ab_Team_'+currab.data.t2[0].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[0].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[0].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[0].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[1].sid}>{currab.data.t2[1].rate}</span>
<button id={'ab_Team_'+currab.data.t2[1].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[1].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[1].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[1].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[2].sid}>{currab.data.t2[2].rate}</span>
<button id={'ab_Team_'+currab.data.t2[2].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[2].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[2].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[2].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[3].sid}>{currab.data.t2[3].rate}</span>
<button id={'ab_Team_'+currab.data.t2[3].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[3].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[3].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[3].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[4].sid}>{currab.data.t2[4].rate}</span>
<button id={'ab_Team_'+currab.data.t2[4].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[4].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[4].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[4].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[5].sid}>{currab.data.t2[5].rate}</span>
<button id={'ab_Team_'+currab.data.t2[5].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[5].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[5].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[5].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[6].sid}>{currab.data.t2[6].rate}</span>
<button id={'ab_Team_'+currab.data.t2[6].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[6].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[6].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[6].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[7].sid}>{currab.data.t2[7].rate}</span>
<button id={'ab_Team_'+currab.data.t2[7].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[7].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[7].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[7].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[8].sid}>{currab.data.t2[8].rate}</span>
<button id={'ab_Team_'+currab.data.t2[8].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[8].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[9].sid}>{currab.data.t2[9].rate}</span>
<button id={'ab_Team_'+currab.data.t2[9].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[9].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[10].sid}>{currab.data.t2[10].rate}</span>
<button id={'ab_Team_'+currab.data.t2[10].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[10].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[11].sid}>{currab.data.t2[11].rate}</span>
<button id={'ab_Team_'+currab.data.t2[11].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[11].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[12].sid}>{currab.data.t2[12].rate}</span>
<button id={'ab_Team_'+currab.data.t2[12].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[12].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[12].sid}>0</span>
</div>

</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>bahar cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[13].sid}>{currab.data.t2[13].rate}</span>
<button id={'ab_Team_'+currab.data.t2[13].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[13].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[14].sid}>{currab.data.t2[14].rate}</span>
<button id={'ab_Team_'+currab.data.t2[14].sid}>
<span id={'ab_TeamTx14_'+currab.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[14].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[15].sid}>{currab.data.t2[15].rate}</span>
<button id={'ab_Team_'+currab.data.t2[15].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[15].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[16].sid}>{currab.data.t2[16].rate}</span>
<button id={'ab_Team_'+currab.data.t2[16].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[16].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[17].sid}>{currab.data.t2[17].rate}</span>
<button id={'ab_Team_'+currab.data.t2[17].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[17].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[18].sid}>{currab.data.t2[18].rate}</span>
<button id={'ab_Team_'+currab.data.t2[18].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[18].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[18].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[19].sid}>{currab.data.t2[19].rate}</span>
<button id={'ab_Team_'+currab.data.t2[19].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[19].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[19].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[19].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[20].sid}>{currab.data.t2[20].rate}</span>
<button id={'ab_Team_'+currab.data.t2[20].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[20].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[20].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[20].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[21].sid}>{currab.data.t2[21].rate}</span>
<button id={'ab_Team_'+currab.data.t2[21].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[21].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[21].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[21].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[22].sid}>{currab.data.t2[22].rate}</span>
<button id={'ab_Team_'+currab.data.t2[22].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[22].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[22].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[22].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[23].sid}>{currab.data.t2[23].rate}</span>
<button id={'ab_Team_'+currab.data.t2[23].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[23].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[23].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[23].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[24].sid}>{currab.data.t2[24].rate}</span>
<button id={'ab_Team_'+currab.data.t2[24].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[24].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[24].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[24].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[25].sid}>{currab.data.t2[25].rate}</span>
<button id={'ab_Team_'+currab.data.t2[25].sid}>
<span id={'ab_TeamTx1_'+currab.data.t2[25].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[25].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[25].sid}>0</span>
</div>

</div>
</div>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_ab'></div>
</div>

<MarketBets eventid={currab.data !== undefined ? currab.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<AbMod />
</>
)
}

export default AndarBahar;
