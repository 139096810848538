import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import MarketBets from '../../../../allBets/MarketBets';
import { startResult_dt1day, runresult_dt1day } from './js/result';
import Resultdt from '../modals/Resultdt';
import DtRules from '../modals/DtRules';
import { 
check_dt1day,
start_dt1day,
pls_dt1day,
allDt1daySid 
} from './js/refresh';

const Dt1Day = () => {
const [load_dt1day, setLoad_dt1Day] = useState(true);
const [currdt1day, setCurrDt1Day] = useState([]);

const opendt1dayRules = () => {
$('#dtModal').show();
}

useEffect(() => {
async function fetchDt1day() {
try {
const getData = await axios.get('/server2/dt1day_Data');
const resData = await getData.data;
if(resData.data !== undefined){

resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nat : element.nat
}

const findInd = allDt1daySid.findIndex((o) => o.sid === element.sid && o.nat === element.nat);
if(findInd === -1){
allDt1daySid.push(data);
}
});

setLoad_dt1Day(false);
setCurrDt1Day(resData);
}
else{
setLoad_dt1Day(true);
setCurrDt1Day([]);
}
} catch (error) {
console.log('backend casino_dt1day_data error : ' + error);
}
}

fetchDt1day();
},[]);

useEffect(() => {
check_dt1day();
runresult_dt1day();
pls_dt1day(currdt1day.data !== undefined ? currdt1day.data.t1[0].mid : 0,'dt_1day');
return () => {
clearInterval(startResult_dt1day);
clearInterval(start_dt1day);
}
},[currdt1day]);

if(load_dt1day){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents_casino'>
{
currdt1day.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>DT 1Day</span>
<span className='casGamerule' onClick={opendt1dayRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_dt1day'>{currdt1day.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>cards</span>
<div className='teamLiveCards'>
<img id='card1_dt1day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt1day.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_dt1day' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(currdt1day.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_dt1day'>{currdt1day.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3057' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh2'><span>back</span></th>
<th className='layTh'><span>lay</span></th>
</tr>
</thead>
{
currdt1day.data.t2.map((data, index) => {
return(
<tbody key={index}>
<tr>
<td>
<div className='teamTdCas'>
<span>{data.nat}</span>
<div className='plsDiv'>
<span className='plsCas dt1dayPls' id={'plsCas_dt1day_'+data.sid}>0</span>
<span className='plsCas plsCasBef' id={'plsCasBef_dt1day_'+data.sid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_dt1day_'+data.sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'odds_casBack_dt1day_'+data.sid}>
<span className='casRate' id={'casRateBack_dt1day_'+data.sid}>{data.b1}</span>
<span className='casSize' id={'casSizeBack_dt1day_'+data.sid}>{data.bs1}</span>
</button>
</td>
<td className='countOdds layCasBox'>
<button className='odds_cas' id={'odds_casLay_dt1day_'+data.sid}>
<span className='casRate' id={'casRateLay_dt1day_'+data.sid}>{data.l1}</span>
<span className='casSize' id={'casSizeLay_dt1day_'+data.sid}>{data.ls1}</span>
</button>
</td>
</tr>
</tbody>
)
}) 
}
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_dt1day'></div>
</div>

<MarketBets eventid={currdt1day.data !== undefined ? currdt1day.data.t1[0].mid : 0} />
</div>
}
</div>

</div>
</div>
</div>

<Resultdt />
<DtRules />
</>
)
}

export default Dt1Day;
