import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import RestoreUser from '../../modals/RestoreUser';
import StatusAlert from '../StatusAlert';
import { GlobalContext } from '../../context/AppContext';

const Restore = () => {
const { loadRemoveusr, removeUsersList, userActive } = GlobalContext();
const [removeUsers, setRemoveUsers] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = removeUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(removeUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const restoreUserOpen = (id,user,role) => {
// alert(id +' -- '+ user +' -- '+ role);
const a = role.split('_');
$('#restoreModal').show();
$('#roleRestore').text(a[0]);
$('#userRestore').text(user);
$('#restoreUserId').val(id);
}

const searchUsers = (name) => {
setRemoveUsers(removeUsersList.filter((e) => e.customer.includes(name)));
}

useEffect(() => {
if(loadRemoveusr){
setRemoveUsers([]); 
}
},[loadRemoveusr]);

useEffect(() => {
if(removeUsersList.length > 0){
setRemoveUsers(removeUsersList);
}
},[removeUsersList]);

useEffect(() => {
if(records.length <= 0){
$('.usersTable').css({width: '100%'});
}
else{
if(window.screen.width <= 768){
$('.usersTable').css({width: 'max-content'});   
}
if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.restoreBtn').css({background: '#ccc'}).attr('disabled', 'disabled');
}
}
},[records, userActive]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>restore user</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {removeUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>name</th>
<th>date time</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td><span className='roleTxt'>{data.user_role.slice(0, 3)}</span> {data.customer}</td>
<td>{data.name}</td>
<td>{data.create_time}</td>
<td>
<button onClick={() => restoreUserOpen(data.id,data.customer,data.user_role)} className='restoreBtn'><img src='/images/restore.png' alt='restore' /> restore</button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>


<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>

<RestoreUser />
</>
)
}

export default Restore;
