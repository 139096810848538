import React, { useEffect, useState } from 'react';
import PageSideMenu2 from '../../extra/PageSideMenu2';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context/AppContext';

const MyActivity = () => {
const location = useLocation();
const { usrAct } = GlobalContext();
const [currActivity, setCurrActivity] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currActivity.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currActivity.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

useEffect(() => {
if(usrAct.length > 0){
setCurrActivity(usrAct.filter((e) => e.customer.trim() === (location.state !== null ? location.state.name : null)));
}
},[usrAct, location.state]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu2 />
</div>
<div className='col'>
<div className='profilePage'>
<div className='headerProfile'><span>activity log</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currActivity.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>login date/time</th>
<th>ip</th>
<th>ISP</th>
<th>city/state/country/pincode</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.create_date}</td>
<td>{data.ip}</td>
<td>{data.org}</td>
<td>{data.city}/{data.region}/{data.country}/{data.postal}</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MyActivity;
