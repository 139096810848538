import React from 'react';
import $ from 'jquery';

const ParentList = () => {

const closeParentList = () => {
$('#parentListModal').hide();
$('#customerParent').text('');
$('#customerParentRole').hide();
$('#adminParent').text('');
$('#adminParentRole').hide();
$('#sub_adminParent').text('');
$('#sub_adminParentRole').hide();
$('#super_masterParent').text('');
$('#super_masterParentRole').hide();
$('#masterParent').text('');
$('#masterParentRole').hide();
$('#agentParent').text('');
$('#agentParentRole').hide();
}

return (
<>
<div className='modalsSm' id='parentListModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>parent list</span> <i className='fa fa-close right' onClick={() => closeParentList()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<ul className='parentUl'>
<li id='adminParentRole'><span id='adminParent'></span> <span>(white label)</span></li>
<li id='sub_adminParentRole'><span id='sub_adminParent'></span> <span>(sub admin)</span></li>
<li id='super_masterParentRole'><span id='super_masterParent'></span> <span>(super)</span></li>
<li id='masterParentRole'><span id='masterParent'></span> <span>(master)</span></li>
<li id='agentParentRole'><span id='agentParent'></span> <span>(agent)</span></li>
<li id='customerParentRole'><span id='customerParent'></span> <span>(customer)</span></li>
</ul>
</div>

</div>
</div>
</div>
</>
)
}

export default ParentList;
