import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const apiSportsSetting = '/server2/user_sports_settings';

const SportsModal = ({user}) => {

const { userActive } = GlobalContext();
const [activeSport, setActiveSport] = useState([]);

const closeSportsSet = () => {
$('#sportsModal').hide();
}

const handleSwitch = async(sport) => {
var user = $('#sportsUser').text();
//console.log(sport +' -- '+ user);
try {
const sendData = await axios.post('/server2/update_sports', {
user : user,
sport : sport
}); 
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('sport locked successfully');
setTimeout(async function(){
$('#msgAlert').hide();
try {
const getData = await axios.get(`${apiSportsSetting}`);
const resData = await getData.data;
if(resData.length > 0){
setActiveSport(resData.filter((e) => e.username === user));
}
else{
setActiveSport([]);
}
} catch (error) {
console.log('backend sports-settings-api error : ' + error);
}
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(async function(){
$('#msgAlert').hide();
try {
const getData = await axios.get(`${apiSportsSetting}`);
const resData = await getData.data;
if(resData.length > 0){
setActiveSport(resData.filter((e) => e.username === user));
}
else{
setActiveSport([]);
}
} catch (error) {
console.log('backend sports-settings-api error : ' + error);
}
},1000);
}
} catch (error) {
console.log('backend sports-setting-api error : ' + error);
}
}

var sportsData = [
{
id : '1',
name : 'cricket',
status : 'active',
},
{
id : '2',
name : 'soccer',
status : 'active',
},
{
id : '3',
name : 'tennis',
status : 'active',
},
{
id : '4',
name : 'kabaddi',
status : 'active',
},
{
id : '5',
name : 'casino',
status : 'active',
},
{
id : '6',
name : 'virtual casino',
status : 'active',
},
];

const matchSports = sportsData.filter((e) => activeSport.find((o) => e.name === o.sport));
const unmatchSports = sportsData.filter((e) => !activeSport.find((o) => e.name === o.sport));

if(matchSports.length > 0){
for(let i = 0; i < matchSports.length; i++){
$('#check_'+matchSports[i].id).prop('checked', true);
$('#sportStatus_'+matchSports[i].id).text('locked');
}
}
if(unmatchSports.length > 0){
for(let i = 0; i < unmatchSports.length; i++){
$('#check_'+unmatchSports[i].id).prop('checked', false);
$('#sportStatus_'+unmatchSports[i].id).text('active');
}
}

useEffect(() => {

const fetchData = async() => {
try {
const getData = await axios.get(`${apiSportsSetting}`);
const resData = await getData.data;
if(resData.length > 0){
setActiveSport(resData.filter((e) => e.username === user));
}
} catch (error) {
console.log('backend sports-settings-api error : ' + error);
}
}

fetchData();
},[user]);

if(userActive.length > 0 && userActive[0].status !== 'active'){
$('.checkSwitch').attr('disabled', 'disabled');
$('.switchSlider').css({background: '#ccc'});
}

return (
<>
<div className='modalsSm' id='sportsModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'>
<span>sports setting - </span>
<span id='sportsUserRole' className='roleTxt'></span>
<span id='sportsUser'></span>
<i className='fa fa-close right' onClick={() => closeSportsSet()}></i>
</div>
<div className='modalsSm_body'>

<table className='sportsSetting_table'>
<thead>
<tr>
<th>sports name</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
sportsData.map((data, index) => {
return(
<tr key={index}>
<td>{data.name}</td>
<td><span id={'sportStatus_'+data.id}>{data.status}</span></td>
<td>
<label className='switchWrap'>
<input type='checkbox' id={'check_'+data.id} className='checkSwitch' onChange={() => handleSwitch(data.name)} />
<span className='switchSlider'></span>
</label>
</td>
</tr>
)
})
}
</tbody>
</table>

</div>
</div>
</div>
</>
)
}

export default SportsModal;
