import { useEffect, useState } from 'react';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const userActive_api = '/server2/active-user';

const AutoLogOut = () => {
   
const Navigate = useNavigate();
const { userActive, activeUser } = GlobalContext();
const [beforeIp, setBeforeIp] = useState(null);
const [currIp, setCurrIp] = useState(null);
const [currSta, setCurrSta] = useState(null);

useEffect(() => {

const startAuto = setInterval(fetchData, 1000);

async function fetchData() {
try {
const getData = axios.get('/server2/active-user');
const resData = (await getData).data;
//console.log(resData);
if(resData.length > 0){
setCurrIp(resData[0].ip);
setCurrSta(resData[0].status);
} 
else{
clearInterval(startAuto);
}
} catch (error) {
console.log('backend auto_log_out userData error : ' + error);
}
}

},[]);

useEffect(() => {
if(userActive.length > 0){
setBeforeIp(userActive[0].ip);
}
}, [userActive]);

useEffect(() => {
if(currIp !== null && beforeIp !== null){
if(currIp !== beforeIp || currSta === 'suspended'){
async function ipLogOut() {
try {
const sendData = await axios.get('/server2/logout');
const resData = await sendData.data;
if(resData === 'success'){
Navigate('/');
return false;
}
} catch (error) {
console.log('backend logout-api error : ' + error);
} 
}

ipLogOut();
}
else if(currSta !== 'suspended'){
activeUser(userActive_api);
}
}

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currIp, currSta, beforeIp]);

return null;
}

export default AutoLogOut;
